

  <!-- <nav class="main-header navbar navbar-expand navbar-white navbar-light" style="position: relative;" >
    <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
    </ul>
   
    <form class="form-inline ml-3" style="z-index: 10;">
      <div class="input-group input-group-sm">
        <input
          class="form-control form-control-navbar"
          [(ngModel)]="searchString"
          [ngModelOptions]="{ standalone: true }"
          type="search"
          placeholder="Search"
          aria-label="Search"
          (input)="onSearchInput()"
        />
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit" (click) ="searchMenu()">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div *ngIf="searchResults.length > 0" class="autocomplete-dropdown">
        <ul class="list-group" style="overflow-y: auto; max-height: 300px;position: absolute; width: 30%;">
          <li class="list-group-item" *ngFor="let result of searchResults" (click)="selectResult(result.title)">
            <a href ="{{result.link}}">{{result.title}}</a>
          </li>
        </ul>
      </div> 

      

    </form>
  </nav>
  <aside class="main-sidebar sidebar-dark-primary elevation-4" id="menu">
    <a [routerLink] = "['/dashboard']" class="brand-link">
      <img src="assets/image/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-light">Gbost</span>
    </a>
  
    <div class="sidebar" id="sb">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="assets/image/avatar5.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a class="d-block">{{adminNmae}}</a>
        </div>
      </div>
  
    <nav class="mt-2">
    
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <ng-container *ngFor="let item of menuItems">
        <li class="nav-item" *ngIf="item.isHaved">
          <a *ngIf="!item.childrens || item.childrens.length === 0" [routerLink]="[item.link ? '/' + item.link : '']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <i class="fas  {{ item.icon }} nav-icon"></i>
            <p>{{ item.title }}</p>
          </a>
          <ng-container *ngIf="item.childrens && item.childrens.length > 0">
            <li class="nav-item has-treeview">
              <a href="#" class="nav-link" [ngClass]="{ 'active': selectedParentMenu === item.code }" (click)="selectParentMenu(item.code)">
                <i class="nav-icon fas {{ item.icon }}"></i>
                <p>
                  {{ item.title }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview" style="background-color: #343a40;">
                <ng-container *ngFor="let child of item.childrens">
                  <li class="nav-item has-treeview" *ngIf="child.isHaved" >
                    
                    <a  *ngIf="child.link != ''" [routerLink]="[child.link ? '/' + child.link : '']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                      <i class="nav-icon fas {{ child.icon }}"></i>
                      <p>{{ child.title }}</p>
                    </a>
  
                    <a  *ngIf="child.link == ''" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                      <i class="nav-icon fas {{ child.icon }}"></i>
                      <p>{{ child.title }}</p>
                      <i class="fas fa-angle-left right"></i>
                    </a>
  
                    <ul class="nav nav-treeview" *ngIf="child.childrens && child.childrens.length > 0">
                      <ng-container *ngFor="let grandchild of child.childrens">
                        <li class="nav-item" *ngIf="grandchild.isHaved && grandchild.link != null">
                          <a [routerLink]="[grandchild.link ? '/' + grandchild.link : '']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="nav-icon fas {{ grandchild.icon }}"></i>
                            <p>{{ grandchild.title }}</p>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ul>              

            </li>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  
    </nav>
    </div>
  </aside> 
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner> -->


  <!-- amk original -->

 <nav class="main-header navbar navbar-expand navbar-white navbar-light" style="position: relative;">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    </ul>
  
    <form class="form-inline ml-3" style="z-index: 10;">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" [(ngModel)]="searchString" [ngModelOptions]="{ standalone: true }"
          type="search" placeholder="Search" aria-label="Search" (input)="onSearchInput()" />
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit" (click)="searchMenu()">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div *ngIf="searchResults.length > 0" class="autocomplete-dropdown">
        <ul class="list-group" style="overflow-y: auto; max-height: 300px;position: absolute; width: 30%;">
          <li class="list-group-item" *ngFor="let result of searchResults" (click)="selectResult(result.title)">
            <a href="{{result.link}}">{{result.title}}</a>
          </li>
        </ul>
      </div>
    </form>
 
    <div _ngcontent-pks-c41="" class="col-md-6 ng-tns-c41-0" style="margin-top: 50px !important;"></div>
    <div _ngcontent-pks-c41="" class="col-md-1 ng-tns-c41-0">
      <div _ngcontent-pks-c41="" class="dropdown ng-tns-c41-0 show">
     
          <a  id="dLabel" role="button" data-toggle="dropdown" data-target="#" (click)="toggleDropdown()" style="position: relative;">         
            <i class="fas fa-bell" style="font-size: 24px; margin-right: 20px;"></i>
            <span *ngIf="unreadCount !== 0" class="notification-badge">
              {{unreadCount}}
            </span>
        </a>
        
        <a  title="Speakers Audio" style="position: relative;">     
          <i id="speakerIcon" title="Speakers Audio"  class="fas fa-volume-up" style="font-size: 1.5rem; cursor: pointer;" (click)="toggleAudio()">
          </i>
          <i id="muteIcon" class="fas fa-volume-mute" style="font-size: 1.5rem; cursor: pointer; display: none;" (click)="toggleAudio()"></i> 
        </a> 
  
        <audio #notificationSound src="assets/songs/notification-sound.mp3"></audio>  
  
  
  
        <ul role="menu" aria-labelledby="dLabel" 
          class="dropdown-menu notifications" style="margin-bottom: 5px;"  *ngIf="noti_total != 0">         
          <div class="arrow-up ng-tns-c41-0" ></div>  
          <div _ngcontent-afv-c41=""
            class="notification-heading d-flex justify-content-between align-items-center ng-tns-c41-0"
            style="margin-left: 5px;">
            <h4 _ngcontent-pks-c41="" class="menu-title ng-tns-c41-0">Notifications</h4>
            <h4 _ngcontent-pks-c41="" class="menu-title pull-right ng-tns-c41-0">
              <i _ngcontent-pks-c41="" class="fas fa-times-circle ng-tns-c41-0" style="margin-right: 10px;" (click)="toggleDropdown()">
              </i>
            </h4>
          </div>
          <li _ngcontent-pks-c41="" class="divider ng-tns-c41-0" style="margin-top: 0px;"></li>
          <div _ngcontent-pks-c41="" class="notifications-container ng-tns-c41-0">
            <div _ngcontent-pks-c41="" class="notifications-wrapper ng-tns-c41-0 ng-star-inserted"
              *ngFor="let notification of notidata">
              <a _ngcontent-pks-c41="" class="content ng-tns-c41-0 ng-star-inserted">
                <div _ngcontent-pks-c41="" class="notification-item ng-tns-c41-0"
                  style="background-color: #fff !important;" (click)="handleNotificationClick(notification.id)">
                  <h4 _ngcontent-pks-c41="" class="item-title ng-tns-c41-0" style="margin-left: 10px;">
                    {{ notification.title }}
                    <span *ngIf="!notification.readed" class="large-dot">.</span>
                  </h4>
                  <p _ngcontent-pks-c41="" class="item-info ng-tns-c41-0 centered-text">
                    {{notification.content}}<br>
                  </p>
                  <p _ngcontent-pks-c41="" class="hourAgos ng-tns-c41-0 small-text" style="margin-left: 10px;">{{
                    getTimeAgo(notification.created_date) }}</p>
  
                </div>
              </a>
              <li class="divider"></li>
            </div>
          </div>
          <div _ngcontent-pks-c41="" class="notification-footer ng-tns-c41-0">
          </div>
        </ul>
  
      </div>
    </div>
  
  
  </nav>
  <aside class="main-sidebar sidebar-dark-primary elevation-4" id="menu">
    <a [routerLink]="['/dashboard']" class="brand-link">
      <img src="assets/image/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
        style="opacity: .8; background-color: #000000 !important;">
      <span class="brand-text font-weight-light">Gbost</span>
    </a>
  
    <div class="sidebar" id="sb" style="background-color: #000000 !important;">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="assets/image/avatar5.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a class="d-block">{{adminNmae}}</a>
        </div>
      </div>
      <nav class="mt-2">
  
        
  
  
        <ul class="accordion" style="overflow-x: hidden !important;width: 290px !important;">
          <li *ngFor="let item of menuItems">
            <div class="link" (click)="toggleAccordion(item,'item')"> 
              <a *ngIf="item.childrens.length > 0 && item.childrens[0].type == 2" [routerLink]="[item.link ? '/' + item.link : '']"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
              <i class="nav-icon fas {{ item.icon }}"></i>
                {{ item.title }}
              </a>
              <a *ngIf="item.childrens.length > 0 && item.childrens[0].type != 2" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
                <i class="nav-icon fas {{ item.icon }}"></i>
                  {{ item.title }}
                <i class="nav-icon fas fa-angle-up right" *ngIf="item.childrens.length > 0 && item.isHaved && item.isOpen && item.childrens[0].type != 2"></i>
                <i class="nav-icon fas fa-angle-down right" *ngIf="item.childrens.length > 0 && item.isHaved && !item.isOpen && item.childrens[0].type != 2"></i>
              </a>
            </div>
            <ul class="submenu" *ngIf="item.isOpen && item.childrens.length > 0 && item.isHaved && item.childrens[0].type != 2">
              <li *ngFor="let subItem of item.childrens">
                <div class="link1" *ngIf="subItem.isHaved">
                  <a *ngIf="subItem.link != ''" 
                  routerLinkActive="active " 
                  [routerLinkActiveOptions]="{exact: false}" 
                  [routerLink]="[subItem.link ? '/' + subItem.link : '']">
                    <i class="nav-icon fas {{ subItem.icon }}"></i>
                    <p>{{ subItem.title }}</p>
                  </a>
    
                  <a *ngIf="subItem.link == ''"
                      class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" 
                      (click)="toggleAccordion(subItem,'subItem')">
                    <i class="nav-icon fas {{ subItem.icon }}"></i>
                    <p>{{ subItem.title }}</p>
                    <i class="nav-icon fas fa-angle-down right"></i>
                  </a>
                </div>
                <ul class="submenu" *ngIf="subItem.isOpen && subItem.childrens.length > 0">
                  <li *ngFor="let grandchild of subItem.childrens">
                    <div class="link2" *ngIf="grandchild.isHaved">
                      <a *ngIf="grandchild.link != ''"  
                      class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" [routerLink]="[grandchild.link ? '/' + grandchild.link : '']">
                        <i class="nav-icon fas {{ grandchild.icon }}"></i>
                        <p>{{ grandchild.title }}</p>
                      </a>
            
                    <a *ngIf="grandchild.link == ''"
                        class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                      <i class="nav-icon fas {{ grandchild.icon }}"></i>
                      <p>{{ grandchild.title }}</p>
                      <i class="nav-icon fas fa-angle-down right"></i>
                    </a>
    
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner> 

