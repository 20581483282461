<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          
        </div>
       
      </div>
    </div>
   
    <section class="content">
      <div class="container-fluid">

        <form>
          <div class="row">

            <div class="col-md-12">
              
              <!-- <ul class="nav nav-tabs nav-pills">
                <li>
                    <a id="topupdetail-tab" (click)="goToAdminDetail()" data-toggle="tab" href="#topupdetail">Topup</a>
                  </li>
                  <li>
                    <a id="withdrawaldetail-tab" (click)="resettab()" data-toggle="tab" href="#withdrawaldetail">Withdrawal</a>
                  </li>
              
              </ul> -->

              <div class="tab-content clearfix">

                <!-- <div class="tab-pane" id="topupdetail"> -->
                  <div class="row" *ngIf="type === 'TOPUP'">
                    <div class="col-md-12" >                                
                      <div class="card">
                          <div class="card-header">
                              <h3 class="card-title" style="font-weight: bold;">  Topup Bank Account Detail </h3>
                          </div>                         
                          <div class="card-body">
                              <form>                                  
                                  <div class="row">
                                      <label class="col-md-2">Bank Type</label>
                                      <div class="form-group col-md-10">
                                          <div class="row">
                                              <div id="paymentId" class="col-md-6" align="left">
                                                  <div class = "row">
                                                  <a *ngFor = "let item of paymentList">
                                                      <div class="col-lg-3">
                                                          <img id="{{item.id}}" (click) ="changeAction(item.id)" [src]="item.image_url" width = "80px" hegith = "80px" style="margin-top: 5%;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;"
                                                          class="grayscale-image-topup">
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                              <div id="paymentId1" class="col-md-6"  align="end">
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="row" >
                                      <label class="col-md-2"> Account Number </label>
                                   
                                      

                                      <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm" id="idaccno"
                                                 [(ngModel)]="paymentDTO.account_no"
                                                 [ngModelOptions]="{standalone: true}"
                                                 [disabled]="!hardcode_add_bool"> 
                                      </div>
                                      

                                    <div class = "col-md-1"></div>
                                      <label class="col-md-2"> Minimum Amount </label>
                                      <div class="form-group col-md-3">
                                          <input  type = "number" class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.min_amount"
                                              [ngModelOptions]="{standalone: true}">
                                      </div>
                                  </div>                   

                                  <div class="row">

                                      <label class="col-md-2"> Account Name </label>
                                      <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm" id = "idaccname"
                                              [(ngModel)]="paymentDTO.account_name"
                                              [ngModelOptions]="{standalone: true}"
                                              [disabled]="!hardcode_add_bool">
                                      </div>

                                      <div class = "col-md-1"></div>
                                      <label class="col-md-2"> Maximum Amount </label>
                                      <div class="form-group col-md-3">
                                          <input  type = "number" class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.max_amount"
                                              [ngModelOptions]="{standalone: true}">
                                      </div>
                                  </div>                               

                                  <div class="row">
                                      <label class="col-md-2"> Code Number </label>
                                      <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm"  id = "idcodenumber"
                                              [(ngModel)]="paymentDTO.code_number"
                                              [ngModelOptions]="{standalone: true}"
                                              [disabled]="!hardcode_add_bool">
                                      </div>

                                      <div class = "col-md-1"></div>
                                      <label class="col-md-2"> Limited Amount </label>
                                      <div class="form-group col-md-3">
                                          <input type = "number" class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.limited_amt"
                                              [ngModelOptions]="{standalone: true}">
                                      </div>
                                  </div>

                              
                          <div class="row" >
                              <label class="col-md-2"> Account Holder (Admin) </label>
                              
                              <div class="form-group col-md-3" id = "insertAdmin">
                                  <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                                  [ngModelOptions]="{standalone: true}" class = "form-control-sm"  title  = "Add New Admin" style="margin-left : -7px"
                                  >
                              </ngx-bootstrap-multiselect>
                              </div>
                             
                              <div class="form-group col-md-3" id = "updateAdmin">
                                   <table class="table table-bordered">
                                          <thead>
                                          </thead>
                                          <tbody>
                                      <tr *ngFor="let cadmin of paymentDTO.adminIdList">
                                      
                                          <td>
                                          {{cadmin.admin_name}}
                                          </td>
                                          <td>
                                          <span title = "Remove this admin" style = "color : red;cursor: pointer;"  id ="{{cadmin.id}}" class="fas fa-trash-alt" (click)= 'goModal($event)'></span>
                                         </td>
                                     </tr>
                                  </tbody>
                                  </table>
                             <ngx-bootstrap-multiselect [options]="myUpdOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                                  [ngModelOptions]="{standalone: true}" class = "form-control-sm"  title  = "Add New Admin" style="margin-left : -7px"
                                  >
                              </ngx-bootstrap-multiselect>

                              </div>

                              <div class = "col-md-1"></div>
                              <label class="col-md-2"> Max Account Count </label>
                              <div class="form-group col-md-3">
                                  <input  type = "number" class="form-control form-control-sm"
                                      [(ngModel)]="paymentDTO.maxAccountCount"
                                      [ngModelOptions]="{standalone: true}">
                              </div>
                          </div>
                         
                                  <div class="row">
                                      <label class="col-md-2"> Status </label>
                                      <div class="form-group col-md-3 radio">
                                          <div class="form-group clearfix">
                                              <div class="icheck-success d-inline">
                                                  <input type="radio" name="r3" checked id="radioSuccess1"
                                                      value="ACTIVE" [(ngModel)]="paymentDTO.status"
                                                      [ngModelOptions]="{standalone: true}">
                                                  <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                  </label>
                                              </div>
                                              <div class="icheck-success d-inline">
                                                  <input type="radio" name="r3" id="radioSuccess2"
                                                      value="INACTIVE" [(ngModel)]="paymentDTO.status"
                                                      [ngModelOptions]="{standalone: true}">
                                                  <label for="radioSuccess2">Inactive
                                                  </label>
                                              </div>
                                          </div>
                                      </div>

                                    <div class = "col-md-1"></div>
                                      <label class="col-md-2"> QR Code Image </label>

                                      <div class="form-group col-md-2">
                                          <input #file type="file" accept='image/*'
                                              (change)="preview(file.files)" /> 
                                              
                                              <img [src]="paymentDTO.imageUrl" height="200" width="255" *ngIf="paymentDTO.imageUrl"
                                              style="margin-top: 5%;">
                                      </div>

                                      <div class="form-group col-md-2">
                                      <button (click)="onDelete(paymentDTO.id)">
                                          <span title = "Remove this image" style = "color : red;cursor: pointer;"  class="fas fa-trash-alt"  ></span>
                                        </button>
                                      </div>

                                  </div>
                                  <div class="row">
                                      <label class="col-md-2"> Choose Display Option </label>
                                      <div class="form-group col-md-3">
                                               <select  class="isShowAll form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                               [(ngModel)]="paymentDTO.isShowAll" [ngModelOptions]="{standalone: true}" (change)="onSelectionChange($event)">                                                      
                                              <option value="1" selected>Only Account Number</option>
                                              <option value="2">Only QR Code Image</option>
                                              <option value="3">Both</option>
                                          </select>
                                      </div>                                    
                                  </div>
                                  <div class="row" style="margin-top: 1%;">
                                      <div class="col-md-2 col-sm-6">
                                          <button type="button" class="btn btn-block btn-success"
                                              (click)="goSave()">Save</button>&nbsp;</div>
                                      <div class="col-md-2 col-sm-6">
                                          <button type="button" class="btn btn-block btn-danger"
                                              [routerLink]="['/payment-info']"  [queryParams]="{ type: 'TOPUP' }"> Cancel </button></div>
                                  </div>

                              </form>
                          </div>
                      </div>
                  </div>
              </div>
              
              <div class="row" id="updatedata" style = "display: none;">
                  <div class="col-md-12">
                      <div class="card">
                          <div class="card-body">
                              <form>
                                  <div class="row" style="margin-top: 2%;">
                                      <label class="col-md-2"> Created Date </label>
                                      <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.createdDate"
                                              [ngModelOptions]="{standalone: true}" disabled>
                                      </div>
                                      <div class="col-md-1"></div>
                                      <label class="col-md-2"> Created By</label>
                                      <div class="col-md-3">
                                          <input class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.createdBy"
                                              [ngModelOptions]="{standalone: true}" disabled>
                                      </div>
                                  </div>

                                  <div class="row" style="margin-top: 1%;">
                                      <label class="col-md-2"> Updated Date </label>
                                      <div class="form-group col-md-3">
                                          <input class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.updatedDate"
                                              [ngModelOptions]="{standalone: true}" disabled>
                                      </div>
                                      <div class="col-md-1"></div>
                                      <label class="col-md-2"> Updated By </label>
                                      <div class="col-md-3">
                                          <input class="form-control form-control-sm"
                                              [(ngModel)]="paymentDTO.updatedBy"
                                              [ngModelOptions]="{standalone: true}" disabled>
                                      </div>
                                  </div>

                              </form>
                          </div>                        
                      </div>
                  </div>
                </div>
                <!-- </div> -->


                <!-- <div class="tab-pane" id="withdrawaldetail"> -->
                  <div class="row"  *ngIf="type === 'WITHDRAWAL'">
                    <div class="col-md-12">                                
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title" style="font-weight: bold;"> Withdrawal Bank Account Detail </h3>
                            </div>                           
                            <div class="card-body">
                                <form>                                    
                                    <div class="row">
                                        <label class="col-md-2">Bank Type</label>
                                        <div class="form-group col-md-10">
                                            <div class="row">
                                                <div id="paymentwithdrawalId" class="col-md-6" align="left">
                                                    <div class = "row">
                                                    <a *ngFor = "let item of paymentListwithdrawal">
                                                        <div class="col-lg-3">
                                                            <div class="col-lg-3">
                                                                <img [id]="item.id" (click)="changeActionForWithdrawal(item.id)" [src]="item.image_url" width="80px" height="80px" 
                                                                     style="margin-top: 5%; object-fit: cover; border: 3px solid #ffffff; border-radius: 10px;"
                                                                     [class.grayscale-image-with]="!isSelected(item.id)">
                                                            </div>                                                            
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                                <div id="paymentwithdrawalId1" class="col-md-6"  align="end">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
  
                                    <div class="row" >
                                        <label class="col-md-2"> Account Number </label>
                                     
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm" id="idaccnowithdrawal"
                                                   [(ngModel)]="paymentDTOWithdrawal.account_no"
                                                   [ngModelOptions]="{standalone: true}"
                                                   [disabled]="!hardcode_add_bool"> 
                                        </div>                                     
  
                                      <div class = "col-md-1"></div>                                      
                                    </div> 
                                    <div class="row">  
                                        <label class="col-md-2"> Account Name </label>
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm" id = "idaccnamewithdrawal"
                                                [(ngModel)]="paymentDTOWithdrawal.account_name"
                                                [ngModelOptions]="{standalone: true}"
                                                [disabled]="!hardcode_add_bool">
                                        </div>  
                                        <div class = "col-md-1"></div>                                       
                                    </div>
                                    <div class="row">
                                        <label class="col-md-2"> Code Number </label>
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm"  id = "idcodenumberwithdrawal"
                                                [(ngModel)]="paymentDTOWithdrawal.code_number"
                                                [ngModelOptions]="{standalone: true}"
                                                [disabled]="!hardcode_add_bool">
                                        </div>  
                                        <div class = "col-md-1"></div>   
                                    </div>                                  
                            <div class="row" >
                                <label class="col-md-2"> Account Holder (Admin) </label>                                
                                <div class="form-group col-md-3" id = "insertAdminWithdrawal">
                                    <ngx-bootstrap-multiselect [options]="myOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChangeWithdrawal()"
                                    [ngModelOptions]="{standalone: true}" class = "form-control-sm"  title  = "Add New Admin" style="margin-left : -7px"
                                    >
                                </ngx-bootstrap-multiselect>
                                </div>                               
                                <div class="form-group col-md-3" id = "updateAdminWithdrawal">
                                     <table class="table table-bordered">
                                            <thead>
                                            </thead>
                                            <tbody>
                                        <tr *ngFor="let cadmin of paymentDTOWithdrawal.adminIdList">
                                            <td>
                                            {{cadmin.admin_name}}
                                            </td>
                                            <td>
                                            <span title = "Remove this admin" style = "color : red;cursor: pointer;"  id ="{{cadmin.id}}" class="fas fa-trash-alt" (click)= 'goModal($event)'></span>
                                           </td>
                                       </tr>
                                    </tbody>
                                    </table>
                               <ngx-bootstrap-multiselect [options]="myUpdOptions" [(ngModel)]="optionsModel" (ngModelChange)="onChange()"
                                    [ngModelOptions]="{standalone: true}" class = "form-control-sm"  title  = "Add New Admin" style="margin-left : -7px"
                                    >
                                </ngx-bootstrap-multiselect>  
                                </div>  
                                <div class = "col-md-1"></div>                               
                            </div>

                                    <div class="row">
                                        <label class="col-md-2"> Status </label>
                                        <div class="form-group col-md-3 radio">
                                            <div class="form-group clearfix">
                                                <div class="icheck-success d-inline">
                                                    <input type="radio" name="r3" checked id="radioSuccess1"
                                                        value="ACTIVE" [(ngModel)]="paymentDTOWithdrawal.status"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label for="radioSuccess1">Active &nbsp;&nbsp;
                                                    </label>
                                                </div>
                                                <div class="icheck-success d-inline">
                                                    <input type="radio" name="r3" id="radioSuccess2"
                                                        value="INACTIVE" [(ngModel)]="paymentDTOWithdrawal.status"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <label for="radioSuccess2">Inactive
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
  
                                      <div class = "col-md-1"></div>                                    
  
                                    </div> 
  
                                    <div class="row">                                      
                                    </div> 
  
                                    <div class="row" style="margin-top: 1%;">
                                        <div class="col-md-2 col-sm-6">
                                            <button type="button" class="btn btn-block btn-success"
                                                (click)="goSaveWithdrawal()">Save</button>&nbsp;</div>
                                        <div class="col-md-2 col-sm-6">
                                            <button type="button" class="btn btn-block btn-danger"
                                                [routerLink]="['/payment-info']"   [queryParams]="{ type: 'WITHDRAWAL' }"> Cancel </button></div>
                                    </div>
  
                                </form>
                            </div>
                        </div>
                    </div>
  
                </div>
             
                <div class="row" id="updatedataWithdrawal" style = "display: none;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <form>
                                    <div class="row" style="margin-top: 2%;">
                                        <label class="col-md-2"> Created Date </label>
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm"
                                                [(ngModel)]="paymentDTOWithdrawal.createdDate"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <label class="col-md-2"> Created By</label>
                                        <div class="col-md-3">
                                            <input class="form-control form-control-sm"
                                                [(ngModel)]="paymentDTOWithdrawal.createdBy"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                    </div>
  
                                    <div class="row" style="margin-top: 1%;">
                                        <label class="col-md-2"> Updated Date </label>
                                        <div class="form-group col-md-3">
                                            <input class="form-control form-control-sm"
                                                [(ngModel)]="paymentDTOWithdrawal.updatedDate"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <label class="col-md-2"> Updated By </label>
                                        <div class="col-md-3">
                                            <input class="form-control form-control-sm"
                                                [(ngModel)]="paymentDTOWithdrawal.updatedBy"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                        </div>
                                    </div>
  
                                </form>
                            </div>
                          
                        </div>
                    </div>

                 </div>  
               <!-- </div> -->

              </div>
            </div>

          </div>
        </form>
        
      
      </div>
     
    </section>
   
  </div>

  <aside class="control-sidebar control-sidebar-dark">
  
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>

  <div id="deleteCash" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left:100px;">
                        <div class="form-group col-md-12">
                            <h5> Are you sure want to delete this Account Holder (Admin)? </h5>
                        </div>
                    </div>



                    <div class="row" style="margin-left: 10%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="deleteOk()"> Ok </button>
                        </div>

                        <div class="col-md-5 col-sm-6">
                            <button type="button" class="btn btn-block btn-danger" (click) = "hideModal()"> Cancel </button>
                        </div>
                    </div>

                    


                </form>
            </div>

        </div>
    </div>
</div>



</div>


