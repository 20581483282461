<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
      
      </div>
    </div>
 
    <section class="content">
      <div class="container-fluid">
        <form>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Viber Notification Detail </h3>
                </div>
                <div class="card-body">
                  <form>                  
                    <div class="row">
                      <label class="col-md-2"> Phone No </label>
                      <div class="form-group col-sm-3">
                        <input class="form-control form-control-sm"  [(ngModel)]="userDTO.phone_no" 
                          [ngModelOptions]="{standalone: true}">
                      </div>
                      <div class="col-md-1"></div>                     
                    </div>

                    



                    <div class="row">
                      <label class="col-md-2">From Date</label>
                      <div class="form-group col-sm-3">
                        <input id="approveFromDate" 
                               class="form-control form-control-sm" 
                               placeholder="yyyy-mm-dd" 
                               [ngxDatePicker]="dateInstanceApprove" 
                               [(ngModel)]="approvefromdate" 
                               [ngModelOptions]="{ standalone: true }" 
                               (ngModelChange)="onChangeApproveFromDate()" />
                        <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                    
                    <div class="row">
                      <label class="col-md-2">To Date</label>
                      <div class="form-group col-sm-3">
                        <input id="approveToDate" 
                               class="form-control form-control-sm" 
                               placeholder="yyyy-mm-dd" 
                               [ngxDatePicker]="dateInstanceApprove1" 
                               [(ngModel)]="approvetodate" 
                               [ngModelOptions]="{ standalone: true }" 
                               (ngModelChange)="onChangeApproveToDate()" />
                        <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                    
              

                    <div class="row">
                      <label class="col-md-2"> Caption </label>
                      <div class="form-group col-sm-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.caption"
                          [ngModelOptions]="{standalone: true}">
                      </div>
                      <div class="col-md-1"></div>                      
                    </div>

                    <div class="row">
                      <label class="col-md-2">Action Link </label>
                      <div class="form-group col-sm-3">
                        <input class="form-control form-control-sm" [(ngModel)]="userDTO.action_link"
                          [ngModelOptions]="{standalone: true}">
                      </div>
                      <div class="col-md-1"></div>                      
                    </div>
                    <br />                  
  

                    <div class="row">
                      <label class="col-md-2"> Content </label>
                      <div class="col-sm-3">
                        <textarea class="form-control form-control-sm" [(ngModel)]="userDTO.content"
                          [ngModelOptions]="{standalone: true}"> </textarea>
                      </div>
                      <div class="col-md-1"></div>                   
                    </div>


                    <br />   
                    

                  <div class="row">
                    <label class="col-md-2">  Image Link </label>
                    <div class="form-group col-md-2">
                        <input #file type="file" accept='image/*'
                            (change)="preview(file.files)" />              
                            <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
                            style="margin-top: 5%;">
                    </div>

                    <div class="form-group col-md-2">
                    <button (click)="deleteImage()">
                        <span title = "Remove this image" style = "color : red;cursor: pointer;"  class="fas fa-trash-alt"  ></span>
                      </button>
                    </div>

                </div>


                    <br />     

                    <div class="row">
                      <div class="form-group col-md-2">
                          <label>Send Now?</label><span class ="req">*</span>
                      </div>
                      <div class="form-group col-md-3 radio">
                        <div class="form-group clearfix">
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" checked id="radioSuccess1" [value]="true" [(ngModel)]="userDTO.sendNow" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess1">Yes &nbsp;&nbsp;
                            </label>
                          </div>
                          <div class="icheck-success d-inline">
                            <input type="radio" name="r3" id="radioSuccess2" [value]="false" [(ngModel)]="userDTO.sendNow" [ngModelOptions]="{standalone: true}">
                            <label for="radioSuccess2">No
                            </label>
                          </div>
                        </div>
                      </div>
                  </div>


                        
                    

                    <br />
                    <div class="row">
                      <div class="col-md-2 col-sm-6" *ngIf="hardcode_resend_bool == true">
                        <button type="button" class="btn btn-block btn-success" (click)="edit('Resend')">Resend</button>&nbsp;
                      </div> 
                      <!-- (click)="goSave('Resend')"  -->
                      <div class="col-md-2 col-sm-6" *ngIf="hardcode_resend_bool == false">
                        
                      </div>   
                      <!-- <div class="col-md-2 col-sm-6" *ngIf="hardcode_edit_bool == true">
                        <button type="button" class="btn btn-block btn-primary"
                        (click)="edit('Edit')">Edit</button>
                       
                      </div> -->
                      <div class="col-md-2 col-sm-6" *ngIf="hardcode_edit_bool == false">
                       
                      </div>

                      <div class="col-md-2 col-sm-6">
                        <button type="button" class="btn btn-block btn-danger"
                        (click)="Cancel()">Cancel</button>
                       
                      </div>

                      <div class="col-md-2"></div>
                     
                     
                    </div>

                    <div class="row">
                      <label class="col-md-2"></label>
                      <div class="form-group col-md-3">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </form>   

      </div>
      
    </section> 
    
  </div>

  <aside class="control-sidebar control-sidebar-dark">
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>


</div>











<!-- 


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
              <form>
                
                  <div class="row justify-content-center">
                      <div class="col-md-12">
                          <h3 class="text-center">Are you sure you want to delete?</h3>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 10%;margin-bottom: 3%;">                   

                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-danger" (click)="closeTran()"
                        [routerLink]="['/game-transaction-list']"> Cancel </button>
                      </div>

                      <div class="col-md-5">
                        <button type="button" class="btn btn-block btn-success" (click)="deletTran()"> Confirm </button>
                      </div>

                  </div>
              </form>
          </div>
      </div>
  </div>
</div> -->












