import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-threed-winners',
  templateUrl: './threed-winners.component.html',
  styleUrls: ['./threed-winners.component.css']
})
export class ThreedWinnersComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  idIndex: any;
  token: any;
  threedwinnerId: any;
  confrimId: any;
  phoneNo: string = '';
  name: string = '';
  singleDate: string = '';
  status: string = '';
  threedwinnerList: any;
  confirmAllCheck: any = false;
  confirmallArr: any = [{id:''}];
  isChecked: any = false;
  winnerDate: any;
  winnertodayDate: any;
  winnerchangeDate: any;
  todate: any; //add the following
  toDate : any;
  todatetodaydate : any;
  todatechangedate : any;

  alltodaytodate : any;
  alltodatechangeDate : any; //add this
  alltodayDate: any;
  allchangeDate: any;
  alltoDate :any;
  alltodate : any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  number:any;

  schedule : [];

 progressValue: number = 0;
 totalUser: number = 0;
 completeUser: number = 0;

 pollingInterval: any; // Declare an interval variable to manage the polling
isPolling: boolean = false; // To track polling state
circleOffset: number;
// isProgressVisible: boolean = false; 
// isConfirmed: boolean = false;


  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
     private router: Router, private funct: FunctService, private datepipe: DatePipe, private toastr: ToastrService) 
     {  
      this.storage.store('isNotiSong', "");
      this.hardcode_view = "Winners_View";
      this.hardcode_edit = "Winners_Edit";
      this.dto.menuCodes = this.storage.retrieve('menucodes');
      const filteredMenuCodes_view = [];  
      const filteredMenuCodeds_edit =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }    
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }
      });
      if(filteredMenuCodes_view.length != 0)
      {
            this.hardcode_view_bool = true;
      } 
      else
      {
        this.hardcode_view_bool = false;
      } 
      if(filteredMenuCodeds_edit.length != 0)
      {
            this.hardcode_edit_bool = true;
      }
      else
      {
        this.hardcode_edit_bool = false;
      }


    this.idIndex = 1;
    this.winnerDate = new Date();
    this.winnertodayDate = this.datepipe.transform(this.winnerDate, 'MMM dd, yyyy');
    this.alltodate = new Date();  //add this
    this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    this.search();
    
  }

  ngOnInit(): void 
  {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order: []
    }

    this.dtOptions.columnDefs = [
      { targets: [0], orderable: false },
      { targets: [6], orderable: true }
    ];

    // const isConfirmed = this.storage.retrieve('winnerConfirmed');
    // if (!isConfirmed) 
    // {
    //   this.startTaskAndPollProgress();
    // }
    // else{
    // }

    // const confirmedStatus = localStorage.getItem('winnerConfirmed');
    // if (confirmedStatus === 'true') {
    //   this.isConfirmed = true;
    // }
    
   


    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }
    // this.startTaskAndPollProgress();
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 201)
    {
      this.spinner.hide();
      this.router.navigate(['/winhistory/threed']).then(() => {
        this.toastr.error('Already Confirmed', 'Invalid!', {
          timeOut: 3000,
          positionClass: 'toast-top-right'
        });
      })
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblthreedWinners' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  search() {
    this.threedwinnerList = [];
    var id = 'tblthreedWinners' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    // this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.toDate = $("#toDate").val();
    this.alltoDate = $("#alltodate").val();

    if (this.singleDate == '' || this.singleDate == undefined) {
      console.log("date if case");
      this.winnerchangeDate = this.winnertodayDate;
    }
    else {
      console.log("date else case");
      this.winnerchangeDate = this.singleDate;
    }

    if (this.alltoDate == '' || this.alltoDate == undefined) {
      console.log("date if case for to date");
      this.alltodatechangeDate = this.alltodaytodate;
    }
    else {
      console.log("date else case");
      this.alltodatechangeDate = this.alltoDate;
    }
    if(this.number == undefined )
    {
        this.number ="";;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('phoneNo',this.phoneNo).set('name',this.name).set('fromDate',this.winnerchangeDate).set('toDate',this.alltodatechangeDate).set('status', this.status).set('num',this.number);    
    this.http.get(this.funct.ipaddress + 'winner/threed-by-params', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => 
      {
        this.dto.Response = {};
        console.log("result>> " + JSON.stringify(result));
        this.dto.Response = result;
        this.threedwinnerList = this.dto.Response;

        if(this.threedwinnerList != undefined)
        {
          for (let i = 0; i < this.threedwinnerList.length; i++) {
            if (this.threedwinnerList[i].status == "CONFIRMED") {
              this.threedwinnerList[i].uiDisable = true;
             }
             else if(this.threedwinnerList[i].status == "PENDING"){
              this.threedwinnerList[i].uiDisable = false;
            }
            if(this.threedwinnerList[i].status == "PENDING")
            {
              this.threedwinnerList[i].created_by = "";
            }
          }
        }
        this.dtTrigger.next();
        // this.spinner.hide();
      });
  }

  onInput(event: InputEvent) {
    const myanmarCharactersRegex = new RegExp('^[\u1000-\u109F\uAA60-\uAA7F\uA9E0-\uA9FF]+$');
    const inputValue = (event.target as HTMLInputElement).value; 
    if(event.data!=null)
    {
      if (myanmarCharactersRegex.test(inputValue)) 
      {        
        this.spinner.hide();
        this.toastr.error( "Myanmar typing detected!", '', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        });  
      }
    }   
  }

  ngOnDestroy(): void 
  {
    this.dtTrigger.unsubscribe();
  }

  onChangeSingle() {
    $(document).ready(function () {
      this.singleDate = $("#singleDate").val();
    });
  }

  onChangeAllToDate() {
    $(document).ready(function () {
      this.alltoDate = $("#alltodate").val();
    });
  }

  checkuncheckall() {
    if (this.isChecked == true) {
      this.isChecked = false;
    }
    else {
      this.isChecked = true;
    }
  }

  changeSelection(ev, id) {
    console.log("id..... " + id);
    var target = ev.target;
    this.confirmallArr = [];

    if (target.checked && id == 0) {
      for (let i = 0; i < this.threedwinnerList.length; i++) {
        this.threedwinnerList[i].status = "check";
      }
    }
    else if (target.checked && id != 0) {
      for (let i = 0; i < this.threedwinnerList.length; i++) {
        if (this.threedwinnerList[i].id == id) {
          this.threedwinnerList[i].status = "check";
        }
      }
    }
    else if (!target.checked && id == 0) {
      for (let i = 0; i < this.threedwinnerList.length; i++) {
        this.threedwinnerList[i].status = "uncheck";
      }
    }
    else if (!target.checked && id != 0) {
      for (let i = 0; i < this.threedwinnerList.length; i++) {
        if (this.threedwinnerList[i].id == id) {
          this.threedwinnerList[i].status = "uncheck";
        }
      }
    }
    console.log(" this.threedwinnerList >>" + JSON.stringify(this.threedwinnerList));

    this.checkedData();
  }

  checkedData() {
    for (let i = 0; i < this.threedwinnerList.length; i++) 
    {
      if (this.threedwinnerList[i].status == "check") {
        this.confirmallArr.push(this.threedwinnerList[i].id);
      }
    }
    console.log("this.confirmallArr >>" + JSON.stringify(this.confirmallArr));
  }

  confirmAll() 
  {
    console.log("click confirmall");
  //  this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let formData = new FormData();
    formData.append("ids",this.confirmallArr); 
    this.http.post(this.funct.ipaddress + 'winner/threedConfirms_new', formData, {headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => 
      {          
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success')
        {
          
          this.search();
           this.startTaskAndPollProgress();  
          this.threedwinnerList = this.dto.Response.threedwinnerListDTO
          for (let i = 0; i < this.threedwinnerList.length; i++)
           {
            if (this.threedwinnerList[i].status == "CONFIRMED") {
              this.threedwinnerList[i].uiDisable = true;
             }
             else if(this.threedwinnerList[i].statsu == "PENDING"){
              this.threedwinnerList[i].uiDisable = false;
            }
          }          
    
         
        }
        else 
        {
         
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
      
  }


  confirmOne(id) 
  {

    console.log("confirm id>>> " + id);
    this.confrimId = id;
    this.confirmallArr = [];
    this.confirmallArr.push(this.confrimId);
    console.log("this.confirmallArr>>" + JSON.stringify(this.confirmallArr));
  //  this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let formData = new FormData();
    formData.append("ids",this.confirmallArr);
    this.http.post(this.funct.ipaddress + 'winner/threedConfirms_new', formData, {headers: headers })
    .pipe(  
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => 
      {   
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') 
        {
          this.search();
          this.startTaskAndPollProgress();  

          this.threedwinnerList = this.dto.Response.threedwinnerListDTO
          for (let i = 0; i < this.threedwinnerList.length; i++) 
          {
            if (this.threedwinnerList[i].status == "CONFIRMED") 
            {
              this.threedwinnerList[i].uiDisable = true;
             }
             else if(this.threedwinnerList[i].checkStatus == "PENDING")
             {
              this.threedwinnerList[i].uiDisable = false;
            }
          }         
         
        }
        else 
        {
          
          this.spinner.hide(); 
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }      
      });  
   
  }


calculateCircleOffset(totalUser: number, completeUser: number)
 {
    const circumference = 339.292;
    const progressCircle = document.getElementById('progress');
    const percentageText = document.getElementById('percentage-text');
    const userText = document.getElementById('user-text');
    if (totalUser === 0) {
        console.error('Total Users cannot be 0.');
        return;
    }
    const progressPercentage = (completeUser / totalUser) * 100;
    const offset = circumference - (progressPercentage / 100) * circumference;
    if (progressCircle) {
        progressCircle.style.strokeDashoffset = offset.toString();
    }
    if (percentageText) {
        percentageText.textContent = `${Math.round(progressPercentage)}%`;
    }
    if (userText) {
        userText.textContent = `${completeUser}/${totalUser}`;
    }
}

  threedConfirms_task_schedule()
   { 
    this.schedule = [];
    this.dto.token = this.storage.retrieve('token');  
    let headers = new HttpHeaders().set('Authorization', this.dto.token);  
    this.http.get(this.funct.ipaddress + 'winner/threedConfirms_task_schedule', { headers })
      .subscribe({
        next: (result: any) => {
          if (result.status === 'Success' && result.data) 
          {
            this.dto.Response = result.data || {};
            this.schedule = this.dto.Response;  
            this.totalUser = this.dto.Response.totalUser || 0;
            this.completeUser = this.dto.Response.completeUser || 0;          
            this.progressValue = this.totalUser > 0 
              ? Math.round((this.completeUser / this.totalUser) * 100) 
              : 0;
            } 
            else 
            {
            console.error('Unexpected response or no data available');
          }
          this.calculateCircleOffset(this.totalUser, this.completeUser);
         
        },
        error: (error) => 
        {
          console.error('Failed to fetch schedule:', error.message);
          alert('An error occurred while fetching the schedule. Please try again later.');
          this.spinner.hide(); 
        }
      });
  }
  
  

  
  incrementProgress() {
    if (this.progressValue < 100) {
      const interval = setInterval(() => {
        this.progressValue += 10; 
        if (this.progressValue >= 100) {
          clearInterval(interval); 
          console.log('Progress completed!');
        }
      }, 500); 
    }
  }




  fetchDataAndUpdateCircle() {
    this.http.get(this.funct.ipaddress + 'progress/status')
    .subscribe((response: any) => {
        this.incrementProgress();
        this.circleOffset = response.progress;  
    });
  
}


// startTaskAndPollProgress() 
// {
//   this.progressValue = 0;
//   this.pollingInterval = setInterval(() => 
//   {
//       this.threedConfirms_task_schedule();
//       this.search();
//   }, 1000);  
// }


startTaskAndPollProgress() {
  this.progressValue = 0;

  if (this.pollingInterval) {
    clearInterval(this.pollingInterval);  // Clear any existing interval
  }

  this.pollingInterval = setInterval(() => {
    if (this.progressValue < 100) {
      this.threedConfirms_task_schedule();
      // this.search();
    } else {
      // Call search one last time when progress reaches 100%
      this.search();

      // Stop further polling
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }, 1000);
}


}
