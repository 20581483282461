<!-- <div class="wrapper"> -->
  <!-- <app-header-sidebar></app-header-sidebar> -->

  <!-- <div class="content-wrapper"> -->
    <!-- <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-8">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> SMS Operators </h3>
          </div>
        </div>
      </div>
    </div> -->
    
    <!-- <section class="content">
      <div class="container-fluid">
        <form>
          
        </form>
      </div>      
    </section> -->
  <!-- </div> -->


  <div class="row">

            
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" style="font-weight: bold;"> Providers List</h3>
        </div>       
        <div class="card-body">
          <table width='100%' id="{{'tblAgent' + idIndex}}" datatable [dtTrigger]="dtTrigger"
            [dtOptions]="dtOptions" class="table table-bordered table-hover">
            <thead>
              <tr>
                <th style="font-family: unicode;" class="center-text">Type</th>
                  
                <th style="font-family: unicode;" class="center-text">Status</th>      
                <th style="font-family: unicode;" class="center-text">Updated Date</th> 
                <th style="font-family: unicode;" class="center-text"> Updated By </th>                
                <th style="font-family: unicode;" class="center-text" *ngIf="hardcode_edit_bool === true">Action</th>                        
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let agent of agentList">
                <td style="font-family: unicode;" class="center-text">{{
                  agent.name === 'vmg_viber' ? 'Viber' :
                  agent.name === 'email' ? 'Email' :
                  agent.name === 'sms_poh' ? 'SMS Poh' :
                  agent.name
                }}</td>   
                <td style="font-family: unicode;" class="center-text">{{agent.enable}}</td>                                 
                <td style="font-family: unicode;" class="center-text">{{agent.updated_date_str }}</td>                                           
                <td style="font-family: unicode;" class="center-text">{{agent.updated_by}}</td>  

            <td style="font-family: unicode;"  class="center-text" *ngIf="hardcode_edit_bool === true">
              <input
                style="cursor: pointer;"
                type="checkbox"
                [checked]="agent.enable === true"
                [ngModel]="agent.enable === true"
                [ngModelOptions]="{ standalone: true }"
                (change)="goOnOffSave(agent.id,agent.enable,agent.name)"
              />
            </td>
          

              </tr>                     
            </tbody>
          </table>
        </div>              
      </div>
    </div>
  </div>

  <aside class="control-sidebar control-sidebar-dark">   
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
<!-- </div> -->

<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>