<div class="row">

    <div class="form-group col-md-2">
        <label>Phone No</label>
        <input class="form-control form-control-sm" [(ngModel)]="phoneNo" [ngModelOptions]="{standalone: true}"
            (input)="onInput($event)">
    </div>

    <div class="form-group col-md-2">
        <label>Name</label>
        <input class="form-control form-control-sm" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
    </div>

    <div class="form-group col-md-2">
        <label>Number</label>
        <input class="form-control form-control-sm" [(ngModel)]="number" [ngModelOptions]="{standalone: true}">
    </div>

    <div class="form-group col-md-2">
        <label> From Date </label>
        <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
            [ngxDatePicker]="dateInstanceSingle" [value]="singleDate" [(ngModel)]="winnerDate"
            [ngModelOptions]="{standalone: true}" (valueChange)="onChangeSingle()">
        <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
    </div>

    <div class="form-group col-md-2">
        <label>To Date</label>
        <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
            [ngxDatePicker]="dateInstanceSingle1" [value]="alltoDate" [(ngModel)]="alltodate"
            [ngModelOptions]="{standalone: true}" (valueChange)="onChangeAllToDate()">
        <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
    </div>

    <div class="form-group col-md-2" *ngIf="hardcode_edit_bool === true || hardcode_view_bool === false">
        <label> Status </label>
        <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="status"
            [ngModelOptions]="{standalone: true}">
            <option value="CONFIRMED" selected> Confirmed </option>
            <option value="PENDING"> Pending </option>
            <option value=""> All </option>
        </select>
    </div>

    <div class="form-group col-md-2 col-sm-4 col-xs-4">
        <label></label>
        <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
            (click)="search()">Search</button>
    </div>


    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title" style="font-weight: bold;"> 3D Winners </h3>
            </div>

            <div class="card-body">
                <!-- <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" *ngIf="hardcode_edit_bool === true">
                                        <button id="confirm" type="button" class="btn btn-block btn-primary btn-sm"
                                            style="margin-bottom: 12%;" (click)="confirmAll()"> Confirm </button>
                                    </div> -->


                <!-- <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%;" *ngIf="hardcode_edit_bool === true">
                        <button id="confirm" type="button" class="btn btn-block btn-primary btn-sm"
                        style="margin-bottom: 12%;" (click)="confirmAll()"> Confirm </button>
                      </div>
                  
                      <div class="progress-container d-flex" style="width: 60%; height: 20px;">
                        <div class="progress" style="flex: 1; height: 100%; background-color: #e9ecef; border-radius: 5px;">
                          <div class="progress-bar" role="progressbar" [style.width.%]="progressValue"
                            style="background-color: #007bff; border-radius: 5px;" aria-valuemin="0" aria-valuemax="100" 
                            [attr.aria-valuenow]="progressValue">
                          </div>
                        </div>
                        <span class="ml-2" style="white-space: nowrap;">
                          {{ completeUser }}/{{ totalUser }}
                        </span>
                      </div>
                    </div>
                  </div>                -->


                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="col-md-2 col-sm-4 col-xs-4" style="margin-left: -0.7%; margin-bottom: 2px;" *ngIf="hardcode_edit_bool === true">
                        <button id="confirm" type="button" class="btn btn-block btn-primary btn-sm" style="margin-bottom: 12%;" (click)="confirmAll()"> Confirm </button>
                      </div>
                  
                      <!-- *ngIf="isProgressVisible" *ngIf="totalUser && !isConfirmed" -->
                      <div   *ngIf="totalUser"   class="progress-circle" id="progress-circle" style="margin-left: 400px;">
                        <svg width="80" height="80" viewBox="0 0 120 120">
                          <circle cx="60" cy="60" r="54" stroke="lightgray" stroke-width="12" fill="none"></circle>
                          <circle cx="60" cy="60" r="54" stroke="blue" stroke-width="12" fill="none" stroke-dasharray="339.292" stroke-dashoffset="339.292" id="progress"></circle>
                        </svg>
                        <div id="progress-text">
                          <span id="percentage-text">0%</span>
                          <br />
                          <span id="user-text">0/0</span>
                        </div>
                      </div>
                      
                      
                  </div>
                  

                <table width='100%' id="{{'tblthreedWinners' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                    [dtOptions]="dtOptions" class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th *ngIf="hardcode_edit_bool === true || hardcode_view_bool === false">
                                <input type="checkbox" name="winnerscheck" (click)="checkuncheckall()"
                                    style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                    (change)="changeSelection($event,0)">
                            </th>
                            <th style="font-family: unicode;">Phone No</th>
                            <th style="font-family: unicode;">Name</th>
                            <th style="font-family: unicode;">Number</th>
                            <th style="font-family: unicode;">Bet Amount</th>
                            <th style="font-family: unicode;">Odds Amount</th>
                            <th style="font-family: unicode;">Win Amount</th>
                            <th style="font-family: unicode;">Bet Date</th>
                            <th style="font-family: unicode;">Confirm By</th>
                            <th style="font-family: unicode;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let threedwin of  threedwinnerList">
                            <td style="font-family: unicode;"
                                *ngIf="hardcode_edit_bool === true || hardcode_view_bool === false">
                                <input type="checkbox" name="winnerscheck" [checked]="isChecked"
                                    value="{{threedwin.id}}" style="width: 1.10rem;height: 1.10rem;margin-left: 28%;"
                                    (change)="changeSelection($event,threedwin.id)">
                            </td>             
                            <td style="font-family: unicode;">
                                <a [routerLink]="['/user-detail', threedwin.user_id]"
                                    [queryParams]="{ type: 'threedwinnerslist' }">{{threedwin.phone_no}}</a>
                            </td>
                            <td style="font-family: unicode;">{{threedwin.name}}</td>
                            <td style="font-family: unicode;">{{threedwin.number}}</td>
                            <td style="font-family: unicode;">{{threedwin.amount_Str}}</td>
                            <td style="font-family: unicode;">{{threedwin.odd}}</td>
                            <td style="font-family: unicode;">{{threedwin.balance_Str}}</td>
                            <td style="font-family: unicode;">{{threedwin.created_date_Str}}</td>
                            <td style="font-family: unicode;">{{threedwin.created_by}}</td>
                            <td style="font-family: unicode;" *ngIf="threedwin.uiDisable">
                                <a style="color: #28a745;"> Confirmed </a>
                            </td>
                            <td style="font-family: unicode;" *ngIf="!threedwin.uiDisable">
                                <a style="color: #007bff; cursor: pointer;" (click)="confirmOne(threedwin.id)"> Confirm
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

</div>




<app-footer></app-footer>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
</ngx-spinner>



<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device.
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>