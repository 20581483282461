import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'

import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-smsprovider',
  templateUrl: './smsprovider.component.html',
  styleUrls: ['./smsprovider.component.css']
})
export class SmsProviderComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  phoneNo: string= '';
  phone_no: string= '';
  name: string= '';
  accountno:string= '';
  referralCode: string= '';
  status: boolean = false;
  type: string= '';
  agentList: any;
  idIndex: any;
  gameproviderList : any;
  providerId : any;
  alldate: any;
  alltodate:any;
  alltodayDate: any;
  alltodaytodate:any;
  singleDate: string = '';
  approveDate: string = '';
  alltodatechangeDate : any;
  allchangeDate: any;
  alltoDate: string = '';
  token: any;
  id :any;
  updated_by:any;
  status_sim:any;
  checkbox:any;
  isStatus: string= '';

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_delete:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_delete_bool:any;
  hardcode_double_bool:any;

  status_old :any;
  status_new :any;
  name_new :any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private funct: FunctService,private datepipe: DatePipe) 
    {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;

    ///this.hardcode_view = "SMSOperators_View";
    this.hardcode_edit = "OTPProviders_Edit";  
    this.dto.menuCodes = this.storage.retrieve('menucodes');

    //const filteredMenuCodes_view = [];
    const filteredMenuCodeds_edit =[];
  
    this.dto.menuCodes.forEach(menuCode => {
      // if (menuCode === this.hardcode_view) {
      //   filteredMenuCodes_view.push(menuCode);
      // }
    
      if (menuCode === this.hardcode_edit) {
        filteredMenuCodeds_edit.push(menuCode);
      }
     
      
    });
    // if(filteredMenuCodes_view.length != 0)
    // {
    //       this.hardcode_view_bool = true;
    // }
  
    if(filteredMenuCodeds_edit.length != 0)
    {
          this.hardcode_edit_bool = true;
    }
  
    this.Search();
    
   }
  ngOnInit(): void {   
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {        
        this.router.navigated = false;       
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,
      order:[],
      paging: false,
      info : true,
      dom: "Bfrltip", 
    }

    this.dtOptions.columnDefs = [
    ];
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
   
  }
  ngAfterViewInit(){

  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblAgent' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    Search(){
    this.agentList = [];
    var id = 'tblAgent' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex +1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.http.get(this.funct.ipaddress + 'user/getsmstypebyadmin', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }



  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  // goOnOffSave(id , status)
  //  {
  //   this.id = id;
  //   this.status = status;
  //   // this.status = this.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';  
  //   this.spinner.show();
  //   this.token = this.storage.retrieve('token');
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', this.token); 
  //   const formData = new FormData();
  //   formData.append("id",this.id);
  //   formData.append("enable",this.status);
  //   this.http.post(this.funct.ipaddress + 'user/updatesmstypes', formData, { headers: headers })
  //   .pipe(
  //     catchError(this.handleError.bind(this))
  //    )
  //   .subscribe( 
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       if (this.dto.Response.status == 'Success') {
  //         this.spinner.hide();
  //         this.toastr.success(this.dto.Response.message, 'Success!', {
  //           timeOut: 5000,
  //           positionClass: 'toast-top-right'
  //         });
  //         this.Search();
          
  //       }
  //       else {
  //         this.spinner.hide();
  //         this.toastr.error(this.dto.Response.message, 'Invalid!', {
  //           timeOut: 5000,
  //           positionClass: 'toast-top-right',
  //         });
  //       }
  //     }
  //   );
  // }


  goOnOffSave(id: string, status: boolean , name :string) 
  {
    this.status = status;
    this.status = this.status === true ? false : true;
    this.spinner.show();
    this.name = name;
    // this.token = this.storage.retrieve('token');  
    // const headers = new HttpHeaders()
    //   .set('Authorization', this.token)
    //   .set('Content-Type', 'application/json'); 

    this.dto.token = this.storage.retrieve('token');    
    let editedArray = this.getEditedArrayForBankAccountDelete();      
    let headers = new HttpHeaders();
    if(editedArray.length > 0)
    {    
      headers = headers.set('Authorization',  this.dto.token).set("editedArray",editedArray);
    }
    else{
      headers = headers.set('Authorization',  this.dto.token);
    } 
     
    const payload = [
      {
        id: id,
        enable: this.status,
      },
    ];
  
    this.http.post(this.funct.ipaddress + 'user/updatesmstypes', payload, { headers })
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe(result => {
        this.spinner.hide();
        this.dto.Response = result;  
        if (this.dto.Response.status === 'Success')
         {
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
          this.Search();
        } else {
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
        }
      });
  }
  

  
  getEditedArrayForBankAccountDelete() 
  {
    let editedArray = ''; 
    if(this.status == true)
    {
          this.status_new = "ON";
          this.status_old = "OFF";
    }
    else 
    {
      this.status_new = "OFF";
      this.status_old = "ON";
    }
    if(this.name =="sms_poh")
    {
         this.name_new = "SMS Poh";
    }
    else if(this.name =="vmg_viber")
    {
      this.name_new = "Viber";
    }
    else{
      this.name_new = "Email";
    }
      if (this.status_old !== undefined || this.status_old !== null || this.status_old !=="") 
      {
        editedArray += this.name_new + ': ' +  this.status_old  + ' -> '+  this.status_new + ', ';
      }     
      if (editedArray.endsWith(', ')) 
      {
        editedArray = editedArray.slice(0, -2);
      }    
      return editedArray;

      
  }


}
