import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-twod-manual-detail',
  templateUrl: './twod-manual-detail.component.html',
  styleUrls: ['./twod-manual-detail.component.css']
})
export class TwoDManualDetailComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  id: any;
  changedate: any;
  date: string = '';
  status: string = '';
  token: any;
  type : any;
  section:any;
  sectionTwoDHistoryLogs:any;
  twodSet:any;
  twodValue:any;
  twodResult:any;
  isDone:any;
  manual10_30:any;
  manual12_01:any;
  manual02_30:any;
  manual04_30:any;
  manualOnOffList:any;
  twoDHistoryLogsList:any;
  idIndex:any;
  idTwoDHistoryLogsIndex:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dttwoDHistoryLog: Subject<any> = new Subject();

  hardcode_2DHistoryLogs_view :any;
  hardcode_SwitchSections :any;
  hardcode_2DLiveResult: any;
  DLiveSectionConfig_Edit:any;
  hardcode_2DHistoryLogs_Edit :any;
  hardcode_2DHistoryLogs_Add:any;
  hardcode_2DLiveResult_bool : any;
  hardcode_SwitchSections_bool : any;
  hardcode_2DHistoryLogs_bool : any;
  hardcode_2DHistoryLogs_Edit_bool : any;
  hardcode_2DHistoryLogs_Add_bool : any;
  hardcode_DLiveSectionConfig_Edit :any;
  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) {
      this.storage.store('isNotiSong', "");
      this.section="Null";
      this.sectionTwoDHistoryLogs="10:30 AM";
      this.idIndex = 1;
      
      
      this.hardcode_2DLiveResult = "2DLiveResult";

      this.hardcode_SwitchSections = "SwitchSections"
      this.DLiveSectionConfig_Edit = "DLiveSectionConfig_Edit";

      this.hardcode_2DHistoryLogs_view = "2DHistoryLogs_View";     
      this.hardcode_2DHistoryLogs_Edit="2DHistoryLogs_Edit";
      this.hardcode_2DHistoryLogs_Add = "2DHistoryLogs_Add";
      this.dto.menuCodes = this.storage.retrieve('menucodes');

      const filteredMenuCodes_2DLiveResult = [];
      const filteredMenuCodeds_SwitchSections =[];
      const filteredMenuCodeds_2DHistoryLogs =[];
      const filteredMenuCodeds_2DHistoryLogs_Edit =[];
      const filteredMenuCodeds_2DHistoryLogs_Add =[];
      const filteredMenuCodeds_DLiveSectionConfig_Edit =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_2DLiveResult) {
          filteredMenuCodes_2DLiveResult.push(menuCode);
        }
        if (menuCode === this.hardcode_SwitchSections) {
          filteredMenuCodeds_SwitchSections.push(menuCode);
        }
        if (menuCode === this.hardcode_2DHistoryLogs_view) {
          filteredMenuCodeds_2DHistoryLogs.push(menuCode);
        }
        if (menuCode === this.hardcode_2DHistoryLogs_Edit) {
          filteredMenuCodeds_2DHistoryLogs_Edit.push(menuCode);
        }
        if (menuCode === this.hardcode_2DHistoryLogs_Add) {
          filteredMenuCodeds_2DHistoryLogs_Add.push(menuCode);
        }
        if (menuCode === this.DLiveSectionConfig_Edit) {
          filteredMenuCodeds_DLiveSectionConfig_Edit.push(menuCode);
        }
      });
      if(filteredMenuCodes_2DLiveResult.length != 0)
      {
          this.hardcode_2DLiveResult_bool = true;
      }
      if(filteredMenuCodeds_SwitchSections.length != 0)
      {
          this.hardcode_SwitchSections_bool = true;
      }
      if(filteredMenuCodeds_2DHistoryLogs.length != 0)
      {
          this.hardcode_2DHistoryLogs_bool = true;
      }
      if(filteredMenuCodeds_2DHistoryLogs_Edit.length != 0)
      {
          this.hardcode_2DHistoryLogs_Edit_bool = true;
      }
      if(filteredMenuCodeds_2DHistoryLogs_Add.length != 0)
      {
          this.hardcode_2DHistoryLogs_Add_bool = true;
      }
      if(filteredMenuCodeds_DLiveSectionConfig_Edit.length != 0)
      {
          this.hardcode_DLiveSectionConfig_Edit = true;
      }

      this.idTwoDHistoryLogsIndex = 1;
      this.getLiveData();
     }
  ngOnInit(): void {
    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 1);
    }
    else {
      this.storage.clear('loadFlag');
    }
    this.dtOptions = {
      responsive: true,
      order: []
    }
    this.dtOptions.columnDefs = [
    ];
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dttwoDHistoryLog.unsubscribe();
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 404)
    {
      this.spinner.hide();
      this.toastr.error("Not Found", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 
  onChangeDate() {
    $(document).ready(function () {
      this.date = $("#date").val();
    });
  }
  getLiveData(){
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'back4app/GetLiveData', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response == null)
        {    
          this.spinner.hide();
        }
        else
        {
          this.twodSet=this.dto.Response.set;
          this.twodValue=this.dto.Response.value;
          this.twodResult=this.dto.Response.result;
          this.spinner.hide();
        }
       
      });
  }
  getTwodManualOnOff(){
    this.spinner.show();
    this.manualOnOffList =[];
    var id = 'tblManual' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'back4app/GetManualOnOff', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.manualOnOffList=this.dto.Response;
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }
  getTwoDHistoryLog(){
    this.spinner.show();
    this.twoDHistoryLogsList =[];
    var id = 'tblTwoDHistoryLog' + this.idTwoDHistoryLogsIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idTwoDHistoryLogsIndex = this.idTwoDHistoryLogsIndex + 1;
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
    params = params.set('section', this.sectionTwoDHistoryLogs);
    this.http.get(this.funct.ipaddress + 'back4app/GetLive2DLog', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response == null)
        {      
          this.dttwoDHistoryLog.next();
          this.spinner.hide();
        }
        else
        {
          this.dto.Response = result.results;
          this.twoDHistoryLogsList=this.dto.Response;
          this.dttwoDHistoryLog.next();
          this.spinner.hide();
        }
        
      });
  }

  search() {
    this.twoDHistoryLogsList = [];
    var id = 'tblTwoDHistoryLog' + this.idTwoDHistoryLogsIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idTwoDHistoryLogsIndex = this.idTwoDHistoryLogsIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);    
    let params = new HttpParams();
    params = params.set('section', this.sectionTwoDHistoryLogs);
    this.http.get(this.funct.ipaddress + 'back4app/GetLive2DLog', { params: params, headers: headers }).
    pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};   
        this.dto.Response = result; 
        if(this.dto.Response == null)
        {          
          this.dttwoDHistoryLog.next();
          this.spinner.hide();  
        }
        else
        {
          this.dto.Response = result.results;
          this.twoDHistoryLogsList = this.dto.Response;
          this.dttwoDHistoryLog.next();
          this.spinner.hide();  
        }     
      }
    );
  }

  goOnOffSave() {
    this.spinner.show();this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let param = {
      manual10_30 : this.manualOnOffList.filter(data => data.section == '10:30 AM')[0].switchManual,
      manual12_01 : this.manualOnOffList.filter(data => data.section == '12:01 PM')[0].switchManual,
      manual02_30 : this.manualOnOffList.filter(data => data.section == '02:30 PM')[0].switchManual,
      manual04_30 : this.manualOnOffList.filter(data => data.section == '04:30 PM')[0].switchManual,
    }
    this.http.post(this.funct.ipaddress + 'back4app/SetManualOnOff', param, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 5000,
            positionClass: 'toast-top-right'
          });
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }
  goConfigSave() {
    this.spinner.show();
    if (this.twodSet != '' && this.twodValue != '' && this.twodResult !='' && this.section!='Null') {
      this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      let param = {
        section : this.section,
        set : this.twodSet,
        value : this.twodValue,
        result : this.twodResult,
        isDone : this.isDone
      }
      console.log("Save >>>>" +JSON.stringify(param))
      headers = headers.set('Authorization', this.token);
      this.http.post(this.funct.ipaddress + 'back4app/SetManualResult', param, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe( 
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.spinner.hide();
            this.twodResult='';
            this.twodSet='';
            this.twodValue='';
            this.section='Null';
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 5000,
              positionClass: 'toast-top-right'
            });
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
          }
        }
      );
    }
    else {
      this.spinner.hide();
      this.toastr.error('Please fill all fields', 'Invalid input!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
  }

  IsShowHistory() {
    this.spinner.show();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let param = {
      history10_30 : this.manualOnOffList.filter(data => data.section == '10:30 AM')[0].switchHistory,
      history12_01 : this.manualOnOffList.filter(data => data.section == '12:01 PM')[0].switchHistory,
      history02_30 : this.manualOnOffList.filter(data => data.section == '02:30 PM')[0].switchHistory,
      history04_30 : this.manualOnOffList.filter(data => data.section == '04:30 PM')[0].switchHistory,
    }
    this.http.post(this.funct.ipaddress + 'back4app/SetHistoryOnOff', param, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe( 
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') {
          this.spinner.hide();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 5000,
            positionClass: 'toast-top-right'
          });
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
  }

  // checkUncheck()
  // {
  //   if($("#foo_bar").is(':checked'))
  //    {
  //     this.isMaintenance = true;
  //    }
  //   else
  //   {
  //     this.isMaintenance = false;
  //   }
      
  // }
}
