<div class="wrapper">

    <app-header-sidebar></app-header-sidebar>


    <div class="content-wrapper">

        <div class="content-header">
            <div class="container-fluid">

            </div>
        </div>


        <section class="content">
            <div class="container-fluid">
                <form>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Withdrawal Detail </h3>
                                </div>

                                <div class="card-body">
                                    <form>

                                        <div class="row">
                                            <label class="col-md-2"> User Account</label>

                                            <div class="form-group col-md-3">
                                                <a *ngIf="userFinancialTransactionDTO.phone_no"
                                                    [routerLink]="['/user-detail', userFinancialTransactionDTO.user_id]"
                                                    [queryParams]="{ type: 'withdrawaldetail' , id : userFinancialTransactionDTO.id}">
                                                    {{ userFinancialTransactionDTO.phone_no }}</a>


                                            </div>

                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> User Name </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11">
                                                        <input type="string" class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.name"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <label class="col-md-2">Payment</label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <a *ngFor="let item of paymentList">
                                                        <div class="col-lg-3">
                                                            <img id="{{item.id}}" [src]="item.image_url" width="80px"
                                                                hegith="80px"
                                                                style="margin-top: 5%;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                                                        </div>
                                                    </a>

                                                </div>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <label class="col-md-4" id="description"> Description </label>
                                                    <div class="form-group col-md-6" id="description1">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.description"
                                                            [ngModelOptions]="{standalone: true}"> </textarea>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Updated Date </label>
                                            <div class="form-group col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updated_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updated_by"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>





                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Amount </label>
                                            <div class="form-group col-md-3">
                                                <input id="amount" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.amount_Str"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Requested Date </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.created_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>


                                        <div class="row" id="status">
                                            <label class="col-md-2"> Status </label>

                                            <div class="form-group col-md-3">
                                                <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                    [(ngModel)]="userFinancialTransactionDTO.status"
                                                    [ngModelOptions]="{standalone: true}" id="approvestatus">
                                                    <option *ngFor="let status of statusList_temp" value={{status}}>
                                                        {{status}}

                                                </select>
                                            </div>


                                            <div class="col-md-1"></div>
                                            <div class="col-md-6" *ngIf="oldstatus !== 'APPROVED'">
                                                <div class="row">
                                                    <label class="col-md-4"> Withdrawal Bank Account </label>
                                                    <div class="form-group col-md-6">
                                                        <select
                                                            class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="withdrawalbankacc_id"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [disabled]="isDisabled">
                                                            <option *ngFor="let account of withdrawalBankAccountList"
                                                                [value]="account.id">
                                                                {{ account.account_name }} - {{ account.account_no }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-6" *ngIf="oldstatus === 'APPROVED'">
                                                <div class="row">
                                                    <label class="col-md-4"> Withdrawal Bank Account </label>
                                                    <div class="form-group col-md-6">
                                                        <select
                                                            class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="userFinancialTransactionDTO.withdrawbankAcc"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [disabled]="isDisabled">
                                                            <option
                                                                [value]="userFinancialTransactionDTO.withdrawbankAcc">
                                                                {{ userFinancialTransactionDTO.withdrawbankAcc }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button id="idsave" type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/withdraw-check-list']">Cancel</button>
                                                  
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div> -->



                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;"> Withdrawal Detail </h3>
                                </div>

                                <div class="card-body">
                                    <form>                                   


                                        <div class="row">
                                            <label class="col-md-2">Payment</label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <a *ngFor="let item of paymentList">
                                                        <div class="col-lg-3">
                                                            <img id="{{item.id}}" [src]="item.image_url" width="80px"
                                                                hegith="80px"
                                                                style="margin-top: 5%;object-fit: cover;border: 3px solid #ffffff;border-radius: 10px;border-color: #ffffff;">
                                                        </div>
                                                    </a>

                                                </div>
                                            </div>
                                          
                                            <div class="col-md-1"></div>
                                            <div class="col-md-6" *ngIf="oldstatus !== 'APPROVED'">
                                                <div class="row">
                                                    <label class="col-md-4"> Withdrawal Bank Account </label>
                                                    <div class="form-group col-md-6">
                                                        <select
                                                            class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="withdrawalbankacc_id"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [disabled]="isDisabled">
                                                            <option *ngFor="let account of withdrawalBankAccountList"
                                                                [value]="account.id">
                                                                {{ account.account_name }} - {{ account.account_no }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-6" *ngIf="oldstatus === 'APPROVED'">
                                                <div class="row">
                                                    <label class="col-md-4"> Withdrawal Bank Account </label>
                                                    <div class="form-group col-md-6">
                                                        <select
                                                            class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                            [(ngModel)]="userFinancialTransactionDTO.withdrawbankAcc"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [disabled]="isDisabled">
                                                            <option
                                                                [value]="userFinancialTransactionDTO.withdrawbankAcc">
                                                                {{ userFinancialTransactionDTO.withdrawbankAcc }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>



                                        <div class="row">
                                            <label class="col-md-2"> User Account</label>

                                            <div class="form-group col-md-3">
                                                <a *ngIf="userFinancialTransactionDTO.phone_no"
                                                    [routerLink]="['/user-detail', userFinancialTransactionDTO.user_id]"
                                                    [queryParams]="{ type: 'withdrawaldetail' , id : userFinancialTransactionDTO.id}">
                                                    {{ userFinancialTransactionDTO.phone_no }}
                                                </a>
                                            </div>

                                            <div class="col-md-1"></div>                                       

                                            <label class="col-md-2"> Status </label>
                                            <div class="col-md-3">
                                                <select class="form-control form-control-sm col-md-12 col-sm-4 col-xs-4"
                                                        [(ngModel)]="userFinancialTransactionDTO.status"
                                                        [ngModelOptions]="{standalone: true}" id="approvestatus">
                                                        <option *ngFor="let status of statusList_temp" value={{status}}>
                                                            {{status}}
    
                                                    </select>
                                            </div>


                                        </div>

                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> User Name</label>

                                            <div class="form-group col-md-3">
                                                <input type="string" class="form-control form-control-sm"
                                                [(ngModel)]="userFinancialTransactionDTO.name"
                                                [ngModelOptions]="{standalone: true}">
                                            </div>

                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Requested Time </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.created_date"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>



                                        <div class="row" style="margin-top: 1%;">
                                            <label class="col-md-2"> Amount </label>
                                            <div class="form-group col-md-3">
                                                <input id="amount" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.amount_Str"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Updated Time </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                [(ngModel)]="userFinancialTransactionDTO.updated_date"
                                                [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <label class="col-md-4" id="description"> Description </label>
                                                    <div class="form-group col-md-6" id="description1">
                                                        <textarea class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.description"
                                                            [ngModelOptions]="{standalone: true}"> </textarea>
                                                    </div>
                                                </div>
                                            </div>                                            
                                            <!-- <div class="col-md-1"></div> -->
                                            <label class="col-md-2"> Updated By </label>
                                            <div class="col-md-3">
                                                <input class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.updated_by"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>
                                        </div>


                                        <div class="row" style="margin-top: 1%;">
                                            <div class="col-md-2 col-sm-6">
                                                <button id="idsave" type="button" class="btn btn-block btn-success"
                                                    (click)="goSave()">Save</button>&nbsp;
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                                <button type="button" class="btn btn-block btn-danger"
                                                    [routerLink]="['/withdraw-check-list']" [queryParams]="{ type: 'withdrawalalllistcancel' }">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>



                </form>

            </div>

        </section>




        <!-- ‌amk -->

        <!-- <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">User Bank Account Detail </h3>
                                </div>

                                <div class="card-body">
                                    <form>

                                       



                                        <div class="row">
                                            <label class="col-md-2"> Last Top Up Bank Type </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11">
                                                        <input id="banktype" type="string"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.last_topup_type"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keypress)="numericOnly($event)" disabled>
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                       
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Transfer From </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11">
                                                        <input id="transferfrom" type="string"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.tran_from"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keypress)="numericOnly($event)">
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                        <button id="approvedAccBtnFrom" type="button"
                                                            class="btn btn-sm btn-success btn-fixed-size"
                                                            (click)="goApproveForFrom()">Approved</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="row">
                                            <label class="col-md-2" id="wavePassword"> Wave Password </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11" id="wavePassword">
                                                        <input id="wp" type="number" class="form-control form-control-sm"
                                                        [(ngModel)]="userFinancialTransactionDTO.wavePassword"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keypress)="numericOnly($event)">
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                    
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Bank Account Name </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11">
                                                        <input id="bankaccountname" type="string" class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.bankAccountName"
                                                            [ngModelOptions]="{standalone: true}" >
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                        <button id="savedbtn" type="button"
                                                            class="btn btn-sm btn-success btn-fixed-size"
                                                            (click)="goEditSave()">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="row">
                                            <label class="col-md-2"> QR Image</label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11" >
                                                        <a (click)="showQrModal()" class="col-md-10" style="text-decoration: underline; color: blue !important;">View QR</a>
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                    
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-md-1"></div>
                                            <label class="col-md-2"> Bank Account No </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11">
                                                        <input id="accountNo" type="number"
                                                            onKeyPress="if(this.value.length==6) return false;"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.account_no"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keypress)="numericOnly($event)">
                                                    </div>
                                                    <div class="form-group col-md-1">
                                                        <button id="approvedAccBtn" type="button"
                                                            class="btn btn-sm btn-success btn-fixed-size"
                                                            (click)="goApprove()">Approved</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>

        </section> -->





        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">User Bank Account Detail </h3>
                                </div>

                                <div class="card-body">
                                    <form>
                                        <div class="row">
                                            <label class="col-md-2"> Bank Account No </label>
                                            
                                            <div class="form-group col-md-3">
                                                    <div class="row">
                                                        <div class="form-group col-md-8">
                                                            <input id="accountNo" type="number"
                                                            onKeyPress="if(this.value.length==6) return false;"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.account_no"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keypress)="numericOnly($event)">
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <button id="approvedAccBtn" type="button"
                                                                class="btn btn-sm btn-success btn-fixed-size"
                                                                (click)="goApprove()">Approved</button>
                                                        </div>
                                                    </div>
                                                </div>



                                            <div class="col-md-1"></div>
                                      
                                            <label class="col-md-2"> Transfer From </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-8">
                                                        <input id="transferfrom" type="string"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.tran_from"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keypress)="numericOnly($event)">
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <button id="approvedAccBtnFrom" type="button"
                                                            class="btn btn-sm btn-success btn-fixed-size"
                                                            (click)="goApproveForFrom()">Approved</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <label class="col-md-2"> Bank Account Name </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-8">
                                                        <input id="bankaccountname" type="string" class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.bankAccountName"
                                                            [ngModelOptions]="{standalone: true}" >
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <button id="savedbtn" type="button"
                                                            class="btn btn-sm btn-success btn-fixed-size"
                                                            (click)="goEditSave()">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1"></div>

                                            <label class="col-md-2"> Last Top Up Bank Type </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-12">
                                                        <input id="banktype" type="string"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.last_topup_type"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keypress)="numericOnly($event)" disabled>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="row">
                                            <label class="col-md-2" id="wavePassword"> Wave Password </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-12" id="wavePassword">
                                                        <input id="wp" type="number" class="form-control form-control-sm"
                                                        [(ngModel)]="userFinancialTransactionDTO.wavePassword"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keypress)="numericOnly($event)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-1"></div>
                                            
                                        </div>




                                        <div class="row">
                                            <label class="col-md-2"> QR Image </label>
                                            <div class="form-group col-md-3">
                                                <div class="row">
                                                    <div class="form-group col-md-11" >
                                                        <a (click)="showQrModal()" class="col-md-10" style="text-decoration: underline; color: blue !important;">View QR</a>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-md-1"></div>
                                        
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

            </div>

        </section>









        <!-- 
        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">User Detail </h3>
                                </div>
                               
                                <div class="card-body">
                                    <form>
                                        
                                        <div class="row" id="status">
                                            <label class="col-md-2">Total Top-up</label>
                                            <div class="form-group col-md-3">
                                                <input id="totalTopup" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.totalTopup_str"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                            </div>

                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row">                                         
                                                    <label class="col-md-4">Total Withdrawal</label>
                                                    <div class="form-group col-md-6">
                                                        <input id="totalWith" type="string" class="form-control form-control-sm"
                                                        [(ngModel)]="userFinancialTransactionDTO.totalWith_str"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keypress)="numericOnly($event)" disabled>
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div> 
                                        


                                        <div class="row" id="status">
                                            <label class="col-md-2">Current Wallet</label>
                                           
                                                <div class="form-group col-md-3">
                                                    <input id="balance" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.balance"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                                </div>

                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row" >
                                                    <label class="col-md-4"> Freeze Amount </label>
                                                    <div class="form-group col-md-6">
                                                        <input id="freeze_balance_Str" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.freeze_balance_Str"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                          


                                        <div class="row" id="status">
                                            <label class="col-md-2"> Top-up Bank List</label>
                                           
                                                <div class="form-group col-md-3">
                                                    <input id="tranFroms" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.tranFroms"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                                </div>

                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row" >
                                                    <label class="col-md-4"> Withdrawal Bank List</label>
                                                    <div class="form-group col-md-6">
                                                        <input id="bank_accounts" type="string" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.bank_accounts"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keypress)="numericOnly($event)" disabled>
                                                    </div>
                                                </div>
                                            
                                            </div>

                                        </div>
                                        
                                        
                                        
    

                                    </form>
                                </div>
                             
                            </div>
                        </div>
                    </div>

                   

                </form>
               
            </div>
            
        </section> -->



        <section class="content">
            <div class="container-fluid">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title" style="font-weight: bold;">User Detail </h3>
                                </div>
                                <div class="card-body">
                                    <form>
                                        <div class="row" id="status">
                                            <label class="col-md-2">Total Top-up</label>
                                            <div class="form-group col-md-3">
                                                <input id="totalTopup" type="text" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.totalTopup_str"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>

                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <label class="col-md-4">Total Withdrawal</label>
                                                    <div class="form-group col-md-6">
                                                        <input id="totalWith" type="text"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.totalWith_str"
                                                            [ngModelOptions]="{standalone: true}" disabled>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" id="status">
                                            <label class="col-md-2">Current Wallet</label>
                                            <div class="form-group col-md-3">
                                                <input id="balance" type="text" class="form-control form-control-sm"
                                                    [(ngModel)]="userFinancialTransactionDTO.balance"
                                                    [ngModelOptions]="{standalone: true}" disabled>
                                            </div>

                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <label class="col-md-4">Freeze Amount</label>
                                                    <div class="form-group col-md-6">
                                                        <input id="freeze_balance_Str" type="text"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="userFinancialTransactionDTO.freeze_balance_Str"
                                                            [ngModelOptions]="{standalone: true}" disabled>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Top-up Bank List -->
                                        <div class="row" id="status">
                                            <label class="col-md-2">Top-up Bank List</label>
                                            <div class="form-group col-md-3">
                                                <div
                                                    *ngFor="let bank of userFinancialTransactionDTO.tranFroms; let i = index">
                                                    <input id="tranFroms{{i}}" type="text"
                                                        class="form-control form-control-sm"
                                                        [(ngModel)]="userFinancialTransactionDTO.tranFroms[i]"
                                                        [ngModelOptions]="{standalone: true}" disabled>
                                                </div>
                                            </div>

                                            <div class="col-md-1"></div>
                                            <div class="col-md-6">
                                                <!-- Withdrawal Bank List -->
                                                <div class="row">
                                                    <label class="col-md-4">Withdrawal Bank List</label>
                                                    <div class="form-group col-md-6">
                                                        <div
                                                            *ngFor="let bank of userFinancialTransactionDTO.bank_accounts; let i = index">
                                                            <input id="bank_accounts{{i}}" type="text"
                                                                class="form-control form-control-sm"
                                                                [(ngModel)]="userFinancialTransactionDTO.bank_accounts[i]"
                                                                [ngModelOptions]="{standalone: true}" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>







    </div>

    <aside class="control-sidebar control-sidebar-dark">

    </aside>

    <app-footer></app-footer>

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
        <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>

</div>


<div id="deleteData" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <form>

                    <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                        <div class="form-group col-md-12">
                            <h5> This Account is used by another device.
                                Please Sign Out. Login Again. </h5>
                        </div>
                    </div>

                    <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                        <div class="col-md-5 col-sm-6" id="deleteBtn">
                            <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>


<!-- 
<div *ngIf="isQrModalVisible" id="qr" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content" style="width: fit-content !important;">
            <div class="modal-header">
                <button type="button" (click)="closeQrModal()" class="close">x</button>
            </div>
            <div class="modal-body text-center">
          
                <img *ngIf="userFinancialTransactionDTO?.qrImageUrl; else noQrTemplate" 
                     [src]="userFinancialTransactionDTO.qrImageUrl" 
                     alt="QR Code" class="ng-star-inserted">
                
             
                <ng-template #noQrTemplate>
                    <p>No QR image to display</p>
                </ng-template>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div> -->



<div id="qrModal" class="modal" [ngClass]="{'show-modal': isQrModalVisible}">
    <div class="modal-content">
        <span class="close" (click)="closeQrModal()">&times;</span>
        <div class="modal-body text-center">
            <!-- Conditionally show QR code or message -->
            <img *ngIf="userFinancialTransactionDTO?.qrImageUrl; else noQrTemplate" 
                 [src]="userFinancialTransactionDTO.qrImageUrl" 
                 alt="QR Code" 
                 class="qr-image" />
            
            <ng-template #noQrTemplate>
                <p>No QR image to display</p>
            </ng-template>
        </div>
    </div>
</div>



