

          <div class="row">
		  
		      <div class="form-group col-md-2">
              <label>Caption</label>
              <input id='phoneNo' type="text" list="users" class="form-control form-control-sm" [(ngModel)]="caption"
                [ngModelOptions]="{standalone: true}" (input)="onInput($event)">              
          </div>
			

            <div class="form-group col-md-2 col-sm-4 col-xs-4" >
              <label></label>
              <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                (click)="search()">Search</button>
            </div>
			


            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title" style="font-weight: bold;"> Viber Notification List </h3>
                </div>
             
                <div class="card-body">
                  <table width='100%' id="{{'tbltwodbet' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                    [dtOptions]="dtOptions" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;  width: 2px;" class="text-center" >Receiver</th>
                        <th style="font-family: unicode;  width: 2px;" class="text-center" >Caption</th>
                        <th style="font-family: unicode;  width: 2px;" class="text-center" >Action Link</th>
                        <th style="font-family: unicode;  width: 300px;" class="text-center" >Content </th>
                        <th style="font-family: unicode;  width: 0px;" class="text-center" >Created By </th>
                        <th style="font-family: unicode;  width: 2px;" class="text-center" >Created Date</th>
                        <th style="font-family: unicode;  width: 0px;" class="text-center" >Updated By</th>                    
                        <th style="font-family: unicode;  width: 2px;" class="text-center" >Updated Date</th>
                        <th style="font-family: unicode; width: 2px;" class="text-center"  *ngIf="hardcode_double == true">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let twodbet of twodbetList">                    
                        <td style="font-family: unicode; width: 2px;" class="text-center" >
                           {{twodbet.phone_no}}
                        </td>
                        <td style="font-family: unicode; width: 2px;" class="text-center" >{{twodbet.caption}}</td>
                        <td style="font-family: unicode; width: 2px;" class="text-center" >{{twodbet.action_link}}</td>
                        <td style="font-family: unicode; width: 300px;" class="text-center" >{{twodbet.content}}</td>
                        <td style="font-family: unicode; width: 0px;" class="text-center" >{{twodbet.created_by_name}}</td>                      
                        <td style="font-family: unicode; width: 2px;" class="text-center" >{{twodbet.created_date_time_Str | date : 'yyyy-MM-dd HH:mm'}}</td>                   
                        <td style="font-family: unicode; width: 0px;" class="text-center" >{{twodbet.updated_by_name}}</td>
                       
                        <td style="font-family: unicode; width: 2px;" class="text-center">{{twodbet.updated_date_Str | date : 'yyyy-MM-dd HH:mm'}} </td>
                        <td style="font-family: unicode; width: 2px;" class="text-center"  *ngIf="hardcode_double == true">                          
                            <a *ngIf="hardcode_edit_bool == true">
                           <i class="far fa-eye" style="color:#4b88eb; cursor: pointer;"
                            (click)="goModal(twodbet.id)"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a *ngIf="hardcode_delete_bool == true">
                              <i class="fas fa-trash" title="Delete" (click)="delete(twodbet.id)" style="color:#dc3545;cursor: pointer;"></i>
                            </a>

                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <tr *ngFor="let twodbet of twodbetList | paginate
                          : {
                              itemsPerPage: itemsPerPage,
                              currentPage: page,
                              totalItems: totalItems
                            }" 
                            >
                        </tr> 
                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls
                            class="pagi"
                            (pageChange)="qty((page = $event))"
                          ></pagination-controls>
                      </div>
                      </div>


                </div>
               
              </div>
            </div>
          </div>

    
   

 


          <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
            <p style="font-size: 16px; color: white">Loading...</p>
          </ngx-spinner>


          
<div id="deleteDataForTutorial" class="modal fade">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">

      <div class="modal-body text-center">
        <h5 id="deleteModalLabel">Are you sure you want to cancel?</h5>
        <div class="d-flex justify-content-center mt-4">
          <button type="button" class="btn btn-danger mx-2" (click)="closeTran()" [routerLink]="['/vibernotification/list']">Cancel</button>
          <button type="button" class="btn btn-success mx-2" (click)="deleteLive()">Confirm</button>
        </div>
      </div>

    </div>
  </div>
</div>



<div id="browseAccountData" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" style="font-weight: bold;font-size: 1.1rem;"> 2D Bet History Detail </h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"> x </button>
      </div>

      <div class="modal-body">
        <form>
          <div class="col-md-12">
            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-2"> PhoneNo </label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="phoneNo2" [ngModelOptions]="{standalone: true}"
                  disabled>
              </div>
              <div class="col-md-1"></div>
              <label class="col-md-2">Total</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="totalAmount"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>

            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-2">Name</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}"
                  disabled>
              </div>
              <div class="col-md-1"></div>
              <label class="col-md-2">Created Date</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="createdDate"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>

            <div class="row" style="margin-left: 0.1%;">
              <label class="col-md-6"></label>
              <label class="col-md-2">Section Time</label>
              <div class="form-group col-md-3">
                <input class="form-control form-control-sm" [(ngModel)]="bet_time"
                  [ngModelOptions]="{standalone: true}" disabled>
              </div>
            </div>
            <div class="col-md-12" style="margin-top: 0.5%;">
              <div class="card">
                <div class="card-body">
                  <table width='100%' id="{{'tbltwodbetdetail' + idIndex2}}" datatable [dtTrigger]="dtTrigger2"
                    [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style="font-family: unicode;">No</th>
                        <th style="font-family: unicode;">Bet Number</th>
                        <th style="font-family: unicode;">Amount</th>
                        <!-- <th style="font-family: unicode;">Result</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let twodbet of twoDBetDetailDTOList, let i = index">
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{ i + 1 }}</td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.number}}
                        </td>
                        <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.total_amount_Str}}
                        </td>
                        <!-- <td style="font-family: unicode; padding: 0.35rem 0.75rem 0.30rem 0.75rem;">{{twodbet.result_number}}
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </form>
      </div>

    </div>
  </div>



<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-body">
              <form>
                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>
                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>




