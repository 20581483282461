import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams ,HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';


declare const getCurrentDate: any;
declare var $: any;

export class AppModule {
}
@Component({
  selector: 'app-viber-notification-addnew',
  templateUrl: './viber-notification-addnew.component.html',
  styleUrls: ['./viber-notification-addnew.component.css'],
  providers: [DatePipe]

})
export class ViberNotificationAddnewListComponent implements OnInit {
  title: string ='';
  body: string = '';
  dtTrigger: Subject<any> = new Subject();
  model : any;
  frontend_parameter :any;
  myDate = new Date();
  notiId : any;
  
  
  hardcode_view : any;
  hardcode_add:any;
  hardcode_edit:any;
  hardcode_action:any;
  hardcode_delete: any;
  hardcode_delete_bool : any;
  hardcode_view_bool : any;
  hardcode_edit_bool :any;
  hardcode_add_bool : any;
  hardcode_double : any;
  endDate:any;
  startDate:any;
  approvefromdate:any;
  approvetodate:any;
  approvetodayDate:any;
  approvetodaytodate:any;
  phone_no:any;
  
  approveFromDate: any;
  approveToDate:any;
  approvechangeDate:any;
  approvetodatechangeDate:any;
  caption:any;
  action_link:any;
  content:any;
  qrImageUrl:any;
  imagePath: any;
  imgURL:any;
  imageFileName :any;
  filePath:any;
  message:any;
  
  constructor( private route: ActivatedRoute,private toastr: ToastrService,private datePipe: DatePipe,private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService, private router: Router, private funct: FunctService, private datepipe: DatePipe) 
  {
    this.storage.store('isNotiSong', ""); 
    this.hardcode_view = "ViberNotification_View";
    this.hardcode_add = "ViberNotification_Add";
    this.hardcode_edit = "ViberNotification_Edit";
    this.hardcode_delete = "ViberNotification_Del";

    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_view =[];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    this.dto.menuCodes.forEach(menuCode =>
     {
    if (menuCode === this.hardcode_delete) {
      filteredMenuCodes_delete.push(menuCode);
    }   
    if (menuCode === this.hardcode_view) {
      filteredMenuCodeds_view.push(menuCode);
    }
    if (menuCode === this.hardcode_add) {
      filteredMenuCodeds_add.push(menuCode);
    }
    if (menuCode === this.hardcode_edit) {
      filteredMenuCodeds_edit.push(menuCode);
    }
   
 
  });
      if(filteredMenuCodes_delete.length != 0)
      {
          this.hardcode_delete_bool = true;
      }
      else
      {
        this.hardcode_delete_bool = false;
      }    
   
      if(filteredMenuCodeds_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }
      else
      {
        this.hardcode_view_bool = false;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
      else
      {
        this.hardcode_edit_bool = false;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
          this.hardcode_add_bool = true;
      }
      else
      {
        this.hardcode_add_bool = false;
      }
      if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
      {
          this.hardcode_double = false;
      }
      else
      {
        this.hardcode_double = true;
      }



  //   if (!this.storage.retrieve('notificationFromDate'))
  //   {
  //    this.approvefromdate = new Date(); 
  //    this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');   
  //  }
  //  else {
  //    this.approvefromdate = new Date(this.storage.retrieve('notificationFromDate'));
  //    this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
  //  }

  //  if (!this.storage.retrieve('notificationToDate')) {
  //   this.approvetodate = new Date();  
  //   this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
  // }
  // else {
  //   this.approvetodate = new Date(this.storage.retrieve('notificationToDate'));
  //   this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
  // } 
  
  
    //  this.approvefromdate = new Date(); 
    //  this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy'); 
    //  this.approvetodate = new Date();  
    //  this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
  
  }
  ngOnInit(): void 
  {
    this.notiId = this.route.snapshot.paramMap.get("id");
    if (this.notiId == null) 
    {
      $(document).ready(function () 
      {
      });      

      this.model = {
        caption: '',
        content: '',
        action_link: '',
        phone_no : '',
        from_date : '' ,
        to_date:'',
        sendNow:false,
        img_link :'',
        img_type:''
      }

    }
    else
     {
      $(document).ready(function () {
      });     

      this.model = {
        caption: '',
        content: '',
        action_link: '',
        phone_no : '',
        from_date : '' ,
        to_date:'',
        sendNow:false,
        img_link :'',
        img_type:''
      }      
  
    }   
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
    //this.goModal();
  }



  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    }



  goSave() 
  {    
     this.edit();   
  }




edit() 
{
        if(this.caption == null || this.caption == undefined || this.caption =="")
        {
          this.toastr.error("Please add Caption", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
        }
        if(this.action_link == null || this.action_link == undefined || this.action_link =="")
        {
          this.toastr.error("Please add Action Link", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
        }
        if(this.content == null || this.content == undefined || this.content =="")
        {
          this.toastr.error("Please add Content", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
        }
      
        this.approveFromDate = $("#approveFromDate").val();
        this.approveToDate = $("#approveToDate").val();
    
       if (this.approveFromDate == '' || this.approveFromDate == undefined)
        {
           
          this.approvechangeDate = this.approvetodayDate;
        
        }
        
        else 
        {
             
          this.approvechangeDate = this.approveFromDate;
       
        }
          
        
        if (this.approveToDate == '' || this.approveToDate == undefined) 
        {
            
          this.approvetodatechangeDate = this.approvetodaytodate;
        
        }
        
        else 
        {
            
          this.approvetodatechangeDate = this.approveToDate;
        
        }
  
        this.model.caption = this.caption;
        this.model.content = this.content;
        this.model.action_link = this.action_link;
        this.model.phone_no = this.phone_no;
        // this.model.from_date = this.approvechangeDate;
        // this.model.to_date = this.approvetodatechangeDate;
  
        this.model.from_date = this.formatDate(this.approvechangeDate);
        this.model.to_date = this.formatDate(this.approvetodatechangeDate);
        if(this.model.from_date == "" || this.model.to_date=="")
        {
          this.model.to_date = null;
          this.model.from_date = null;

        }
        this.model.sendNow = true;
        this.model.img_link = this.imgURL;

        //amk
      if (this.model.img_link === null || this.model.img_link  === ''  || this.model.img_link  === undefined) 
      {      
        this.model.img_link = this.model.img_link;
      }   
      else
      {
          if(this.model.img_link .includes('data:image/jpeg;base64,'))
          this.model.img_link = this.model.img_link.replace("data:image/jpeg;base64,","");
          if(this.model.img_link.includes('data:image/png;base64,'))
          this.model.img_link = this.model.img_link.replace("data:image/png;base64,","");
         
      }  
      if(this.model.img_link == undefined || this.model.img_link == "" || this.model.img_link == null)
      {
        this.model.img_link ="";
        this.model.img_type ="";
      }
      else
      {
        this.model.img_type =".jpg";
      }
    

        if((this.phone_no == null || this.phone_no == undefined) && (this.model.from_date == undefined || this.model.from_date ==null))
        {
          this.toastr.error("Please add Phone No / Date ", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return;
        }
  
          this.spinner.show();
          this.dto.token = this.storage.retrieve('token');
  
          let headers = new HttpHeaders().set('Authorization', this.dto.token);
          this.frontend_parameter = { ...this.model };
 
  this.http.post(this.funct.ipaddress + 'viberNotification/SendNoti', this.frontend_parameter, { headers: headers })
    .pipe(catchError(this.handleError.bind(this)))
    .subscribe({
      next: result => 
      {
        console.log("HTTP Response:", result);
        this.dto.Response = result;      
        if (this.dto.Response.status === 'Success') 
        {
          this.spinner.hide();
          this.router.navigate(['/vibernotification/list']).then(() => {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          });
        } 
        else 
        {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      },      
      error: error => 
      {
        this.spinner.hide();
        this.toastr.error(error.error.message, 'Error!', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
        });
      
      }
      
    });
   
}



onChangeApproveFromDate() {

  this.storage.store('notificationFromDate', this.approvefromdate.toString());
}

onChangeApproveToDate()
{
  this.storage.store('notificationToDate', this.approvetodate.toString());
}

private formatDate(date: Date | null): string {
  if (!date) return '';
  return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
}

preview_old(files) {
  if (files.length === 0)
    return;
  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {

    return;
  }

  var reader = new FileReader();
  this.imagePath = files;
  reader.readAsDataURL(files[0]);
  reader.onload = (_event) => {
    this.imgURL = reader.result;
  }
}

deleteImage(): void {
  this.imgURL = null; // Clear the image preview
  this.imageFileName = null; // Clear the file name
  console.info('Image deleted successfully');
}

preview(files) {
  if (files.length === 0)
    return;
  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }
  var reader = new FileReader();
  this.imagePath = files;
  this.filePath = files[0];
  reader.readAsDataURL(files[0]);
  reader.onload = (_event) => {
    this.imgURL = reader.result;
   
  }
}


}
