import { ChangeDetectorRef, Component, NgZone ,ElementRef, Input, OnInit, ViewChild ,AfterViewInit} from '@angular/core';
import { DtoService } from '../../service/dto.service';
import { UtilService } from '../../service/util.service';
import { FunctService } from '../../service/funct.service';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { parseISO, formatDistanceToNow } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-header-sidebar',
  templateUrl: './header-sidebar.component.html',
  styleUrls: ['./header-sidebar.component.css']
})
export class HeaderSidebarComponent implements OnInit {
  menuList: [];
  notidata : any;
  getvalue:any;
  permissionList: any;
  menuItems: any; searchString: any;
  searchResults: string[] = [];
  roleList: any;
  @Input() title: string | undefined;
  @Input() code1: string | undefined;
  @Input() code2: string | undefined;
  @ViewChild('parentMenu') parentMenu: ElementRef;
  @ViewChild('notificationSound', { static: false }) notificationSound!: ElementRef;
  //@HostListener('document:click', ['$event'])

  selectedParentMenu: string;
  activeItem: any;
  adminNmae: any;
  dashboard_bool :any;

  type_filter: any;

  selectedChildMenu: string | null = null;
  openedMainMenu: number | null = null;
  CurrentAdminName: any;
  accordionItems: { title: string; icon: string; isOpen: boolean; submenuItems: string[]; }[];

  showDropdown = false;
  hasClickedNotification = false;
  isDropdownToggled = false;
  unreadCount: number = 0; 
  noti_total : number =0;
  notiCount_total =0;
  noti_id :any;

  hardcode_edit :any;
  hardcode_add :any;
  hardcode_view: any;
  hardcode_add_Account_Number:any;
  hardcode_add_Account_Name:any;
  hardcode_add_Code_Number:any;
  hardcode_view_bool : any;
  hardcode_add_bool : any;
  hardcode_edit_bool : any;
  hardcode_acc_holder_bool :any;
  hardcode_acc_holder :any;
  subscription :any;

  isDropdownVisible: boolean = false;
  isMutedCondition: any ; 
   isCondition :any; 
   speakerfornotipoint :any;
   speakerMute :any;


   //amk added for menu
   activeTab: string = ''; 
   tabs: Array<{ title: string; link: string; active: boolean }> = [];

  constructor(private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private http: HttpClient, private dto: DtoService, private storage: LocalStorageService, private util: UtilService,
    private funct: FunctService,private toastr: ToastrService,private eRef: ElementRef,private zone: NgZone) 
    {            
      this.hardcode_view = "BankAccount_View";
      this.hardcode_edit = "BankAccount_Edit";
      this.hardcode_add = "BankAccount_Add";
      this.hardcode_acc_holder ="BankAccountDetail";      
      this.dto.menuCodes = this.storage.retrieve('menucodes');
      const filteredMenuCodes_view = [];
      const filteredMenuCodeds_add =[];
      const filteredMenuCodeds_edit =[];
      const filteredMenuCodeds_acc_holder =[];

      this.dto.menuCodes.forEach(menuCode => 
        {
        if (menuCode === this.hardcode_view) 
        {
          filteredMenuCodes_view.push(menuCode);
        }
        if (menuCode === this.hardcode_add) 
        {
          filteredMenuCodeds_add.push(menuCode);
        }
        if (menuCode === this.hardcode_edit)
         {
          filteredMenuCodeds_edit.push(menuCode);
        }
        if (menuCode === this.hardcode_acc_holder) 
        {
          filteredMenuCodeds_acc_holder.push(menuCode);
        }
      });
      if(filteredMenuCodes_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }
      else
      {
        this.hardcode_view_bool = false;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
          this.hardcode_add_bool = true;
      }
      else{
        this.hardcode_add_bool = false;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
      else{
        this.hardcode_edit_bool = false;
      }
      if(filteredMenuCodeds_acc_holder.length != 0)
      {
          this.hardcode_acc_holder_bool = true;
      }
      else
      {
        this.hardcode_acc_holder_bool = false;
      }
      
  }

  //amk

  ngOnInit(): void 
  { 
    this.dto.isNotiSong = this.storage.retrieve('isNotiSong');
    if(this.dto.isNotiSong =="login")
    {
      this.getNoti();     
    }
    else
    {
       this.getNotiWithountSong();
    }  
    //this.getNoti(); 
    this.getPersentage();   
    this.subscription = interval(300000).subscribe(() => this.getNotiWithSecond()); // Calls getNoti every 5 minutes
   
    this.dashboard_bool = JSON.parse(localStorage.getItem("dashboard_bool"));
    this.adminNmae = this.storage.retrieve('adminname');
    // if (localStorage.getItem("menuItems") != null && localStorage.getItem("menuItems") != "undefined") 
    // {
    //   this.menuItems = JSON.parse(localStorage.getItem("menuItems"));
    //   this.displayUI();
    // }
    // else {
    //   this.getRolesModuleListByAdmin();
    // }

    //amk for dorpdown menu
    if(localStorage.getItem("menuItems") != null && localStorage.getItem("menuItems") != "undefined" )
    {
      this.menuItems =JSON.parse(localStorage.getItem("menuItems"));
      const storedState = localStorage.getItem('menuState');
      if (storedState) {
        const menuState = JSON.parse(storedState);
        this.menuItems.forEach((item) => {
          item.isOpen = menuState[item.title] || false;
          item.childrens.forEach((subItem) => {
            subItem.isOpen = menuState[subItem.title] || false;
          });
        });
      }
    }
    else
    {
    this.getRolesModuleListByAdmin();
    }


    
   
    //Test
    this.accordionItems = [
      { title: 'Web Design', icon: 'fa fa-database', isOpen: false, submenuItems: ['Photoshop', 'HTML', 'CSS'] },
      { title: 'Coding', icon: 'fa fa-code', isOpen: false, submenuItems: ['Javascript', 'jQuery', 'Ruby'] },
      { title: 'Devices', icon: 'fa fa-mobile', isOpen: false, submenuItems: ['Tablet', 'Mobile', 'Desktop'] },
      { title: 'Global', icon: 'fa fa-globe', isOpen: false, submenuItems: ['Google', 'Bing', 'Yahoo'] },
    ];
    
  }

  displayUI() {
    this.menuItems.forEach(menuItem =>
   {
      menuItem.show = true;
      for(var i = 0; i<menuItem.childrens.length; i++)
       {
        var child = menuItem.childrens[i];
        if(child.type == 2 ) 
        {
          menuItem.show = false;
        }        
        else 
        {
          menuItem.show = true;
          continue;
        }
       
      }
    });
  }

  logOut() {
    this.util.isLoggedIn = false;
    this.dto.token = "";
    this.storage.store('token', this.dto.token);
    this.storage.store('isOwnerLoggedIn', this.util.isLoggedIn);
    localStorage.setItem("fromDate", null);
    localStorage.setItem("toDate", null);
    localStorage.setItem("status", null);
    localStorage.setItem("option", null);
    localStorage.setItem("foundCodes", null);
    localStorage.setItem("localPhone", null);
    localStorage.clear();
    window.location.href = "/ad-login";
  }
  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {

    }
    if (error.status == 403) {

    }
    return throwError(error);
  }
  getMenuCodes() {
    this.menuList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    this.http.get(this.funct.ipaddress + 'admin/getmenucode', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.menuList = this.dto.Response;
        }
      );
  }
  getDataByCode(codeArray: string[]): any[] {
    const result: { title: string, code: string }[] = [];
    this.findDataByCode(this.menuItems, codeArray, result);
    return result;
  }
  private findDataByCode(dataArray: any[], codeArray: string[], result: any[]): void {
    for (const item of dataArray) {
      if (codeArray.includes(item.code) && item.isHaved) {
        result.push({ title: item.title, code: item.code });
      }
      if (item.childrens && item.childrens.length > 0) {
        this.findDataByCode(item.childrens, codeArray, result);
      }
    }
  }
  getRolesModuleList() {
    this.roleList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('roleId', localStorage.getItem("roleId"));
    const paramsObject = {};
    params.keys().forEach(key => {
      if (key === 'roleId') {
        paramsObject[key] = params.get(key);
      } else {
        paramsObject[key] = params.getAll(key);
      }
    });
    this.http.get(this.funct.ipaddress + 'admin/getrolemenu_byadmin', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.roleList = this.dto.Response;
          localStorage.setItem("roleList", this.roleList);
          this.storage.store('role_list', this.dto.role_list);
        }
      );
  }
  getRolesModuleListByAdmin()
   {
    //this.spinner.show();
    this.permissionList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('roleId', localStorage.getItem("roleId"));
    const paramsObject = {};
    params.keys().forEach(key => {
      if (key === 'roleId') {
        paramsObject[key] = params.get(key);
      } else {
        paramsObject[key] = params.getAll(key);
      }
    });
    this.http.get(this.funct.ipaddress + 'admin/getrolemenu_byadmin', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.menuItems = this.dto.Response;
          localStorage.setItem("menuItems", JSON.stringify(this.menuItems));
          // this.spinner.hide();
          this.displayUI();
           
        }
      );
  }
  isParentActive(item: any): boolean {
    return this.activeItem === item || (item.childrens && item.childrens.some(child => child === this.activeItem));
  }
  toggleItem(item: any): void {
    this.activeItem = (this.activeItem === item) ? null : item;
  }
  onSearchInput() {
    this.searchResults = this.searchResults.filter(item => item.toLowerCase().includes(this.searchString.toLowerCase()));
  }
  searchMenu() {
    this.menuList = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set("title", this.searchString)
    const paramsObject = {};
    params.keys().forEach(key => {
      if (key === 'title') {
        paramsObject[key] = params.get(key);
      } else {
        paramsObject[key] = params.getAll(key);
      }
    });
    this.http.get(this.funct.ipaddress + 'admin/searchmenu_bytitle', { params: params, headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.searchResults = this.dto.Response;
        }
      );
  }
  selectResult(result: string) {
    this.searchString = result;
    this.searchResults = [];
  }
  selectParentMenu(code) {

  }
  toggleMainMenu(index: number): void {
    if (this.openedMainMenu === index) {
      this.openedMainMenu = null;
    } else {
      this.openedMainMenu = index;
      this.selectedChildMenu = null;
    }
  }
  toggleChildMenu(parentIndex: number, childIndex: number): void {
    const menuKey = `${parentIndex}-${childIndex}`;
    if (this.selectedChildMenu === menuKey) {
      this.selectedChildMenu = null;
    } else {
      this.selectedChildMenu = menuKey;
    }
  }
  isMainMenuOpen(index: number): boolean {
    return this.openedMainMenu === index;
  }
  isChildMenuOpen(parentIndex: number, childIndex: number): boolean {
    return this.selectedChildMenu === `${parentIndex}-${childIndex}`;
  }
  //
  // toggleAccordion(item: any, type: any)
  // {
  //   this.menuItems.forEach((accordionItem) => 
  //   {
  //     if (accordionItem !== item && type != "subItem" && accordionItem.childrens.length > 0) 
  //     {
  //       accordionItem.isOpen = false;
  //     }
  //   });
  //   item.isOpen = !item.isOpen;
  // }

  //amk for dropdown menu
  toggleAccordion(item: any, type: any) {
    if (type === 'item') {
      this.menuItems.forEach((menuItem) => {
        if (menuItem !== item) {
          menuItem.isOpen = false; // Close the other menu item
        }
      });

      item.isOpen = !item.isOpen; // Toggle the clicked menu item
      this.updateLocalStorage(); // Update localStorage
    }
    if (type === 'subItem') {
      item.isOpen = !item.isOpen; // Toggle the clicked submenu item
      this.updateLocalStorage(); // Update localStorage
    }
  } 
  updateLocalStorage() {
    const menuState = this.menuItems.reduce((acc, item) => {
      acc[item.title] = item.isOpen;
      return acc;
    }, {});
    localStorage.setItem('menuState', JSON.stringify(menuState));
  } 
//

  getNoti() 
  {
    this.notidata = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const token = this.storage.retrieve('token');
    if (!token) {
      console.error('Token is missing. Cannot make the request.');
      return;
    }
    this.http.post(this.funct.ipaddress + 'AdminNotification/GetAll', {},{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => 
        {
          this.dto.Response = {};
          this.dto.Response = result;
          this.notidata = this.dto.Response;
          if(this.notidata.length == 0 )
          {
            this.noti_total =0;
          }
          else 
          {
            this.noti_total = this.notidata.count ;
          }
          this.notiCount_total=0;
          this.unreadCount = 0;      
          this.notidata.forEach(noti => 
          {
            if (noti.readed === false) 
            {
              this.unreadCount++;
            }           
            this.notiCount_total++;
            if(noti.isMuted == true)
            {            
              document.getElementById('speakerIcon').style.display = 'none';
              document.getElementById('muteIcon').style.display = 'inline';
              this.speakerMute = true;
            }
            else
            {
              document.getElementById('muteIcon').style.display = 'none';
              document.getElementById('speakerIcon').style.display = 'inline';
              this.speakerMute = false;
            
            }
          });    
          this.storage.store('notiCount', this.notiCount_total);
          if(this.speakerMute == false)
          {
            this.playNotificationSound();
          }
          
        }
      );

     
  }

  getNotiWithountSong() 
  {
    this.notidata = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const token = this.storage.retrieve('token');
    if (!token) {
      console.error('Token is missing. Cannot make the request.');
      return;
    }
    this.http.post(this.funct.ipaddress + 'AdminNotification/GetAll', {},{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => 
        {
          this.dto.Response = {};
          this.dto.Response = result;
          this.notidata = this.dto.Response;
          if(this.notidata.length == 0 )
          {
            this.noti_total =0;
          }
          else 
          {
            this.noti_total = this.notidata.count ;
          }
          this.notiCount_total=0;
          this.unreadCount = 0;      
          this.notidata.forEach(noti => 
          {
              if (noti.readed === false) 
              {
                this.unreadCount++;
              }
              this.notiCount_total++;

              if(noti.isMuted == true)
              {            
                document.getElementById('speakerIcon').style.display = 'none';
                document.getElementById('muteIcon').style.display = 'inline';
                this.speakerMute = true;
              }
              else
              {
                document.getElementById('muteIcon').style.display = 'none';
                document.getElementById('speakerIcon').style.display = 'inline';
                this.speakerMute = false
              
              }
             
          });  
          this.storage.store('notiCount', this.notiCount_total);        
           
          }
      );

     
  }

  getNotiWithSecond() 
  {
    this.notidata = [];
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const token = this.storage.retrieve('token');
    if (!token) {
      console.error('Token is missing. Cannot make the request.');
      return;
    }
    this.http.post(this.funct.ipaddress + 'AdminNotification/GetAll', {},{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => 
        {
          this.dto.Response = {};
          this.dto.Response = result;
          this.notidata = this.dto.Response;
          if(this.notidata.length == 0 )
          {
            this.noti_total =0;
          }
          else 
          {
            this.noti_total = this.notidata.count ;
          }
        
          this.notiCount_total =0;
          this.unreadCount = 0;      
          this.notidata.forEach(noti => 
          {
            if (noti.readed === false) 
            {
              this.unreadCount++;
            }
            else{
              
            }
            this.notiCount_total++;
          });      
          this.dto.notiCount = this.storage.retrieve('notiCount');  
        
          if(this.notiCount_total > this.dto.notiCount)
          {
             if(this.speakerMute == false)
             {
              this.playNotificationSound();
             }
          }    
         
        }
      );
     
  }

  getTimeAgo(date: string): string 
  {
    const parsedDate = parseISO(date);
    return formatDistanceToNow(parsedDate, { addSuffix: true });
  }

  toggleDropdown() 
  {    
    this.isDropdownVisible = !this.isDropdownVisible;
    this.speakerfornotipoint ="";
    this.hasClickedNotification = true;
     
  }  

  handleNotificationClick(id) 
  { 
    this.noti_id = id;
    this.readNoti(); 
      
  }


setValues()
{

    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const formData = new FormData();
    formData.append("code", ""); 
    formData.append("value", ""); 
    this.http.post(this.funct.ipaddress + 'SysParam/setvalue', formData, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response.status == 'Success')
        {      
            this.router.navigate(['/topup-check-list']).then(() => {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            })
        }
        else{      
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
}


readNoti()
{
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const formData = new FormData();
    formData.append("notificationId",this.noti_id);   
    this.http.post(this.funct.ipaddress + 'AdminNotification/read', formData, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;        
        if(this.hardcode_acc_holder_bool === true)
        {
          if(this.dto.Response.status == true)
          {       
              this.router.navigate(['/payment-info'], 
              {
              })
          }
          else
          {
            this.router.navigate(['/payment-info']).then(() => 
            {
            
            })
          
          }
        } 
        else{
          this.toastr.error("You Does Not Permission .", 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            });
        }      
      });
}


  getPersentage() 
  {  
   
    this.dto.token = this.storage.retrieve('token');
    if (!this.dto.token) 
    {
      console.error('Token is missing. Cannot make the request.');
      return;
    }  
    let headers = new HttpHeaders().set('Authorization', `Bearer ${this.dto.token}`);
    let params = new HttpParams().set('code', 'bank_amount_reminder_percentage');

    this.http.get(this.funct.ipaddress + 'SysParam/getvalue', { params: params, headers: headers }) 
      .pipe(
        catchError((error) => 
        {
          console.error('Request failed with error:', error);
          return this.handleError(error); 
        })
      )
      .subscribe(
        result => 
        {        
          this.dto.Response = result;
          this.dto.persentage = result;
          this.storage.store('persentage', this.dto.persentage);
        
        }
        
      );
}


playNotificationSound() 
{
  if (this.unreadCount > 0) 
  {
    try 
    {
      if (this.notificationSound?.nativeElement) 
          {
            this.notificationSound.nativeElement.play().catch((error) => 
            {
              console.error('Audio playback failed:', error);
            });
          }
    } 
    catch (error) 
    {
      console.error('Audio playback failed:', error);
    }
  }
}

 toggleAudio() 
 {
  var speakerIcon = document.getElementById("speakerIcon");
  var muteIcon = document.getElementById("muteIcon");
  if (speakerIcon.style.display === "none")
  {
     // Unmute logic can go here
    speakerIcon.style.display = "inline";
    muteIcon.style.display = "none";   
 
    this.isMutedCondition = false;
  }
  else 
  {
     // Mute logic can go here
    speakerIcon.style.display = "none";
    muteIcon.style.display = "inline"; 

    this.isMutedCondition = true;
  }
  this.updateMyMuted();  
}


updateMyMuted()
{
   
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    const formData = new FormData();
    formData.append("isMuted",this.isMutedCondition);   
    this.http.post(this.funct.ipaddress + 'AdminNotification/updateMyMuted', formData, {headers: headers})
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;          
      });
}

  



}

