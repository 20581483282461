<link rel="stylesheet" href='https://mmwebfonts.comquas.com/fonts/?font=myanmar3' />
<link rel="stylesheet" href='https://mmwebfonts.comquas.com/fonts/?font=zawgyi' />	



<section class="content">
  <div class="container-fluid">
   
    <div class="card">
      <div class="card-header">
          <h3 class="card-title" style="font-weight: bold;">  Notification Detail</h3>
      </div>
      <div class="card-body">
    <form>
      <div class="row">
        <div class="form-group col-md-2">
            <label>Phone No </label>
        </div>
        <div class="form-group col-md-2">
          <input type = "text" class="form-control form-control-sm" style="width:250px;" [(ngModel)]="phone_no" name = "title" class="form-control form-control-sm" placeholder="09*********">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-2">
            <label> </label>
        </div>
        <div class="form-group col-md-2">
          <label >(OR)</label>
         
        </div>
      </div>


      <div class="row">
        <div class="form-group col-md-2">
            <label>From Date </label>
        </div>
        <div class="form-group col-md-2">
          <input id="approveFromDate" style="width:250px;" readonly class="form-control form-control-sm" placeholder="yyyy-mm-dd" 
          [ngxDatePicker]="dateInstanceApprove"  [(ngModel)]="approvefromdate"
          [ngModelOptions]="{standalone: true}"  (ngModelChange)="onChangeApproveFromDate()">
        <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
        </div>
    </div>


    
    <div class="row">
      <div class="form-group col-md-2">
          <label>To Date </label>
      </div>
      <div class="form-group col-md-2">
        <input id="approveToDate" style="width:250px;" readonly class="form-control form-control-sm" placeholder="yyyy-mm-dd" 
                          [ngxDatePicker]="dateInstanceApprove1"  [(ngModel)]="approvetodate"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeApproveToDate()">
                        <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
      </div>
    </div>


        <div class="row">
          <div class="form-group col-md-2">
              <label>Caption </label>
          </div>
          <div class="form-group col-md-2">
            <input type = "text" class="form-control form-control-sm" style="width:250px;" [(ngModel)]="caption" name = "title" class="form-control form-control-sm" id = "fdate">
          </div>
      </div>

      


    <div class="row">
      <div class="form-group col-md-2">
          <label>Action Link </label>
      </div>
      <div class="form-group col-md-2">
        <input type = "text"class="form-control form-control-sm" style="width:250px;" [(ngModel)]="action_link" name = "title" class="form-control form-control-sm" id = "fdate">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-2">
          <label> Content</label>
      </div>
      <div class="form-group col-md-2">
        <input type = "text" maxlength="100" style="width:250px;height : 50px;font-family:Myanmar3,Yunghkio,'Masterpiece Uni Sans'"  [(ngModel)]="content" name = "title" class="form-control form-control-sm" id = "fdate">
      </div>
    </div>

    


<!-- 
    <div class="row">
      <div class="form-group col-md-2">
        <label> Image Link </label>
      </div>
      <div class="form-group col-md-6">
        <div class="form-group col-md-2">
          <div class="form-group">
            <input #file type="file" accept='image/*'
                (change)="preview(file.files)" />               
                <img [src]="imgURL" height="200" width="250" *ngIf="imgURL" 
                style="margin-top: 5%;">
          </div>
          <div class="form-group col-md-1" style = "margin-right: 10px;" >
            <button (click)="deleteImage()">
                <span title = "Remove this image" style = "color : red;cursor: pointer;"  class="fas fa-trash-alt"  ></span>
              </button>               
          </div>
        </div>
      </div>
    </div> -->





     <div class="row">
      <label class="col-md-2">  Image Link </label>
      <div class="form-group col-md-2">
          <input #file type="file" accept='image/*'
              (change)="preview(file.files)" />              
              <img [src]="imgURL" height="200" width="255" *ngIf="imgURL"
              style="margin-top: 5%;">
      </div>

      <div class="form-group col-md-2">
      <button (click)="deleteImage()">
          <span title = "Remove this image" style = "color : red;cursor: pointer;"  class="fas fa-trash-alt"  ></span>
        </button>
      </div>

  </div>



        <div class="row" *ngIf="notiId==null">
          <div class="col-md-2 col-sm-3" *ngIf="hardcode_add_bool == true">
            <button type="button" class="btn btn-block btn-success"
            (click)="goSave()">Send</button>&nbsp;
          </div>
          <div class="col-md-2 col-sm-3" *ngIf="hardcode_add_bool == false">
            
          </div>
          <div class="col-md-2 col-sm-3">
              <button type="button" class="btn btn-block btn-danger"
              [routerLink]="['/vibernotification/list']"> Cancel </button>
          </div>
        </div>

        
    </form>
    </div>
    </div>
  </div>
</section>


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>

