import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-sms-history',
  templateUrl: './sms-history.component.html',
  styleUrls: ['./sms-history.component.css']
})
export class SMSHistoryComponent implements OnInit {
 constructor() 
 { 
  }

  ngOnInit(): void {
  }


}
