import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd ,ActivatedRoute} from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Subject } from 'rxjs';
declare var $: any;

//amk

import { DatePipe } from '@angular/common'
import Responsive from 'datatables.net-responsive';


@Component({
  selector: 'app-servicephone',
  templateUrl: './servicephone.component.html',
  styleUrls: ['./servicephone.component.css']
})
export class ServicephoneComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  phone: string = '';
  type: string = '';
  status: string = '';
  servicephoneList: any;
  idIndex: any;

  phoneNo: string = '';
  name: string = '';
  referralCode: string = '';
  token: any;
  serviceId: any;  

    hardcode_edit :any;
    hardcode_add :any;
    hardcode_view: any;
    hardcode_delete:any;
    hardcode_view_bool : any;
    hardcode_add_bool : any;
    hardcode_edit_bool : any;
    hardcode_delete_bool:any;
    hardcode_double_bool:any;
    id_servicephone:any;
  

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private http: HttpClient, private dto: DtoService,
    private router: Router, private funct: FunctService, private toastr: ToastrService,) {
      this.storage.store('isNotiSong', "");
    this.idIndex = 1;

      this.hardcode_view = "ServicePhone_View";
      this.hardcode_edit = "ServicePhone_Edit";
      this.hardcode_add = "ServicePhone_Add";
      this.hardcode_delete="ServicePhone_Del"; 
      
      this.dto.menuCodes = this.storage.retrieve('menucodes');
      const filteredMenuCodes_view = [];
      const filteredMenuCodeds_add =[];
      const filteredMenuCodeds_edit =[];
      const filteredMenuCodes_delete =[];
      this.dto.menuCodes.forEach(menuCode => {
        if (menuCode === this.hardcode_view) {
          filteredMenuCodes_view.push(menuCode);
        }
        if (menuCode === this.hardcode_add) {
          filteredMenuCodeds_add.push(menuCode);
        }
        if (menuCode === this.hardcode_edit) {
          filteredMenuCodeds_edit.push(menuCode);
        }
        if (menuCode === this.hardcode_delete) {
          filteredMenuCodes_delete.push(menuCode);
        }
        
      });
      if(filteredMenuCodes_view.length != 0)
      {
            this.hardcode_view_bool = true;
      }
      else
      {
        this.hardcode_view_bool = false;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
            this.hardcode_add_bool = true;
      }
      else
      {
        this.hardcode_add_bool = false;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
            this.hardcode_edit_bool = true;
      }
      else
      {
        this.hardcode_edit_bool = false;
      }
      if(filteredMenuCodes_delete.length != 0)
      {
            this.hardcode_delete_bool = true;
      }
      else
      {
        this.hardcode_delete_bool = false;
      }
      if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)
      {
           this.hardcode_double_bool = false ;           
      }
      else
      {
          this.hardcode_double_bool = true;
      }
      
    // this.search();
  }

  ngOnInit(): void 
  {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      responsive: true,     
    }
    this.dtOptions1 = {
      responsive: true,     
    }

    this.dtOptions.columnDefs = [
      //amk added
      { targets: [5], orderable: false }
    ];

    this.dtOptions1.columnDefs = [
      //amk added
      { targets: [5], orderable: false }
    ];


    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    this.search();

  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id = 'tblServicePhone' + this.idIndex;
      var table = $('#' + id).DataTable();
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

  search() {
    this.servicephoneList = [];
    var id = 'tblServicePhone' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('phone', this.phone).set('type', this.type).set('status', this.status);
    this.http.get(this.funct.ipaddress + 'service/listServiceByParams', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response));
        this.servicephoneList = this.dto.Response;
        this.dtTrigger1.next();
        this.spinner.hide();
      }
    );
  }


  deleteservice(id)
  {
    $("#deleteDataForService").modal("show");
    this.id_servicephone = id;       
  } 

  deleteServicePhoneById() 
  {
    this.serviceId = this.id_servicephone;  
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.token);
    let params = new HttpParams();
    params = params.set('id', this.serviceId);
    this.http.get(this.funct.ipaddress + 'service/delete', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') 
        {
          this.spinner.hide();
          this.search();
          this.toastr.success(this.dto.Response.message, 'Success!', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          })
        }
        else {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      });
      $('#deleteDataForService').modal("hide");
  }

  closeTran()
  {
      $('#deleteDataForService').modal("hide");
      this.router.navigate(['/servicephone-list']).then(() => 
      {
      // this.toastr.error("Service Phone ", 'Cancel!', {
      //   timeOut: 3000,
      //   positionClass: 'toast-top-right',
      // });
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

}
