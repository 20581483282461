import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';

import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from 'ngx-toastr';


import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DtoService } from '../../service/dto.service';
import { FunctService } from '../../service/funct.service';

import { Subject } from 'rxjs';
import Responsive from 'datatables.net-responsive';
declare var $: any;

@Component({
  selector: 'app-withdraw-check',
  templateUrl: './withdraw-check.component.html',
  styleUrls: ['./withdraw-check.component.css']
})
export class WithdrawCheckComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtElement1: DataTableDirective;
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();

  dtElement2: DataTableDirective;
  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();

  dtElement3: DataTableDirective;
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();

  dtElement4: DataTableDirective;
  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();

  status: string = '';

  singleDate: string = '';
  pendingDate: string = '';
  checkedDate: string = '';
  approveDate: string = '';
  deniedDate: string = '';
  orderby:string='';
  ordermethod:string='';
  orderbypending:string='';
  ordermethodpending:string='';
  orderbychecking:string='';
  ordermethodchecking:string='';
  orderbyapproved:string='';
  ordermethodapproved:string='';
  orderbydenied:string='';
  ordermethoddenied:string='';

  accountNo: string ;
  phone_no:any;
  pendingaccountNo: string ;
  checkedaccountNo: string ;
  approveaccountNo: string ;
  deniedaccountNo: string  ;

  withdrawList: any;
  withdrawPendingList: any;
  withdrawCheckedList: any;
  withdrawApproveList: any;
  withdrawDeniedList: any;

  idIndex: any;
  idpendingIndex: any;
  idcheckedIndex: any;
  idapproveIndex: any;
  iddeniedIndex: any;

  date: any;
  pendingdate: any;
  checkeddate: any;
  approvedate: any;
  denieddate: any;
  bankTypeList:any;
  BankType:any;
  
  
  //add the following vars
  alltoDate: string = ''; 
  alltodate : any;
  alltodaytodate : any;
  alltodaytodatechangeDate : any;
  alltodatechangeDate : any; 
  pendingtodate : any;
  pendingtoDate : any;
  pendingtodaytodate : any;
  pendingtodatechangeDate : any;
  addedtoDate : any;
  addedtodate : any;
  addedtodaytodate : any;
  addedtodatechangeDate : any;
  approvetodate :any;
  approvetoDate : any;
  approvetodaytodate : any;
  approvetodatechangeDate : any;
  deniedtodate : any;
  deniedtoDate : any;
  deniedtodaytodate : any;
  deniedtodatechangeDate : any;

  allchangeDate: any;
  pendingtodayDate: any;
  checkedtodayDate: any;
  approvetodayDate: any;
  deniedchangeDate: any;

  alltodayDate: any;
  pendingchangeDate: any;
  checkedchangeDate: any;
  approvechangeDate: any;
  deniedtodayDate: any;

  hardcode_view: any;
  hardcode_edit :any;
  hardcode_approved :any;
  hardcode_denied :any;


  hardcode_view_bool : any; 
  hardcode_edit_bool : any;
  hardcode_approved_bool :any;
  hardcode_denied_bool :any;

  phone_no_pending:any;
  phone_no_checked:any;
  phone_no_approved:any;
  phone_no_denied:any;
  user_name:string='';
  user_name_pending:string='';
  user_name_denied:string='';
  user_name_approved:string='';
  user_name_checked:string='';
 
  page = 1;
  totalItems : any; 
  itemsPerPage =  10;
  approvedloading :any;
  BankTypePending:any;
  BankTypeCheck:any;
  BankTypeApproved:any;
  BankTypeDenied:any;
  queryParam: string = '';
  from_amount :any;
  to_amount : any;
  from_amount_pending :any;
  to_amount_pending : any;
  from_amount_checked :any;
  to_amount_checked : any;
  from_amount_approved :any;
  to_amount_approved : any;
  from_amount_denied :any;
  to_amount_denied : any;
  type :any;

  constructor( private toastr: ToastrService, private storage: LocalStorageService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private router: Router,
    private route: ActivatedRoute, private funct: FunctService, private datepipe: DatePipe) 
    {

    this.idIndex = 1;
    this.storage.store('isNotiSong', "");
    this.idpendingIndex = 1;
    this.idcheckedIndex = 1;
    this.idapproveIndex = 1;
    this.iddeniedIndex = 1;  

    this.hardcode_view = "Withdrawal_View";
    this.hardcode_edit = "Withdrawal_Edit";
    this.hardcode_approved ="Withdrawal_Approved";
    this.hardcode_denied ="Withdrawal_Denied";
    this.dto.menuCodes = this.storage.retrieve('menucodes');
    const filteredMenuCodes_view = [];    
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_approved =[];
    const filteredMenuCodeds_denied =[];
    this.dto.menuCodes.forEach(menuCode => 
      {
      if (menuCode === this.hardcode_view) 
      {
        filteredMenuCodes_view.push(menuCode);
      }     
      if (menuCode === this.hardcode_edit) 
      {
        filteredMenuCodeds_edit.push(menuCode);
      }
      if (menuCode === this.hardcode_approved) 
      {
        filteredMenuCodeds_approved.push(menuCode);
      }
      if (menuCode === this.hardcode_denied) 
      {
        filteredMenuCodeds_denied.push(menuCode);
      }
      
    });
        if(filteredMenuCodes_view.length != 0)
        {
              this.hardcode_view_bool = true;
        } 
        else
        {
          this.hardcode_view_bool = false;
        }      
        if(filteredMenuCodeds_edit.length != 0)
        {
              this.hardcode_edit_bool = true;
        }  
        else
        {
          this.hardcode_edit_bool = false;
        }
        if(filteredMenuCodeds_approved.length != 0)
        {
              this.hardcode_approved_bool = true;
        }  
        else
        {
          this.hardcode_approved_bool = false;
        }
        if(filteredMenuCodeds_denied.length != 0)
        {
              this.hardcode_denied_bool = true;
        }  
        else
        {
          this.hardcode_denied_bool = false;
        }
      
    if (!this.storage.retrieve('ALLTagFromDate')) {
      this.date = new Date();
      this.alltodayDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
    }
    else {
      this.date = new Date(this.storage.retrieve('ALLTagFromDate'));
      this.alltodayDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
    }
    
    //commnet , open today
    // if (!this.storage.retrieve('PendingTagFromDate')) {
    //   this.pendingdate = new Date();
    //   this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
    // }
    // else {
    //   this.pendingdate = new Date(this.storage.retrieve('PendingTagFromDate'));
    //   this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
    // }  
   
    // if (!this.storage.retrieve('CheckedTagFromDate')) {
    //   this.checkeddate = new Date();
    //   this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');
    // }
    // else {
    //   this.checkeddate = new Date(this.storage.retrieve('CheckedTagFromDate'));
    //   this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');
    // }  


    // if (!this.storage.retrieve('ApprovedTagFromDate')) {
    //   this.approvedate = new Date();
    //   this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    // }
    // else {
    //   this.approvedate = new Date(this.storage.retrieve('ApprovedTagFromDate'));
    //   this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    // } 

    // if (!this.storage.retrieve('DeniedTagFromDate')) {
    //   this.denieddate = new Date();
    //   this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
    // }
    // else {
    //   this.denieddate = new Date(this.storage.retrieve('DeniedTagFromDate'));
    //   this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
    // }  

    //

    //add the follwoing 
    if (!this.storage.retrieve('ALLTagToDate')) {
      this.alltodate = new Date();
      this.alltodaytodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    }
    else {
      
      this.alltodate = new Date(this.storage.retrieve('ALLTagToDate'));
      this.alltodaytodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('PendingTagToDate')) {
      this.pendingtodate = new Date(); 
      this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
    }
    else {
      // this.pendingtodate = new Date(this.storage.retrieve('PendingTagToDate'));
      // this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
      this.pendingtodate = new Date();
      this.pendingtodaytodate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('CheckedTagToDate')) 
    {
      this.addedtodate = new Date();  //use for check tab
      this.addedtodaytodate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy');
    }
    else {
      // this.addedtodate = new Date(this.storage.retrieve('CheckedTagToDate'));
      // this.addedtodaytodate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy');

      this.addedtodate = new Date();
      this.addedtodaytodate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('ApprovedTagToDate')) 
    {
      this.approvetodate = new Date();  //add this
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    }
    else {
      // this.approvetodate = new Date(this.storage.retrieve('ApprovedTagToDate'));
      // this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');

      this.approvetodate = new Date();
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    } 

    if (!this.storage.retrieve('DeniedTagToDate')) {
      this.deniedtodate = new Date();  //add this
      this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
    }
    else {
      // this.deniedtodate = new Date(this.storage.retrieve('DeniedTagToDate'));
      // this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');

      this.deniedtodate = new Date();
      this.deniedtodaytodate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy');
    }   


    //amk added for refresh 
    
    
    this.pendingdate = new Date();
    this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');

    this.checkeddate = new Date();
      this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');

    this.approvedate = new Date();
    this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');

    this.denieddate = new Date();
    this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');


    //
    
    this.BankType ="";
    this.BankTypePending ="";
    this.BankTypeApproved ="";
    this.BankTypeCheck="";
    this.BankTypeDenied="";
    this.getAssignedPaymentsForWithdrawal();
  }

  ngOnInit(): void 
  {

    
    this.route.queryParams.subscribe(queryParams => {
      this.type = queryParams['type'];
    });

    if(this.type == "withdrawalalllistcancel")
      {  
          
            if (!this.storage.retrieve('ALLTagFromDate')) 
            {
              this.date = new Date();
              this.allchangeDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
            }
            else 
            {
              this.alltodate = new Date(this.storage.retrieve('ALLTagFromDate'));
              this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
              this.singleDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
              this.date = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
            }

            if (!this.storage.retrieve('ALLTagFromDate')) {
              this.date = new Date();
              this.alltodaytodate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
            } else {
              this.date = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
              this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
            }
             

            if (!this.storage.retrieve('PendingTagFromDate')) 
            {
              this.pendingdate = new Date();
              this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
            }
            else {
              this.pendingdate = new Date(this.storage.retrieve('PendingTagFromDate'));
              this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
            } 
            if (!this.storage.retrieve('CheckedTagFromDate')) 
            {
              this.checkeddate = new Date();
              this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');
            }
            else {
              this.checkeddate = new Date(this.storage.retrieve('CheckedTagFromDate'));
              this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');
            }         
        
            if (!this.storage.retrieve('ApprovedTagFromDate')) 
            {
              this.approvedate = new Date();
              this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
            }
            else {
              this.approvedate = new Date(this.storage.retrieve('ApprovedTagFromDate'));
              this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
            } 
        
            if (!this.storage.retrieve('DeniedTagFromDate')) 
            {
              this.denieddate = new Date();
              this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
            }
            else {
              this.denieddate = new Date(this.storage.retrieve('DeniedTagFromDate'));
              this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
            }  
      }


    // this.type = this.route.snapshot.paramMap.get("id");       
    // if(this.type =="detail")
    // {
    //   if (!this.storage.retrieve('ALLTagFromDate')) 
    //   {
    //     this.date = new Date();
    //     this.alltodayDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
    //   }
    //   else {
    //     this.date = new Date(this.storage.retrieve('ALLTagFromDate'));
    //     this.alltodayDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
    //   }
    // }



    // if(this.queryParam == "pending")
    // {
    //     if (!this.storage.retrieve('PendingTagFromDate')) 
    //     {
    //     this.pendingdate = new Date();
    //     this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
    //     }
    //     else 
    //     {
    //       this.pendingdate = new Date(this.storage.retrieve('PendingTagFromDate'));
    //       this.pendingtodayDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');
    //     }  

    //     if (!this.storage.retrieve('CheckedTagFromDate')) 
    //     {
    //       this.checkeddate = new Date();
    //       this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');
    //     }
    //     else {
    //       this.checkeddate = new Date(this.storage.retrieve('CheckedTagFromDate'));
    //       this.checkedtodayDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy');
    //     } 
    //     if (!this.storage.retrieve('ApprovedTagFromDate')) 
    //     {
    //       this.approvedate = new Date();
    //       this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    //     }
    //     else 
    //     {
    //       this.approvedate = new Date(this.storage.retrieve('ApprovedTagFromDate'));
    //       this.approvetodayDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy');
    //     }  
    //     if (!this.storage.retrieve('DeniedTagFromDate')) 
    //     {
    //       this.denieddate = new Date();
    //       this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
    //     }
    //     else {
    //       this.denieddate = new Date(this.storage.retrieve('DeniedTagFromDate'));
    //       this.deniedtodayDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy');
    //     }  
    // }

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
     
        this.router.navigated = false;

        window.scrollTo(0, 0);
      }
    });

    this.dtOptions = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

   

    this.dtOptions.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions1 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions1.columnDefs = [
      { targets: [9], orderable: false }
    ];


    this.dtOptions2 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions2.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions3 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions3.columnDefs = [
      { targets: [9], orderable: false }
    ];

    this.dtOptions4 = {
      // responsive: true,
      responsive: {
        details: {
            renderer: Responsive.renderer.listHiddenNodes()
        }
    },
      order: [],
      paging:false
    }

    this.dtOptions4.columnDefs = [
      { targets: [9], orderable: false }
    ];

    if (!this.storage.retrieve('loadFlag')) {
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function () {
        location.reload();
      }, 5);
    }
    else {
      this.storage.clear('loadFlag');
    }

    if (!this.storage.retrieve('WithdrawalTag')) 
    {
      this.storage.store('WithdrawalTag', "ALL");      
      this.search('tabsearch');
      $('#all-tab').tab('show');
    }
    else {
      if(this.storage.retrieve('WithdrawalTag') == "ALL")
      {        
        this.search('tabsearch');
        $('#all-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "PENDING"){        
        this.pendingSearch('tabsearch');
        $('#pending-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "CHECKED"){        
        this.checkedSearch('tabsearch');
        $('#checked-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "APPROVE"){        
        this.approveSearch('tabsearch');
        $('#approve-tab').tab('show');
      }
      else if(this.storage.retrieve('WithdrawalTag') == "DENIED"){        
        this.deniedSearch('tabsearch');
        $('#denied-tab').tab('show');
      }
      else{
        this.search('tabsearch');
        $('#all-tab').tab('show');
      }
    }  
  }

  ngAfterViewInit(){
    console.log('ContactsComponent - ngAfterViewInit()');
    //this.dtTrigger.next();
    //this.dtTrigger1.next();
    //this.dtTrigger2.next();
   // this.dtTrigger3.next();
   // this.dtTrigger4.next();

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
            const that = this;
            $('input', this.footer()).on('keyup change', function () {
                console.log('search(): ' + that.search());
                console.log('value: ' + this['value']);
                if (that.search() !== this['value']) {
                    that.search(this['value'])
                        .draw();
                }
            });
        });
      });
  }

  onChangeSingle() {
    // $(document).ready(function () {
    //   this.singleDate = $("#singleDate").val();
    // });

    this.storage.store('ALLTagFromDate', this.date.toString());
  }

  onChangeAllToDate() {
    // $(document).ready(function () {
    //   this.alltoDate = $("#alltodate").val();
    // });

    this.storage.store('ALLTagToDate', this.alltodate.toString());
  }

  onChangePending() {
    
    this.storage.store('PendingTagFromDate', this.pendingdate.toString());
  }

  onChangePendingToDate()
  {
   
    this.storage.store('PendingTagToDate', this.pendingtodate.toString());
  }
  onChangeChecked(){
   
    this.storage.store('CheckedTagFromDate', this.checkeddate.toString());
  }
  onChangeAddedToDate()  //use as check
  {
  
    this.storage.store('CheckedTagToDate', this.addedtodate.toString());
  }

  onChangeApprove() {
    
    this.storage.store('ApprovedTagFromDate', this.approvedate.toString());
  }

  onChangeApproveToDate()
  {
    
    this.storage.store('ApprovedTagToDate', this.approvetodate.toString());
  }

  onChangeDenied() {
   
    this.storage.store('DeniedTagFromDate', this.denieddate.toString());
  }

  onChangeDeniedToDate()
  {
  
    this.storage.store('DeniedTagToDate', this.deniedtodate.toString());
  }

  handleError(error: HttpErrorResponse)
  {
    if(error.status == 423)
    {
      this.spinner.hide();
      $("#deleteData").modal("show");
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tblWithdraw' + this.idIndex;
      var table1 = $('#' + id1).DataTable();

      var id2 = 'tblpendingWithdraw' + this.idpendingIndex;
      var table2 = $('#' + id2).DataTable();

      var id3 = 'tblcheckedWithdraw' + this.idcheckedIndex;
      var table3 = $('#' + id3).DataTable();
    
      var id4 = 'tblapproveWithdraw' + this.idapproveIndex;
      var table4 = $('#' + id4).DataTable();

      var id5 = 'tbldeniedWithdraw' + this.iddeniedIndex;
      var table5 = $('#' + id5).DataTable();

      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    
  getBankTypeList() {
    this.bankTypeList = [];
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    this.http.get(this.funct.ipaddress + 'payment/paymentListwithdrawal', { headers: headers })
    .pipe(//GetBankTypeLists
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.bankTypeList = this.dto.Response;
        this.spinner.hide();
      });
  }

  
getAssignedPaymentsForWithdrawal()
 {
  this.bankTypeList = [];
  this.dto.token = this.storage.retrieve('token');
  let headers = new HttpHeaders();
  headers = headers.set('Authorization',  this.dto.token);
  let params = new HttpParams();
  params = params.set('type', 'WITHDRAWAL');
  this.http.get(this.funct.ipaddress + 'payment/withdrawallistPayment', { params: params, headers: headers })
  .pipe(
    catchError(this.handleError.bind(this))
   )
  .subscribe(
    result => {
      this.dto.Response = {};
      this.dto.Response = result;
      this.bankTypeList = this.dto.Response;
    }
  );
}
  
  onChangeTagSection() {     
    this.storage.store('BankType', this.BankType.toString());  
  }
  search(searchKey) 
  {
    this.storage.store('WithdrawalTag', "ALL");
    this.withdrawList = [];
    var id = 'tblWithdraw' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();   
    if(searchKey == "btnsearch")
    {
      if (this.singleDate == '' || this.singleDate == undefined) {
        this.allchangeDate = this.alltoDate;
      }
      else {
        this.allchangeDate = this.singleDate;
      }  
      if (this.alltoDate == '' || this.alltoDate == undefined) {
        this.alltodatechangeDate = this.alltodaytodatechangeDate;
      }
      else {      
        this.alltodatechangeDate = this.alltoDate;
      }
      if (this.accountNo == '' || this.accountNo == undefined) {
        this.accountNo = '';
      }
      else {
        this.accountNo = this.accountNo;
      } 

      if (this.phone_no == '' || this.phone_no == undefined) 
      {
        this.phone_no = '';
      }
      else {
        this.phone_no = this.phone_no;
      }
      if(this.from_amount == "" || this.from_amount == undefined || this.from_amount == null)
      {
         this.from_amount ="";
      }
      else 
      {
         this.from_amount = this.from_amount;
      }
      if(this.to_amount == "" || this.to_amount == undefined || this.to_amount == null)
      {
         this.to_amount ="";
      }
      else 
      {
         this.to_amount = this.to_amount;
      }
  
    }
    else
    {
      

      // if (!this.storage.retrieve('ALLTagFromDate')) 
      // {
      //   this.date = new Date();
      //   this.allchangeDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
      // }
      // else 
      // {
      //   this.alltodate = new Date(this.storage.retrieve('ALLTagFromDate'));
      //   this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
      //   this.singleDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
      //   this.date = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
      // }

      // if (!this.storage.retrieve('ALLTagFromDate')) {
      //   this.date = new Date();
      //   this.alltodaytodate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
      // } else {
      //   this.date = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
      //   this.alltodaytodate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
      // }

     //amk
     if(this.type == "withdrawalalllistcancel")
     {
            if (this.singleDate == '' || this.singleDate == undefined)
            {   
            this.date = new Date(this.storage.retrieve('ALLTagFromDate'));
            this.allchangeDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
      
          }
          else 
          {
            this.date = new Date(this.storage.retrieve('ALLTagFromDate'));
            this.allchangeDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');
          
          }
          if (this.alltodate == '' || this.alltodate == undefined) 
          {
            this.alltodate = new Date(this.storage.retrieve('ALLTagToDate'));
            this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');
          }
          else {
              this.alltodate = new Date();
              this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');  
          }

     }
     else
     {
          this.storage.store('ALLTagFromDate',"")  
          if (this.singleDate == '' || this.singleDate == undefined) {          
            this.date = new Date();
            this.allchangeDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');   
          }
          else {      
            this.date = new Date();
            this.allchangeDate = this.datepipe.transform(this.date, 'MMM dd, yyyy');       
          
          }
          if (this.alltoDate == '' || this.alltoDate == undefined) {     
            this.alltodate = new Date();
            this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');   
          }
          else {
            this.alltodate = new Date();
            this.alltodatechangeDate = this.datepipe.transform(this.alltodate, 'MMM dd, yyyy');    
          } 
     }

      if (this.accountNo == '' || this.accountNo == undefined) {
        this.accountNo = '';
      }
      else {
        this.accountNo = this.accountNo;
      }  
      if (this.phone_no == '' || this.phone_no == undefined) 
      {
        this.phone_no = '';
      }
      else {
        this.phone_no = this.phone_no;
      } 
      if(this.from_amount == "" || this.from_amount == undefined || this.from_amount == null)
      {
         this.from_amount ="";
      }
      else 
      {
         this.from_amount = this.from_amount;
      }
      if(this.to_amount == "" || this.to_amount == undefined || this.to_amount == null)
      {
         this.to_amount ="";
      }
      else 
      {
         this.to_amount = this.to_amount;
      }

    }
    if(this.BankType == undefined)
    {
       this.BankType ="";
    }
    
    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();  
    params = params.set('fromDate', this.allchangeDate).set('toDate',this.alltodatechangeDate).set('status', '').set('accountNo', this.accountNo).set('phone_no', this.phone_no).set("pageNumber","1").set('orderByField',this.orderby).set('orderMethod',this.ordermethod).set('payment_id',this.BankType).set('userName' , this.user_name).set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount).set('amountTo', this.to_amount);

    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })//transaction/withdraws-by-params
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawList = this.dto.Response;
        this.totalItems = result.totalRows;     
        this.dtTrigger.next();
        this.spinner.hide();
      });

  }


  gty(page: any)
  {
    this.storage.store('WithdrawalTag', "ALL");
    this.withdrawList = [];
    var id = 'tblWithdraw' + this.idIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idIndex = this.idIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.singleDate = $("#singleDate").val();
    this.alltoDate = $("#alltodate").val();     
    
 
      if (this.singleDate == '' || this.singleDate == undefined) {
        this.allchangeDate = this.alltoDate;
      }
      else {
        this.allchangeDate = this.singleDate;
      }  
      if (this.alltoDate == '' || this.alltoDate == undefined) {
        this.alltodatechangeDate = this.alltodaytodatechangeDate;
      }
      else {      
        this.alltodatechangeDate = this.alltoDate;
      }
      if (this.accountNo == '' || this.accountNo == undefined) {
        this.accountNo = '';
      }
      else {
        this.accountNo = this.accountNo;
      } 

      if (this.phone_no == '' || this.phone_no == undefined) 
      {
        this.phone_no = '';
      }
      else {
        this.phone_no = this.phone_no;
      }
      if(this.BankType == undefined)
      {
         this.BankType ="";
      }
      if(this.from_amount == "" || this.from_amount == undefined || this.from_amount == null)
      {
         this.from_amount ="";
      }
      else 
      {
         this.from_amount = this.from_amount;
      }
      if(this.to_amount == "" || this.to_amount == undefined || this.to_amount == null)
      {
         this.to_amount ="";
      }
      else 
      {
         this.to_amount = this.to_amount;
      }
      
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();  
    params = params.set('fromDate', this.allchangeDate).set('toDate',this.alltodatechangeDate).set('status', '').set('accountNo', this.accountNo).set('phone_no', this.phone_no).set("pageNumber","1").set('orderByField',this.orderby).set('orderMethod',this.ordermethod).set('payment_id',this.BankType).set('userName' , this.user_name).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount).set('amountTo', this.to_amount);
  
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawList = this.dto.Response;//.data.userFinancialTransactionDTOList; 
        this.totalItems = result.totalRows;        
        this.dtTrigger.next();
        this.spinner.hide();
      });
  }

  pendingSearch(searchKey) 
  {
    this.storage.store('WithdrawalTag', "PENDING");
    this.withdrawPendingList = [];
    var id = 'tblpendingWithdraw' + this.idpendingIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idpendingIndex = this.idpendingIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.pendingDate = $("#pendingDate").val();
    this.pendingtoDate = $("#pendingtodate").val();
    console.log("this.pendingDate" + this.pendingDate);
    if(searchKey == "btnsearch")
    {
        this.pendingDate = $("#pendingDate").val();
        this.pendingtoDate = $("#pendingtodate").val();
        if (this.pendingDate == '' || this.pendingDate == undefined) {
          this.pendingchangeDate = this.pendingtodayDate;
          console.log("this.pendingDate with pending todaydate" + this.pendingchangeDate);
        }
        else {      
          this.pendingchangeDate = this.pendingDate;    

        }
        if (this.pendingtoDate == '' || this.pendingtoDate == undefined) {
          this.pendingtodatechangeDate = this.pendingtodaytodate;
        }
        else {
          this.pendingtodatechangeDate = this.pendingtoDate;          
        } 
        if (this.pendingaccountNo == '' || this.pendingaccountNo == undefined) {
          this.pendingaccountNo = '';
        }
        else {
          this.pendingaccountNo = this.pendingaccountNo;
        }   

        if (this.phone_no_pending == '' || this.phone_no_pending == undefined) {
          this.phone_no_pending = '';
        }
        else {
          this.phone_no_pending = this.phone_no_pending;
        }  
        if(this.from_amount_pending == "" || this.from_amount_pending == undefined || this.from_amount_pending == null)
        {
           this.from_amount_pending ="";
        }
        else 
        {
           this.from_amount_pending = this.from_amount_pending;
        }
        if(this.to_amount_pending == "" || this.to_amount_pending == undefined || this.to_amount_pending == null)
        {
           this.to_amount_pending ="";
        }
        else 
        {
           this.to_amount_pending = this.to_amount_pending;
        }
    }
    else
    {
        this.type ="";
        this.storage.store('PendingTagFromDate',"")  
        if (this.pendingDate == '' || this.pendingDate == undefined) {
          this.pendingchangeDate = this.pendingtodayDate;     
        }
        else {      
          this.pendingdate = new Date();
          this.pendingchangeDate = this.datepipe.transform(this.pendingdate, 'MMM dd, yyyy');      
        
        }
        if (this.pendingtoDate == '' || this.pendingtoDate == undefined) {
          this.pendingtodatechangeDate = this.pendingtodaytodate;
        }
        else {
          this.pendingtodate = new Date();
          this.pendingtodatechangeDate = this.datepipe.transform(this.pendingtodate, 'MMM dd, yyyy');    
        }  
        if (this.pendingaccountNo == '' || this.pendingaccountNo == undefined) {
          this.pendingaccountNo = '';
        }
        else {
          this.pendingaccountNo = this.pendingaccountNo;
        }    
        if (this.phone_no_pending == '' || this.phone_no_pending == undefined) {
          this.phone_no_pending = '';
        }
        else {
          this.phone_no_pending = this.phone_no_pending;
        }  
        if(this.from_amount_pending == "" || this.from_amount_pending == undefined || this.from_amount_pending == null)
        {
           this.from_amount_pending ="";
        }
        else 
        {
           this.from_amount_pending = this.from_amount_pending;
        }
        if(this.to_amount_pending == "" || this.to_amount_pending == undefined || this.to_amount_pending == null)
        {
           this.to_amount_pending ="";
        }
        else 
        {
           this.to_amount_pending = this.to_amount_pending;
        }
    }    

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.pendingchangeDate).set('toDate',this.pendingtodatechangeDate).set('status', 'PENDING').set('accountNo', this.pendingaccountNo).set('phone_no', this.phone_no_pending).set('orderByField',this.orderbypending).set('orderMethod',this.ordermethodpending).set('payment_id',this.BankTypePending).set('userName' , this.user_name_pending).set("pageNumber","1").set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_pending).set('amountTo', this.to_amount_pending);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawPendingList = this.dto.Response;   
        this.totalItems = result.totalRows; 
        console.log("this.withdrawPendingList>> " + JSON.stringify(this.withdrawPendingList));
        this.dtTrigger1.next();
        this.spinner.hide();
      });
  }

  gtyPending(page: any){
    this.storage.store('WithdrawalTag', "PENDING");
    this.withdrawPendingList = [];
    var id = 'tblpendingWithdraw' + this.idpendingIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idpendingIndex = this.idpendingIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.pendingDate = $("#pendingDate").val();
    this.pendingtoDate = $("#pendingtodate").val();
    console.log("this.pendingDate" + this.pendingDate);
   
        this.pendingDate = $("#pendingDate").val();
        this.pendingtoDate = $("#pendingtodate").val();
        if (this.pendingDate == '' || this.pendingDate == undefined) {
          this.pendingchangeDate = this.pendingtodayDate;
          console.log("this.pendingDate with pending todaydate" + this.pendingchangeDate);
        }
        else {      
          this.pendingchangeDate = this.pendingDate;    

        }
        if (this.pendingtoDate == '' || this.pendingtoDate == undefined) {
          this.pendingtodatechangeDate = this.pendingtodaytodate;
        }
        else {
          this.pendingtodatechangeDate = this.pendingtoDate;          
        } 
        if (this.pendingaccountNo == '' || this.pendingaccountNo == undefined) {
          this.pendingaccountNo = '';
        }
        else {
          this.pendingaccountNo = this.pendingaccountNo;
        }   

        if (this.phone_no_pending == '' || this.phone_no_pending == undefined) {
          this.phone_no_pending = '';
        }
        else {
          this.phone_no_pending = this.phone_no_pending;
        }  
        if(this.from_amount_pending == "" || this.from_amount_pending == undefined || this.from_amount_pending == null)
        {
           this.from_amount_pending ="";
        }
        else 
        {
           this.from_amount_pending = this.from_amount_pending;
        }
        if(this.to_amount_pending == "" || this.to_amount_pending == undefined || this.to_amount_pending == null)
        {
           this.to_amount_pending ="";
        }
        else 
        {
           this.to_amount_pending = this.to_amount_pending;
        }
      
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.pendingchangeDate).set('toDate',this.pendingtodatechangeDate).set('status', 'PENDING').set('accountNo', this.pendingaccountNo).set('phone_no', this.phone_no_pending).set('orderByField',this.orderbypending).set('orderMethod',this.ordermethodpending).set('payment_id',this.BankTypePending).set('userName' , this.user_name_pending).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_pending).set('amountTo', this.to_amount_pending);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawPendingList = this.dto.Response;    
        this.totalItems = result.totalRows;
        this.dtTrigger1.next();
        this.spinner.hide();
      });
  }

  checkedSearch(searchKey)
  {
    this.storage.store('WithdrawalTag', "CHECKED");
    this.withdrawCheckedList = [];
    var id = 'tblcheckedWithdraw' + this.idcheckedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idcheckedIndex = this.idcheckedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.checkedDate = $("#checkedDate").val();
    this.addedtoDate = $("#addedtodate").val();
    console.log("this.checkedDate" + this.checkedDate);
    if(searchKey =="btnsearch")
    {          
        if (this.checkedDate == '' || this.checkedDate == undefined) {
          console.log("date if case");
          this.checkedchangeDate = this.checkedtodayDate;
        }
        else {
          console.log("date else case");
          this.checkedchangeDate = this.checkedDate;
        }      
        if (this.addedtoDate == '' || this.addedtoDate == undefined) {
          console.log("date if case in added to date");
          this.addedtodatechangeDate = this.addedtodaytodate;
        }
        else {
          console.log("date else case in added to date");
          this.addedtodatechangeDate = this.addedtoDate;
        }
        console.log("this.checkedtodatechangeDate" + this.addedtodatechangeDate);
        if (this.checkedaccountNo == '' || this.checkedaccountNo == undefined) {
          this.checkedaccountNo = '';
        }
        else {
          this.checkedaccountNo = this.checkedaccountNo;
        } 

        if (this.phone_no_checked == '' || this.phone_no_checked == undefined) {
          this.phone_no_checked = '';
        }
        else {
          this.phone_no_checked = this.phone_no_checked;
        } 
        if(this.from_amount_checked == "" || this.from_amount_checked == undefined || this.from_amount_checked == null)
        {
           this.from_amount_checked ="";
        }
        else 
        {
           this.from_amount_checked = this.from_amount_checked;
        }
        if(this.to_amount_checked == "" || this.to_amount_checked == undefined || this.to_amount_checked == null)
        {
           this.to_amount_checked ="";
        }
        else 
        {
           this.to_amount_checked = this.to_amount_checked;
        }

    }
    else
    {         
          if (this.checkedDate == '' || this.checkedDate == undefined) {
            this.checkedchangeDate = this.checkedtodayDate;
          }
          else {       
            this.checkeddate = new Date();
            this.checkedchangeDate = this.datepipe.transform(this.checkeddate, 'MMM dd, yyyy'); 
          }      
          if (this.addedtoDate == '' || this.addedtoDate == undefined) {
            this.addedtodatechangeDate = this.addedtodaytodate;
          }
          else {
            this.addedtodate = new Date();
            this.addedtodatechangeDate = this.datepipe.transform(this.addedtodate, 'MMM dd, yyyy'); 
          }   
          if (this.checkedaccountNo == '' || this.checkedaccountNo == undefined) {
            this.checkedaccountNo = '';
          }
          else {
            this.checkedaccountNo = this.checkedaccountNo;
          }   
        
          if (this.phone_no_checked == '' || this.phone_no_checked == undefined) {
            this.phone_no_checked = '';
          }
          else {
            this.phone_no_checked = this.phone_no_checked;
          } 
          if(this.from_amount_checked == "" || this.from_amount_checked == undefined || this.from_amount_checked == null)
        {
           this.from_amount_checked ="";
        }
        else 
        {
           this.from_amount_checked = this.from_amount_checked;
        }
        if(this.to_amount_checked == "" || this.to_amount_checked == undefined || this.to_amount_checked == null)
        {
           this.to_amount_checked ="";
        }
        else 
        {
           this.to_amount_checked = this.to_amount_checked;
        }

    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.checkedchangeDate).set('toDate',this.addedtodatechangeDate).set('status', 'CHECKED').set('accountNo', this.checkedaccountNo).set('phone_no', this.phone_no_checked).set('orderByField',this.orderbychecking).set('orderMethod',this.ordermethodchecking).set('payment_id',this.BankTypeCheck).set('userName' , this.user_name_checked).set("pageNumber","1").set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_checked).set('amountTo', this.to_amount_checked);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawCheckedList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger4.next();
        this.spinner.hide();
      });
  }

  gtyChecking(page: any)
  {
    this.storage.store('WithdrawalTag', "CHECKED");
    this.withdrawCheckedList = [];
    var id = 'tblcheckedWithdraw' + this.idcheckedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idcheckedIndex = this.idcheckedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.checkedDate = $("#checkedDate").val();
    this.addedtoDate = $("#addedtodate").val();
    console.log("this.checkedDate" + this.checkedDate);
              
        if (this.checkedDate == '' || this.checkedDate == undefined) {
          console.log("date if case");
          this.checkedchangeDate = this.checkedtodayDate;
        }
        else {
          console.log("date else case");
          this.checkedchangeDate = this.checkedDate;
        }      
        if (this.addedtoDate == '' || this.addedtoDate == undefined) {
          console.log("date if case in added to date");
          this.addedtodatechangeDate = this.addedtodaytodate;
        }
        else {
          console.log("date else case in added to date");
          this.addedtodatechangeDate = this.addedtoDate;
        }
        console.log("this.checkedtodatechangeDate" + this.addedtodatechangeDate);
        if (this.checkedaccountNo == '' || this.checkedaccountNo == undefined) {
          this.checkedaccountNo = '';
        }
        else {
          this.checkedaccountNo = this.checkedaccountNo;
        } 

        if (this.phone_no_checked == '' || this.phone_no_checked == undefined) {
          this.phone_no_checked = '';
        }
        else {
          this.phone_no_checked = this.phone_no_checked;
        }
        if(this.from_amount_checked == "" || this.from_amount_checked == undefined || this.from_amount_checked == null)
        {
           this.from_amount_checked ="";
        }
        else 
        {
           this.from_amount_checked = this.from_amount_checked;
        }
        if(this.to_amount_checked == "" || this.to_amount_checked == undefined || this.to_amount_checked == null)
        {
           this.to_amount_checked ="";
        }
        else 
        {
           this.to_amount_checked = this.to_amount_checked;
        }   

    let headers = new HttpHeaders();
    headers = headers.set('Authorization',  this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.checkedchangeDate).set('toDate',this.addedtodatechangeDate).set('status', 'CHECKED').set('accountNo', this.checkedaccountNo).set('phone_no', this.phone_no_checked).set('orderByField',this.orderbychecking).set('orderMethod',this.ordermethodchecking).set('payment_id',this.BankTypeCheck).set('userName' , this.user_name_checked).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount).set('amountTo', this.to_amount);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawCheckedList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger4.next();
        this.spinner.hide();
      });
  }
  approveSearch(searchKey) {
    this.storage.store('WithdrawalTag', "APPROVE");
    this.withdrawApproveList = [];
    var id = 'tblapproveWithdraw' + this.idapproveIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idapproveIndex = this.idapproveIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.approveDate = $("#approveDate").val();
    console.log("this.approveDate" + this.approveDate);
    this.approvetoDate = $("#approvetodate").val();
    if(searchKey =="btnsearch")
    {          
        if (this.approveDate == '' || this.approveDate == undefined) {
          console.log("date if case");
          this.approvechangeDate = this.approvetodayDate;
        }
        else {
          console.log("date else case");
          this.approvechangeDate = this.approveDate;
        }

        if (this.approvetoDate == '' || this.approvetoDate == undefined) {
          console.log("date if case in approve to date");
          this.approvetodatechangeDate = this.approvetodaytodate;
        }
        else {
          console.log("date else case");
          this.approvetodatechangeDate = this.approvetoDate;
        }
  
        if (this.approveaccountNo == '' || this.approveaccountNo == undefined) {
          this.approveaccountNo = '';
        }
        else {
          this.approveaccountNo = this.approveaccountNo;
        } 
        
        if (this.phone_no_approved == '' || this.phone_no_approved == undefined) {
          this.phone_no_approved = '';
        }
        else {
          this.phone_no_approved = this.phone_no_approved;
        } 
        if(this.from_amount_approved == "" || this.from_amount_approved == undefined || this.from_amount_approved == null)
        {
           this.from_amount_approved ="";
        }
        else 
        {
           this.from_amount_approved = this.from_amount_approved;
        }
        if(this.to_amount_approved == "" || this.to_amount_approved == undefined || this.to_amount_approved == null)
        {
           this.to_amount_approved ="";
        }
        else 
        {
           this.to_amount_approved = this.to_amount_approved;
        }
    }
    else
    {   
          if (this.approveDate == '' || this.approveDate == undefined) {
            this.approvechangeDate = this.approvetodayDate;
          }
          else {           
            this.approvedate= new Date();
            this.approvechangeDate = this.datepipe.transform(this.approvedate, 'MMM dd, yyyy'); 
          }
         

          if (this.approvetoDate == '' || this.approvetoDate == undefined) {         
            this.approvetodatechangeDate = this.approvetodaytodate;
          }
          else {           
            this.approvetodate = new Date();
            this.approvetodatechangeDate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy'); 
          }       
          if (this.approveaccountNo == '' || this.approveaccountNo == undefined) {
            this.approveaccountNo = '';
          }
          else {
            this.approveaccountNo = this.approveaccountNo;
          }  
          
          if (this.phone_no_approved == '' || this.phone_no_approved == undefined) {
            this.phone_no_approved = '';
          }
          else {
            this.phone_no_approved = this.phone_no_approved;
          } 
          if(this.from_amount_approved == "" || this.from_amount_approved == undefined || this.from_amount_approved == null)
          {
             this.from_amount_approved ="";
          }
          else 
          {
             this.from_amount_approved = this.from_amount_approved;
          }
          if(this.to_amount_approved == "" || this.to_amount_approved == undefined || this.to_amount_approved == null)
          {
             this.to_amount_approved ="";
          }
          else 
          {
             this.to_amount_approved = this.to_amount_approved;
          }
         
    }  

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.approvechangeDate).set('toDate',this.approvetodatechangeDate).set('status', 'APPROVED').set('accountNo', this.approveaccountNo).set('phone_no', this.phone_no_approved).set('orderByField',this.orderbyapproved).set('orderMethod',this.ordermethodapproved).set('payment_id',this.BankTypeApproved).set('userName' , this.user_name_approved).set("pageNumber","1").set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_approved).set('amountTo', this.to_amount_approved);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawApproveList = this.dto.Response;
        this.totalItems = result.totalRows; 
        this.dtTrigger2.next();
        this.spinner.hide();
       
         
                
      });
  }

  gtyApproved(page: any)
  {
    this.storage.store('WithdrawalTag', "APPROVE");
    this.withdrawApproveList = [];
    var id = 'tblapproveWithdraw' + this.idapproveIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.idapproveIndex = this.idapproveIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.approveDate = $("#approveDate").val();
    console.log("this.approveDate" + this.approveDate);
    this.approvetoDate = $("#approvetodate").val();
            
        if (this.approveDate == '' || this.approveDate == undefined) {
          console.log("date if case");
          this.approvechangeDate = this.approvetodayDate;
        }
        else {
          console.log("date else case");
          this.approvechangeDate = this.approveDate;
        }

        if (this.approvetoDate == '' || this.approvetoDate == undefined) {
          console.log("date if case in approve to date");
          this.approvetodatechangeDate = this.approvetodaytodate;
        }
        else {
          console.log("date else case");
          this.approvetodatechangeDate = this.approvetoDate;
        }
  
        if (this.approveaccountNo == '' || this.approveaccountNo == undefined) {
          this.approveaccountNo = '';
        }
        else {
          this.approveaccountNo = this.approveaccountNo;
        } 
        
        if (this.phone_no_approved == '' || this.phone_no_approved == undefined) {
          this.phone_no_approved = '';
        }
        else {
          this.phone_no_approved = this.phone_no_approved;
        } 
        if(this.from_amount_approved == "" || this.from_amount_approved == undefined || this.from_amount_approved == null)
        {
           this.from_amount_approved ="";
        }
        else 
        {
           this.from_amount_approved = this.from_amount_approved;
        }
        if(this.to_amount_approved == "" || this.to_amount_approved == undefined || this.to_amount_approved == null)
        {
           this.to_amount_approved ="";
        }
        else 
        {
           this.to_amount_approved = this.to_amount_approved;
        }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();
    params = params.set('fromDate', this.approvechangeDate).set('toDate',this.approvetodatechangeDate).set('status', 'APPROVED').set('accountNo', this.approveaccountNo).set('phone_no', this.phone_no_approved).set('orderByField',this.orderbyapproved).set('orderMethod',this.ordermethodapproved).set('payment_id',this.BankTypeApproved).set('userName' , this.user_name_approved).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_approved).set('amountTo', this.to_amount_approved);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawApproveList = this.dto.Response;
        this.totalItems = result.totalRows;  
        this.dtTrigger2.next();
        this.spinner.hide();
      });
  }
  
  deniedSearch(searchKey) 
  {
    this.storage.store('WithdrawalTag', "DENIED");
    this.withdrawDeniedList = [];
    var id = 'tbldeniedWithdraw' + this.iddeniedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.iddeniedIndex = this.iddeniedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.deniedDate = $("#deniedDate").val();
    this.deniedtoDate = $("#deniedtodate").val();

   if(searchKey =="btnsearch")
   {        
        if (this.deniedDate == '' || this.deniedDate == undefined) {
          console.log("date if case");
          this.deniedchangeDate = this.deniedtodayDate;
        }
        else {
          console.log("date else case");
          this.deniedchangeDate = this.deniedDate;
        }

        if (this.deniedtoDate == '' || this.deniedtoDate == undefined) {
          console.log("date if case");
          this.deniedtodatechangeDate = this.deniedtodaytodate;
        }
        else {
          console.log("date else case");
          this.deniedtodatechangeDate = this.deniedtoDate;
        }
        console.log("this.deniedtodatechangeDate" + this.deniedtodatechangeDate);
        if (this.deniedaccountNo == '' || this.deniedaccountNo == undefined) {
          this.deniedaccountNo = '';
        }
        else {
          this.deniedaccountNo = this.deniedaccountNo;
        }

        if (this.phone_no_denied == '' || this.phone_no_denied == undefined) {
          this.phone_no_denied = '';
        }
        else {
          this.phone_no_denied = this.phone_no_denied;
        }
        if(this.from_amount_denied == "" || this.from_amount_denied == undefined || this.from_amount_denied == null)
        {
           this.from_amount_denied ="";
        }
        else 
        {
           this.from_amount_denied = this.from_amount_denied;
        }
        if(this.to_amount_denied == "" || this.to_amount_denied == undefined || this.to_amount_denied == null)
        {
           this.to_amount_denied ="";
        }
        else 
        {
           this.to_amount_denied = this.to_amount_denied;
        }
   }
   else
   {          
        if (this.deniedDate == '' || this.deniedDate == undefined) {
          console.log("date if case");
          this.deniedchangeDate = this.deniedtodayDate;
        }
        else {
          this.denieddate = new Date();
          this.deniedchangeDate = this.datepipe.transform(this.denieddate, 'MMM dd, yyyy'); 
        }
        if (this.deniedtoDate == '' || this.deniedtoDate == undefined) {
          console.log("date if case");
          this.deniedtodatechangeDate = this.deniedtodaytodate;
        }
        else {
          this.deniedtodate = new Date();
          this.deniedtodatechangeDate = this.datepipe.transform(this.deniedtodate, 'MMM dd, yyyy'); 
        }
        if (this.deniedaccountNo == '' || this.deniedaccountNo == undefined) {
          this.deniedaccountNo = '';
        }
        else {
          this.deniedaccountNo = this.deniedaccountNo;
        }

        if (this.phone_no_denied == '' || this.phone_no_denied == undefined) {
          this.phone_no_denied = '';
        }
        else {
          this.phone_no_denied = this.phone_no_denied;
        }
        if(this.from_amount_denied == "" || this.from_amount_denied == undefined || this.from_amount_denied == null)
        {
           this.from_amount_denied ="";
        }
        else 
        {
           this.from_amount_denied = this.from_amount_denied;
        }
        if(this.to_amount_denied == "" || this.to_amount_denied == undefined || this.to_amount_denied == null)
        {
           this.to_amount_denied ="";
        }
        else 
        {
           this.to_amount_denied = this.to_amount_denied;
        }
   }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();  
    params = params.set('fromDate', this.deniedchangeDate).set('toDate',this.deniedtodatechangeDate).set('status', 'DENIED').set('accountNo', this.deniedaccountNo).set('phone_no', this.phone_no_denied).set('orderByField',this.orderbydenied).set('orderMethod',this.ordermethoddenied).set('payment_id',this.BankTypeDenied).set('userName' , this.user_name_denied).set("pageNumber","1").set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_denied).set('amountTo', this.to_amount_denied);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawDeniedList = this.dto.Response;
        this.totalItems = result.totalRows;
        console.log("this.withdrawDeniedList>> " + JSON.stringify(this.withdrawDeniedList));
        this.dtTrigger3.next();
        this.spinner.hide();
      });
  }

  gtyDenied(page: any)
  {
    this.storage.store('WithdrawalTag', "DENIED");
    this.withdrawDeniedList = [];
    var id = 'tbldeniedWithdraw' + this.iddeniedIndex;
    var table = $('#' + id).DataTable();
    table.destroy();
    this.iddeniedIndex = this.iddeniedIndex + 1;
    this.spinner.show();
    this.dto.token = this.storage.retrieve('token');
    this.deniedDate = $("#deniedDate").val();
    this.deniedtoDate = $("#deniedtodate").val();

    if (this.deniedDate == '' || this.deniedDate == undefined)
     {
      console.log("date if case");
      this.deniedchangeDate = this.deniedtodayDate;
    }
    else {
      console.log("date else case");
      this.deniedchangeDate = this.deniedDate;
    }

    if (this.deniedtoDate == '' || this.deniedtoDate == undefined) {
      console.log("date if case");
      this.deniedtodatechangeDate = this.deniedtodaytodate;
    }
    else {
      console.log("date else case");
      this.deniedtodatechangeDate = this.deniedtoDate;
    }
    console.log("this.deniedtodatechangeDate" + this.deniedtodatechangeDate);
    if (this.deniedaccountNo == '' || this.deniedaccountNo == undefined) {
      this.deniedaccountNo = '';
    }
    else {
      this.deniedaccountNo = this.deniedaccountNo;
    }

    if (this.phone_no_denied == '' || this.phone_no_denied == undefined) {
      this.phone_no_denied = '';
    }
    else {
      this.phone_no_denied = this.phone_no_denied;
    }
    if(this.from_amount_denied == "" || this.from_amount_denied == undefined || this.from_amount_denied == null)
    {
       this.from_amount_denied ="";
    }
    else 
    {
       this.from_amount_denied = this.from_amount_denied;
    }
    if(this.to_amount_denied == "" || this.to_amount_denied == undefined || this.to_amount_denied == null)
    {
       this.to_amount_denied ="";
    }
    else 
    {
       this.to_amount_denied = this.to_amount_denied;
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.dto.token);
    let params = new HttpParams();  
    params = params.set('fromDate', this.deniedchangeDate).set('toDate',this.deniedtodatechangeDate).set('status', 'DENIED').set('accountNo', this.deniedaccountNo).set('phone_no', this.phone_no_denied).set('orderByField',this.orderbydenied).set('orderMethod',this.ordermethoddenied).set('payment_id',this.BankTypeDenied).set('userName' , this.user_name_denied).set("pageNumber",page).set("rowsOfPage",this.itemsPerPage.toString()).set('amountFrom',this.from_amount_denied).set('amountTo', this.to_amount_denied);
    this.http.get(this.funct.ipaddress + 'transaction/withdraws-by-params_Order', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result.results;
        this.withdrawDeniedList = this.dto.Response;
        this.totalItems = result.totalRows;
        this.dtTrigger3.next();
        this.spinner.hide();
      });
  }
  

  numericOnly(event): boolean { // restrict e,+,-,E characters in  input type number
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;
  }


     
  addStepAndNavigate(withdraw: any): void
  { 
   localStorage.setItem('withdrawData', JSON.stringify({
     id: withdraw.id,
     account_no:withdraw.account_no,
     balance_Str: withdraw.balance_Str,
     amount_Str:withdraw.amount_Str,
     payment:withdraw.payment,
     phone_no:withdraw.phone_no,
     status:withdraw.status,
     description:withdraw.description,
     created_date_string:withdraw.created_date_string,
     name:withdraw.name,
     updated_date_string:withdraw.updated_date_string,
     updated_by_name:withdraw.updated_by_name,
     tran_from:withdraw.tran_from
    
   }));

   this.router.navigate(['/withdraw-check-detail', withdraw.id]);
 }


}
