<div class="wrapper">
  <app-header-sidebar></app-header-sidebar>

  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2" style="border: 1px solid #ced4da;border-radius: 0.25rem;">
          <div class="col-sm-6">
            <h3 class="m-0 text-dark" style="font-weight: bold;padding: 3px 0px 3px 5px;"> Withdrawal </h3>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <form>
          <div class="row">

            <div class="col-md-12">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs nav-pills">
                <li (click)="search('tabsearch')">
                  <a id="all-tab" data-toggle="tab" href="#all"> ALL </a>
                </li>
                <li (click)="pendingSearch('tabsearch')">
                  <a id="pending-tab" data-toggle="tab" href="#pending"> PENDING </a>
                </li>
                <!-- <li (click)="checkedSearch('tabsearch')">
                  <a id="checked-tab" data-toggle="tab" href="#checked"> CHECKED </a>
                </li> -->
                <li (click)="approveSearch('tabsearch')">
                  <a id="approve-tab" data-toggle="tab" href="#approve"> APPROVE </a>
                </li>
                <li (click)="deniedSearch('tabsearch')">
                  <a id="denied-tab" data-toggle="tab" href="#denied"> DENIED </a>
                </li>
              </ul>

              <!-- Tab panes -->
              <div class="tab-content clearfix">

                <div class="tab-pane" id="all">
                  <div class="row">

                    
                    <div class="form-group col-md-2">        
                      <label>User Name</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="user_name"
                        [ngModelOptions]="{standalone: true}" >
                      
                    </div>

                    <div class="form-group col-md-2">
                      <!-- <div>Account No</div> -->
                      <label>Bank Account</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="accountNo"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >
                        <!-- <(ngModelChange)="onChangeAccNoAll()"> -->
                    </div>

                    <div class="form-group col-md-2">        
                      <label>Phone Number</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="phone_no"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="singleDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceSingle"  [(ngModel)]="date"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeSingle()">
                      <ngx-date-picker #dateInstanceSingle></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="alltodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceSingle1"  [(ngModel)]="alltodate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeAllToDate()">
                      <ngx-date-picker #dateInstanceSingle1></ngx-date-picker>
                    </div>


                  <!-- <div class="col-md-6"></div> -->

                  <div class="form-group col-md-2">
                    <label>Order By</label>
                    <select class="orderby form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="orderby"
                        [ngModelOptions]="{standalone: true}">
                        <option value="balance">Wallet</option>
                        <option value="amount">Amount</option>
                        <option value="payment">Payment</option>
                        <option value="updated_by_name">Updated By</option>
                        <option value="updated_date">Updated Date</option>
                        <option value="requested_date">Requested Date</option>
                    </select>
                  </div>

                

                  <div class="form-group col-md-2">
                    <label> Bank Type </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankType"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeTagSection()">                     
                      <option *ngFor="let bt of bankTypeList" value="{{bt.id}}">
                        {{bt.name}}
                      </option>
                      <option value="" selected>All</option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2">
                    <label>Order Method</label>
                    <select class="ordermethod form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="ordermethod"
                        [ngModelOptions]="{standalone: true}">
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                  </div>

                  <div class="form-group col-md-2">        
                    <label>From Amount</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="from_amount"
                      [ngModelOptions]="{standalone: true}" >
                    
                  </div>

                  <div class="form-group col-md-2">        
                    <label>To Amount</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="to_amount"
                      [ngModelOptions]="{standalone: true}" >
                    
                  </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="search('btnsearch')">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> Withdrawal All List </h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblWithdraw' + idIndex}}" datatable [dtTrigger]="dtTrigger"
                            [dtOptions]="dtOptions" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Account</th>
								                <th style="font-family: unicode;">Wallet</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Payment</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Remark</th>
                                <th style="font-family: unicode;">Requested Date</th>
                                <th style="font-family: unicode;">Requested By</th>
                                <th style="font-family: unicode;">Update Date</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Transfer From</th>
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let withdraw of withdrawList">
                                <td style="font-family: unicode;">{{withdraw.account_no}}</td>
								                <td style="font-family: unicode;" class="text-right">{{withdraw.balance_Str}}</td>
                                <td style="font-family: unicode;" class="text-right">{{withdraw.amount_Str}}</td>
                                <td style="font-family: unicode;">{{withdraw.payment}}</td>
                                <!-- <td style="font-family: unicode;"><a [routerLink] = "['/withdraw-cancel-detail', withdraw.uid]">{{withdraw.phone_no}}</a></td> -->
                                <td style="font-family: unicode;">
                                  <a [routerLink]="['/user-detail', withdraw.uid]" [queryParams]="{ type: 'withdrawalalllist' }">{{withdraw.phone_no}}</a>
                                </td>
                                <td style="font-family: unicode;">{{withdraw.status}}</td>
                                <td style="font-family: unicode;">{{withdraw.description}}</td>
                                <td style="font-family: unicode;">{{withdraw.created_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.name}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_by_name}}</td>
                                <td style="font-family: unicode;">{{withdraw.tran_from}}</td>
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <!-- <a
                                    [routerLink]="['/withdraw-check-detail', withdraw.id]"><i class="fas fa-edit"
                                      style="color:#4b88eb"></i></a> -->

                                      <a  (click)="addStepAndNavigate(withdraw)"><i class="fas fa-edit"
                                      style="color:#4b88eb"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <tr *ngFor="let withdraw of withdrawList | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: page,
                      totalItems: totalItems
                    }" 
                    >
                </tr> 
              <div class = "row">
                <div class = "col-md-6"></div>
                <div class = "col-md-6">
                  <pagination-controls
                    class="pagi"
                    (pageChange)="gty((page = $event))"
                  ></pagination-controls>
              </div>
              </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="pending">
                  <div class="row">

                    <div class="form-group col-md-2">        
                      <label>User Name</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="user_name_pending"
                        [ngModelOptions]="{standalone: true}" >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>Bank Account </label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="pendingaccountNo"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >
                       
                    </div>

              


                    <div class="form-group col-md-2">        
                      <label>Phone Number</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="phone_no_pending"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="pendingDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstancePending"  [(ngModel)]="pendingdate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePending()">
                      <ngx-date-picker #dateInstancePending></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="pendingtodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstancePending1"  [(ngModel)]="pendingtodate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePendingToDate()">
                      <ngx-date-picker #dateInstancePending1></ngx-date-picker>
                    </div>


                     <!-- <div class="col-md-6"></div> -->

                     <div class="form-group col-md-2">
                      <label> Bank Type </label>
                      <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankTypePending"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeTagSection()">                        
                        <option *ngFor="let bt of bankTypeList" value="{{bt.id}}">
                          {{bt.name}}
                        </option>
                        <option value="" selected>All</option>
                      </select>
                    </div>

                     <div class="form-group col-md-2">
                      <label>Order By</label>
                      <select class="orderby form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="orderbypending"
                          [ngModelOptions]="{standalone: true}">
                          <option value="balance">Wallet</option>
                          <option value="amount">Amount</option>
                          <option value="payment">Payment</option>
                          <option value="requested_date">Requested Date</option>
                          <!-- <option value="updated_by_name">Updated By</option>
                          <option value="updated_date">Updated Date</option> -->
                      </select>
                    </div>
        
                    <div class="form-group col-md-2">
                      <label>Order Method</label>
                      <select class="ordermethod form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="ordermethodpending"
                          [ngModelOptions]="{standalone: true}">
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                      </select>
                    </div>

                    <div class="form-group col-md-2">        
                      <label>From Amount</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="from_amount_pending"
                        [ngModelOptions]="{standalone: true}" >
                      
                    </div>
  
                    <div class="form-group col-md-2">        
                      <label>To Amount</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="to_amount_pending"
                        [ngModelOptions]="{standalone: true}" >
                      
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="pendingSearch('btnsearch')">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> Withdrawal Pending List </h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblpendingWithdraw' + idpendingIndex}}" datatable
                            [dtTrigger]="dtTrigger1" [dtOptions]="dtOptions1" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Account </th>
								                <th style="font-family: unicode;">Wallet</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Payment</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Requested Date</th>
                                <th style="font-family: unicode;">Requested By</th>
                                <th style="font-family: unicode;">Update Date</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Transfer From</th>
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let withdraw of withdrawPendingList">
                                <td style="font-family: unicode;">{{withdraw.account_no}}</td>
								                <td style="font-family: unicode;" class="text-right">{{withdraw.balance_Str}}</td>
                                <td style="font-family: unicode;" class="text-right">{{withdraw.amount_Str}}</td>
                                <td style="font-family: unicode;">{{withdraw.payment}}</td>
                                <!-- <td style="font-family: unicode;"><a [routerLink] = "['/withdraw-cancel-detail', withdraw.uid]">{{withdraw.phone_no}}</a></td> -->
                                <td style="font-family: unicode;">
                                  <a [routerLink]="['/user-detail', withdraw.uid]" [queryParams]="{ type: 'withdrawalalllist' }">{{withdraw.phone_no}}</a>
                                </td>
                                <td style="font-family: unicode;">{{withdraw.status}}</td>
                                <td style="font-family: unicode;">{{withdraw.created_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.name}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_by_name}}</td>
                                <td style="font-family: unicode;">{{withdraw.tran_from}}</td>
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <!-- <a
                                    [routerLink]="['/withdraw-check-detail', withdraw.id]"><i class="fas fa-edit"
                                      style="color:#4b88eb"></i></a> -->

                                      <a (click)="addStepAndNavigate(withdraw)"><i class="fas fa-edit"
                                        style="color:#4b88eb"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <tr *ngFor="let withdraw of withdrawPendingList | paginate
                          : {
                              itemsPerPage: itemsPerPage,
                              currentPage: page,
                              totalItems: totalItems
                            }" 
                            >
                        </tr> 
                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls
                            class="pagi"
                            (pageChange)="gtyPending((page = $event))"
                          ></pagination-controls>
                      </div>
                      </div>
        



                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="checked">
                  <div class="row">

                    <div class="form-group col-md-2">        
                      <label>User Name</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="user_name_checked"
                        [ngModelOptions]="{standalone: true}"  >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>Bank Account </label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="checkedaccountNo"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >
                        <!-- <(ngModelChange)="onChangeAccNoChecked()"> -->
                    </div>


                    <div class="form-group col-md-2">        
                      <label>Phone Number</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="phone_no_checked"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="checkedDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceChecked"  [(ngModel)]="checkeddate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeChecked()">
                      <ngx-date-picker #dateInstanceChecked></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="addedtodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceAdded1"  [(ngModel)]="addedtodate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeAddedToDate()">
                      <ngx-date-picker #dateInstanceAdded1></ngx-date-picker>
                    </div>

                    
                <div class="form-group col-md-2">
                  <label> Bank Type </label>
                  <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankTypeCheck"
                    [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeTagSection()">
                    
                    <option *ngFor="let bt of bankTypeList" value="{{bt.id}}">
                      {{bt.name}}
                    </option>
                    <option value="" selected>All</option>
                  </select>
                </div>

                     <!-- <div class="col-md-6"></div> -->

                     <div class="form-group col-md-2">
                      <label>Order By</label>
                      <select class="orderby form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="orderbychecking"
                          [ngModelOptions]="{standalone: true}">
                          <option value="balance">Wallet</option>
                          <option value="amount">Amount</option>
                          <option value="payment">Payment</option>
                          <option value="updated_by_name">Updated By</option>
                          <option value="updated_date">Updated Date</option>
                          <option value="requested_date">Requested Date</option>
                      </select>
                    </div>
        
                    <div class="form-group col-md-2">
                      <label>Order Method</label>
                      <select class="ordermethod form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="ordermethodchecking"
                          [ngModelOptions]="{standalone: true}">
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                      </select>
                    </div>

                    <div class="form-group col-md-2">        
                      <label>From Amount</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="from_amount_checked"
                        [ngModelOptions]="{standalone: true}" >
                      
                    </div>
  
                    <div class="form-group col-md-2">        
                      <label>To Amount</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="to_amount_checked"
                        [ngModelOptions]="{standalone: true}" >
                      
                    </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="checkedSearch('btnsearch')">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> Withdrawal Checked List </h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblcheckedWithdraw' + idcheckedIndex}}" datatable
                            [dtTrigger]="dtTrigger4" [dtOptions]="dtOptions4" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Account </th>
						                		<th style="font-family: unicode;">Wallet</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Payment</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Requested Date</th>
                                <th style="font-family: unicode;">Requested By</th>
                                <th style="font-family: unicode;">Update Date</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Transfer From</th>
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let withdraw of withdrawCheckedList">
                                <td style="font-family: unicode;">{{withdraw.account_no}}</td>
								                 <td style="font-family: unicode;" class="text-right">{{withdraw.balance_Str}}</td>
                                <td style="font-family: unicode;" class="text-right">{{withdraw.amount_Str}}</td>
                                <td style="font-family: unicode;">{{withdraw.payment}}</td>
                                <td style="font-family: unicode;"><a [routerLink] = "['/withdraw-cancel-detail', withdraw.uid]">{{withdraw.phone_no}}</a></td>
                                <td style="font-family: unicode;">{{withdraw.status}}</td>
                                <td style="font-family: unicode;">{{withdraw.created_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.name}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_by_name}}</td>
                                <td style="font-family: unicode;">{{withdraw.tran_from}}</td>
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <!-- <a
                                    [routerLink]="['/withdraw-check-detail', withdraw.id]"><i class="fas fa-edit"
                                      style="color:#4b88eb"></i></a> -->

                                      <a (click)="addStepAndNavigate(withdraw)"><i class="fas fa-edit"
                                        style="color:#4b88eb"></i></a>

                                      
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <tr *ngFor="let withdraw of withdrawCheckedList | paginate
                          : {
                              itemsPerPage: itemsPerPage,
                              currentPage: page,
                              totalItems: totalItems
                            }" 
                            >
                        </tr> 
                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls
                            class="pagi"
                            (pageChange)="gtyChecking((page = $event))"
                          ></pagination-controls>
                      </div>
                      </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="approve">
                  <div class="row">

                    <div class="form-group col-md-2">        
                      <label>User Name</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="user_name_approved"
                        [ngModelOptions]="{standalone: true}"  >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>Bank Account </label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="approveaccountNo"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >
                        <!-- <(ngModelChange)="onChangeAccNoApproved()"> -->
                    </div>

                    <div class="form-group col-md-2">        
                      <label>Phone Number</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="phone_no_approved"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >                
                    </div>

                    <div class="form-group col-md-2">
                      <label>Requested Date</label>
                      <input id="approveDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceApprove"  [(ngModel)]="approvedate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeApprove()">
                      <ngx-date-picker #dateInstanceApprove></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="approvetodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceApprove1"  [(ngModel)]="approvetodate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeApproveToDate()">
                      <ngx-date-picker #dateInstanceApprove1></ngx-date-picker>
                    </div>

                   <!-- <div class="col-md-6"></div> -->

                   <div class="form-group col-md-2">
                    <label> Bank Type </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankTypeApproved"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeTagSection()">
                      
                      <option *ngFor="let bt of bankTypeList" value="{{bt.id}}">
                        {{bt.name}}
                      </option>
                      <option value="" selected>All</option>
                    </select>
                  </div>

                   <div class="form-group col-md-2">
                    <label>Order By</label>
                    <select class="orderby form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="orderbyapproved"
                        [ngModelOptions]="{standalone: true}">
                        <option value="balance">Wallet</option>
                        <option value="amount">Amount</option>
                        <option value="payment">Payment</option>
                        <option value="updated_by_name">Updated By</option>
                        <option value="updated_date">Updated Date</option>
                        <option value="requested_date">Requested Date</option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2">
                    <label>Order Method</label>
                    <select class="ordermethod form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="ordermethodapproved"
                        [ngModelOptions]="{standalone: true}">
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                  </div>

                  <div class="form-group col-md-2">        
                    <label>From Amount</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="from_amount_approved"
                      [ngModelOptions]="{standalone: true}" >
                    
                  </div>

                  <div class="form-group col-md-2">        
                    <label>To Amount</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="to_amount_approved"
                      [ngModelOptions]="{standalone: true}" >
                    
                  </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="approveSearch('btnsearch')">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;"> Withdrawal Approved List </h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tblapproveWithdraw' + idapproveIndex}}" datatable
                            [dtTrigger]="dtTrigger2" [dtOptions]="dtOptions2" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Account </th>
								                <th style="font-family: unicode;">Wallet</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Payment</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Requested Date</th>
                                <th style="font-family: unicode;">Requested By</th>
                                <th style="font-family: unicode;">Update Date</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Transfer From</th>
                                <th style="font-family: unicode; width:100px;">Withdraw-Bank-Acc/Name</th>
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let withdraw of withdrawApproveList">
                                <td style="font-family: unicode;">{{withdraw.account_no}}</td>
								                <td style="font-family: unicode;" class="text-right">{{withdraw.balance_Str}}</td>
                                <td style="font-family: unicode;" class="text-right">{{withdraw.amount_Str}}</td>
                                <td style="font-family: unicode;">{{withdraw.payment}}</td>                             
                                <td style="font-family: unicode;">
                                  <a [routerLink]="['/user-detail', withdraw.uid]" [queryParams]="{ type: 'withdrawalalllist' }">{{withdraw.phone_no}}</a>
                                </td>
                                <td style="font-family: unicode;">{{withdraw.status}}</td>
                                <td style="font-family: unicode;">{{withdraw.created_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.name}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_by_name}}</td>
                                <td style="font-family: unicode;">{{withdraw.tran_from}}</td>
                                <td style="font-family: unicode;">{{withdraw.account_name}} - {{withdraw.account_number}}</td>
                                 <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <!--<a
                                    [routerLink]="['/withdraw-check-detail', withdraw.id]"><i class="fas fa-edit"
                                      style="color:#4b88eb"></i></a> -->

                                      <a (click)="addStepAndNavigate(withdraw)"><i class="fas fa-edit"
                                        style="color:#4b88eb"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <tr *ngFor="let withdraw of withdrawApproveList | paginate
                          : {
                              itemsPerPage: itemsPerPage,
                              currentPage: page,
                              totalItems: totalItems
                            }" 
                            >
                        </tr> 
                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls
                            class="pagi"
                            (pageChange)="gtyApproved((page = $event))"
                          ></pagination-controls>
                      </div>
                      </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="tab-pane" id="denied">
                  <div class="row">

                    <div class="form-group col-md-2">        
                      <label>User Name</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="user_name_denied"
                        [ngModelOptions]="{standalone: true}"  >                
                    </div>


                    <div class="form-group col-md-2">
                      <label>Bank Account </label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="deniedaccountNo"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >
                        <!-- <(ngModelChange)="onChangeAccNoDenied()"> -->
                    </div>

                    <div class="form-group col-md-2">        
                      <label>Phone Number</label>
                      <input type="text" class="form-control form-control-sm" [(ngModel)]="phone_no_denied"
                        [ngModelOptions]="{standalone: true}" (keypress)="numericOnly($event)" >                
                    </div>


                    <div class="form-group col-md-2">
                      <label>From Date</label>
                      <input id="deniedDate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceDenied"  [(ngModel)]="denieddate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeDenied()">
                      <ngx-date-picker #dateInstanceDenied></ngx-date-picker>
                    </div>

                    <div class="form-group col-md-2">
                      <label>To Date</label>
                      <input id="deniedtodate" readonly class="form-control form-control-sm" placeholder="Select Date"
                        [ngxDatePicker]="dateInstanceDenied1"   [(ngModel)]="deniedtodate"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeDeniedToDate()">
                      <ngx-date-picker #dateInstanceDenied1></ngx-date-picker>
                    </div>

                   <!-- <div class="col-md-6"></div> -->

                   <div class="form-group col-md-2">
                    <label> Bank Type </label>
                    <select class="status form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="BankTypeDenied"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeTagSection()">
                     
                      <option *ngFor="let bt of bankTypeList" value="{{bt.id}}">
                        {{bt.name}}
                      </option>
                      <option value="" selected>All</option>
                    </select>
                  </div>

                   <div class="form-group col-md-2">
                    <label>Order By</label>
                    <select class="orderby form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="orderbydenied"
                        [ngModelOptions]="{standalone: true}">
                        <option value="balance">Wallet</option>
                        <option value="amount">Amount</option>
                        <option value="payment">Payment</option>
                        <option value="updated_by_name">Updated By</option>
                        <option value="updated_date">Updated Date</option>
                        <option value="requested_date">Requested Date</option>
                    </select>
                  </div>
      
                  <div class="form-group col-md-2">
                    <label>Order Method</label>
                    <select class="ordermethod form-control form-control-sm col-md-12 col-sm-4 col-xs-4" [(ngModel)]="ordermethoddenied"
                        [ngModelOptions]="{standalone: true}">
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                  </div>

                  <div class="form-group col-md-2">        
                    <label>From Amount</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="from_amount_denied"
                      [ngModelOptions]="{standalone: true}" >
                    
                  </div>

                  <div class="form-group col-md-2">        
                    <label>To Amount</label>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="to_amount_denied"
                      [ngModelOptions]="{standalone: true}" >
                    
                  </div>

                    <div class="form-group col-md-2 col-sm-4 col-xs-4">
                      <label></label>
                      <button id="search" type="button" class="btn btn-block btn-primary btn-sm" style="margin-top: 4%;"
                        (click)="deniedSearch('btnsearch')">Search</button>
                    </div>

                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="card-title" style="font-weight: bold;">Withdrawal Denied List</h3>
                        </div>
                        <div class="card-body">
                          <table width='100%' id="{{'tbldeniedWithdraw' + iddeniedIndex}}" datatable
                            [dtTrigger]="dtTrigger3" [dtOptions]="dtOptions3" class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th style="font-family: unicode;">Bank Account </th>
								                <th style="font-family: unicode;">Wallet</th>
                                <th style="font-family: unicode;">Amount</th>
                                <th style="font-family: unicode;">Payment</th>
                                <th style="font-family: unicode;">Phone No</th>
                                <th style="font-family: unicode;">Status</th>
                                <th style="font-family: unicode;">Remark</th>
                                <th style="font-family: unicode;">Requested Date</th>
                                <th style="font-family: unicode;">Requested By</th>
                                <th style="font-family: unicode;">Update Date</th>
                                <th style="font-family: unicode;">Updated By</th>
                                <th style="font-family: unicode;">Transfer From</th>
                                <th style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let withdraw of withdrawDeniedList">
                                <td style="font-family: unicode;">{{withdraw.account_no}}</td>
								                <td style="font-family: unicode;" class="text-right">{{withdraw.balance_Str}}</td>
                                <td style="font-family: unicode;" class="text-right">{{withdraw.amount_Str}}</td>
                                <td style="font-family: unicode;">{{withdraw.payment}}</td>
                                <!-- <td style="font-family: unicode;"><a [routerLink] = "['/withdraw-cancel-detail', withdraw.uid]">{{withdraw.phone_no}}</a></td> -->
                                <td style="font-family: unicode;">
                                  <a [routerLink]="['/user-detail', withdraw.uid]" [queryParams]="{ type: 'withdrawalalllist' }">{{withdraw.phone_no}}</a>
                                </td>
                                <td style="font-family: unicode;">{{withdraw.status}}</td>
                                <td style="font-family: unicode;">{{withdraw.description}}</td>
                                <td style="font-family: unicode;">{{withdraw.created_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.name}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_date_string}}</td>
                                <td style="font-family: unicode;">{{withdraw.updated_by_name}}</td>
                                <td style="font-family: unicode;">{{withdraw.tran_from}}</td>
                                <td style="font-family: unicode;" *ngIf="hardcode_edit_bool === true">
                                  <!-- <a
                                    [routerLink]="['/withdraw-check-detail', withdraw.id]"><i class="fas fa-edit"
                                      style="color:#4b88eb"></i></a> -->

                                      <a (click)="addStepAndNavigate(withdraw)"><i class="fas fa-edit"
                                        style="color:#4b88eb"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <tr *ngFor="let withdraw of withdrawDeniedList | paginate
                          : {
                              itemsPerPage: itemsPerPage,
                              currentPage: page,
                              totalItems: totalItems
                            }" 
                            >
                        </tr> 
                      <div class = "row">
                        <div class = "col-md-6"></div>
                        <div class = "col-md-6">
                          <pagination-controls
                            class="pagi"
                            (pageChange)="gtyDenied((page = $event))"
                          ></pagination-controls>
                      </div>
                      </div>



                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </form>
       
      </div>

    </section>

  </div>

  <aside class="control-sidebar control-sidebar-dark">
 
  </aside>

  <app-footer></app-footer>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="square-jelly-box">
    <p style="font-size: 16px; color: white">Loading...</p>
  </ngx-spinner>
</div>


<div id="deleteData" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">

          <div class="modal-body">
              <form>

                  <div class="row" style="margin-top: 5%;margin-bottom: 3%;margin-left: 9%;">
                      <div class="form-group col-md-12">
                          <h5> This Account is used by another device. 
                              Please Sign Out. Login Again. </h5>
                      </div>
                  </div>

                  <div class="row" style="margin-left: 40%;margin-bottom: 3%;">
                      <div class="col-md-5 col-sm-6" id="deleteBtn">
                          <button type="button" class="btn btn-block btn-success" (click)="OkLogout()"> Ok </button>
                      </div>
                  </div>
              </form>
          </div>

      </div>
  </div>
</div>