import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-viber-notification-history',
  templateUrl: './viber-notification-history.component.html',
  styleUrls: ['./viber-notification-history.component.css']
})
export class ViberNotificationHistoryComponent implements OnInit {
 constructor() { 
 
  }

  ngOnInit(): void {
  }


}
