import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";
import { FunctService } from '../../service/funct.service';
import { DtoService } from '../../service/dto.service';
import { DomSanitizer, SafeUrl  } from '@angular/platform-browser'; //for unsafe image
import Responsive from 'datatables.net-responsive';

import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';


import { Subject } from 'rxjs';
import { truncate } from 'fs';

declare var $: any;

@Component({
  selector: 'app-viber-notification-detail',
  templateUrl: './viber-notification-detail.component.html',
  styleUrls: ['./viber-notification-detail.component.css']
})

export class ViberNotificationDetailComponent implements OnInit,PipeTransform {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();
  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject();
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject();
  dtOptions4: DataTables.Settings = {};
  dtTrigger4: Subject<any> = new Subject();
  dtOptions5: DataTables.Settings = {};
  dtTrigger5: Subject<any> = new Subject();
  dtOptions6: DataTables.Settings = {};
  dtTrigger6: Subject<any> = new Subject();
  dtOptions7: DataTables.Settings = {};
  dtTrigger7: Subject<any> = new Subject();
  dtOptions8: DataTables.Settings = {};
  dtTrigger8: Subject<any> = new Subject();
  dtOptionswinlose: DataTables.Settings = {};
  dtTriggergwinlose: Subject<any> = new Subject();
  dtOptions10: DataTables.Settings = {};
  dtTrigger10: Subject<any> = new Subject();
  dtOptions11: DataTables.Settings = {};
  dtTrigger11: Subject<any> = new Subject();
  dtOptions12: DataTables.Settings = {};
  dtTrigger12: Subject<any> = new Subject();
  dtOptionsKM: DataTables.Settings = {};
  dtTriggerKM: Subject<any> = new Subject();
  dtTriggerTrand : Subject<any> = new Subject();
  dtOptionsTrand: DataTables.Settings = {};
  dtTriggergamewallet : Subject<any> = new Subject(); 
  dtOptionsgamewallet : DataTables.Settings = {};
  userDTO: any;
  token: any;
  userId: any;
  agentList: [];
  agentId: null;
  adminList: [];
  adminId: null;
  amount : any;
  wallet : any;
  twodwinamount : any;
  twodwinwallet : any;
  twodhitamount : any;
  twodhitwallet : any;
  threedhitamount : any;
  threedhitwallet : any;
  tempUserBankInfoDTOList : any;
  tempUserBankInfoDTOList_topup : any;
  bankimgURL : any;
  qrImageURL :any;
  editUserDTO : any;
  addfacdto:any;

  topupamount : any;
  topuptran : any;
  topupwallet : any;
  withdrawalAcc :any;
  withdrawalamount : any;
  withdrawalwallet : any;
  threeDBetDetailDTOList : any;


  walletHistoryDTOLit :any;
  tempWinnerDTOList : any;
  tempWinnerDTOList1 : any;
  tempFinancialTransactionDTOList : any;
  tempFinancialTransactionDTOList1 : any;
  tempQMfinancialTransactionDTOList : any;
  tempBetHistoryDTOList : any;
  tempBetHistoryDTOList1 : any;
  twoDBetDetailDTOList : any;

  idIndex: any;
  bankidIndex : any;
  idtwodindex : any;
  idtwodwinIndex : any;
  idtopupIndex : any;
  idwithdrawalIndex : any;
  idthreedbetIndex : any;
  idthreedwinIndex :any;
  idgameIndex : any;
  idgamewinIndex : any;
  idbonusIndex : any
  idtwoddetailIndex: any;
  idthreeddetailIndex : any;

  imgURL : any;
  message :any;
  imagePath: any;
  isProfile : boolean = false;

  singleDate: string = '';
  todate : any; //add this

  alltoDate: string = ''; //add this
  alltodate : any;
  alltodaytodate : any;
  alltodatechangeDate : any; //add this
  alldate: any;
  allchangeDate: any;
  alltodayDate: any;

  twodwinDate: string = '';
  twodwindate: any;
  twodwintoDate : any;
  twodwintodate : any;
  twodwintodaytodate : any;
  twodwintodatechangeDate : any;
  twodwintodayDate: any;
  twodwinchangeDate: any;

  twodbetDate: string = '';
  twodbetdate: any;
  twodbettodate :any;
  twodbettoDate : any;
  twodbettodaytodate : any;
  twodbettodatechangeDate : any;
  twodbettodayDate: any;
  twodbetchangeDate: any;
  towdbetDate : any;

  topuptodate : any;
  topuptoDate : any;
  topupdate: any;
  topupDate: any;
  topuptodayDate: any;
  topupchangeDate: any;
  topuptodaytodate : any;
  topuptodatechangeDate : any;

  withtodate : any;
  withtoDate : any;
  withtodatechangeDate : any;
  withtodaytodate : any;
  withdate: any;
  withtodayDate: any;
  withchangeDate: any;
  withDate : any;

  threedbetdate : any;
  threedbetDate : any;
  threedbetchangeDate : any;
  threedbettodate : any;
  threedbettoDate : any;
  threedbettodatechangeDate : any;
  threedbettodaytodate : any;
  threedbettodaydate : any;

  threedwindate : any;
  threedwinDate : any;
  threedwinchangeDate : any;
  threedwintodate : any;
  threedwintoDate : any;
  threedwintodatechangeDate : any;
  threedwintodaytodate : any;
  threedwintodayDate : any;

  gamedate : any;
  gameDate : any;
  gamechangeDate : any;
  gametodate : any;
  gametoDate : any;
  gametodatechangeDate : any;
  gametodaytodate : any;
  gametodaydate : any;

  gamewindate : any;
  gamewinDate : any;
  gamewinchangeDate : any;
  gamewintodate : any;
  gamewintoDate : any;
  gamwinetodatechangeDate : any;
  gamewintodaytodate : any;
  gamewintodaydate : any;
  bonusdate : any;
  bonusDate : any;
  bonuschangeDate : any;
  bonustodate : any;
  bonustoDate : any;
  bonustodatechangeDate : any;
  bonustodaytodate : any;
  bonustodaydate : any;
  twoDBetDetailDTOList_temp : any;
  threeDBetDetailDTOList_temp : any;
  queenmakerdate : any;
  qmdate : any;
  queenmakertodate : any;
  qmtodate : any;
  qmtodaydate: any;
  qmtodaytodate: any;
  queenmakerfchangedate : any;
  queenmakertchangedate  : any;
  tblqmtranid : any;
  gameproviderList : any;
  providerId : any;
  transactionDetailList : any;
  idtbltrnadetail : any;
  winnerDetail  : any;
  gameTranDetailList :  any;
  idgamewallet : any;
  gameWalletModel_temp : any;
  userGameLogList : any;
  tblgamelogtran : any;
  displayUserId : any;
  providerId1 : any;
  displayUserIdTran : any;
  allTranFromDate : any;
  isUseSearchMem : any;
  adjustLogs : any;
  userbalances:any;
  addTranType:any;
  deletTranDto:any;
  addTranDto:any;
  addTranAmt:any;
  addTranRemark:any;
  addAmount:any;
  addRemark:any;
  isInsertByAdmin:any;
  trantype:any;
  deleteId:any;
  gameDTO:any;
  data1 : any;
  data2: any;
  data3:any;
  source:any;

  deleteuseraccid :any;
  deletepaymentId  :any;
  deletebankaccid :any;
  type:any;
  originalAdminDTO:any;

  //adjust
  adjustmakerdate:any;
  adjustmakertodaydate:any;
  adjusttodate:any;
  adjustfromdate:any;
  adjfromdate:any;
  adjtodate:any;

  adjustchangedate : any;
  adjusttochangedate  : any;
  adjtodaydate: any;
  adjtodaytodate: any;
  tempAdjustTransactionList :any;
  tbladjustid : any;
  dtOptionsadjust: DataTables.Settings = {};
  dtTriggeradjust: Subject<any> = new Subject();
  adjusttype:any;
  retrunapistatus:any;
  retirnapiriskyplayer:any;
  retirnapiriskyplayer_from:any;
  retirnapiriskyplayer_to :any;
  transaction_id:any;
  temp_bankaccount:any;
  temp_paymenttype:any;
  temp_user_account_no :any;
  selectedButton:any;

  topupClicked: boolean = false;
  withdrawalClicked: boolean = false;
  isWithdrawalVisible = false; 
  remark:any;
  isQrModalVisible: boolean = false;

  qrImageUrl?: SafeUrl;

  isRiskyPlayer: boolean = false;
  riskyPlayer : boolean;


  
  hardcode_view : any;
  hardcode_add:any;
  hardcode_edit:any;
  hardcode_action:any;
  hardcode_delete: any;
  hardcode_resend: any;
  hardcode_delete_bool : any;
  hardcode_view_bool : any;
  hardcode_edit_bool :any;
  hardcode_add_bool : any;
  hardcode_double : any;
  hardcode_resend_bool : any;

  approvetodate:any;
  approvefromdate:any;


  approveFromDate: any;
  approveToDate:any;
  approvechangeDate:any;
  approvetodatechangeDate:any;

  approvetodayDate:any;
  approvetodaytodate:any;

  imageFileName :any;
  filePath:any;

  constructor(private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, 
    private router: Router, private route: ActivatedRoute,private datepipe: DatePipe, private funct: FunctService,private sanitizer:DomSanitizer) 
    {
    this.idIndex = 1;
    this.storage.store('isNotiSong', "");  

    
    this.hardcode_view = "ViberNotification_View";
    this.hardcode_add = "ViberNotification_Add";
    this.hardcode_edit = "ViberNotification_Edit";
    this.hardcode_delete = "ViberNotification_Del";
    this.hardcode_resend = "ViberNotification_Resend";

    this.dto.menuCodes = this.storage.retrieve('menucodes');

    const filteredMenuCodes_delete = [];
    const filteredMenuCodeds_view =[];
    const filteredMenuCodeds_add =[];
    const filteredMenuCodeds_edit =[];
    const filteredMenuCodeds_resend =[];
    this.dto.menuCodes.forEach(menuCode =>
     {
    if (menuCode === this.hardcode_delete) {
      filteredMenuCodes_delete.push(menuCode);
    }   
    if (menuCode === this.hardcode_view) {
      filteredMenuCodeds_view.push(menuCode);
    }
    if (menuCode === this.hardcode_add) {
      filteredMenuCodeds_add.push(menuCode);
    }
    if (menuCode === this.hardcode_edit) {
      filteredMenuCodeds_edit.push(menuCode);
    }
    if (menuCode === this.hardcode_resend) {
      filteredMenuCodeds_resend.push(menuCode);
    }
   
 
  });
      if(filteredMenuCodes_delete.length != 0)
      {
          this.hardcode_delete_bool = true;
      }
      else
      {
        this.hardcode_delete_bool = false;
      }    
   
      if(filteredMenuCodeds_view.length != 0)
      {
          this.hardcode_view_bool = true;
      }
      else
      {
        this.hardcode_view_bool = false;
      }
      if(filteredMenuCodeds_edit.length != 0)
      {
          this.hardcode_edit_bool = true;
      }
      else
      {
        this.hardcode_edit_bool = false;
      }
      if(filteredMenuCodeds_add.length != 0)
      {
          this.hardcode_add_bool = true;
      }
      else
      {
        this.hardcode_add_bool = false;
      }
      if(this.hardcode_delete_bool == false && this.hardcode_edit_bool == false)        
      {
          this.hardcode_double = false;
      }
      else
      {
        this.hardcode_double = true;
      }
      if(filteredMenuCodeds_resend.length != 0)
      {
          this.hardcode_resend_bool = true;
      }
      else
      {
        this.hardcode_resend_bool = false;
      }
   
   }

  ngOnInit(): void 
  {

    this.userId = this.route.snapshot.paramMap.get("id");
    this.userDTO = {
      id: 0,
      phoneNo: '',
      name: '',
      adminId: 0,
      agentId: 0,
      balance: 0,
      referralCode: '',
      image: '',
      total_topup: '',
      total_withdraw: '',
      registerDate: '',
      registerBy: '',
      updatedDate: '',
      updatedBy: '',
      status: 'ACTIVE',
      Risky : false
    };

    this.editUserDTO = {
      id: 0,
      caption :'',
      content:'',
      action_link:'',
      phone_no:'',
      from_date:'',
      to_date:'',
      sendNow :false,
      img_link:'',
      img_type:''
      
    };
    this.getUserById();
    if(!this.storage.retrieve('loadFlag')){
      this.storage.store('loadFlag', 'noLoad');
      setTimeout(function(){
        location.reload();
      }, 5);
    }
    else{
      this.storage.clear('loadFlag');
    }
   
  } 

  ngAfterViewInit(){
  }

  onChangeSingle() {
      this.singleDate = $("#singleDate").val();
  }
   
  onChangeAllToDate() {
      this.alltoDate = $("#alltodate").val();
  }

  getActiveAgents() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'agent/GetActiveAgents', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.agentList = this.dto.Response;
      }
    );
  }
  getActiveAdmins() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'ITW ' + this.token);
    this.http.get(this.funct.ipaddress + 'admin/active-admins', { headers: headers }).subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adminList = this.dto.Response.data.adminDTOList;
      }
    );
  }

  handleError(error: HttpErrorResponse)
  {
    if(error.status == 423)
    {
      this.spinner.hide();
    }
    if(error.status == 403)
    {
      this.spinner.hide();
      var id1 = 'tblBank' + this.bankidIndex;
      var table1 = $('#' + id1).DataTable();
      var id = 'tblUser' + this.idIndex;
      var tranalltable = $('#' + id).DataTable();
     
      this.toastr.error("Limited Access.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 400)
    {
      this.spinner.hide();
      this.toastr.error("Bad Request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }
    OkLogout()
    {
      window.location.href ="./ad-login";
    } 

    getAllProvider()
    {
     this.dto.token = this.storage.retrieve('token');
     let headers = new HttpHeaders();
     headers = headers.set('Authorization',  this.dto.token);
     this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', {headers: headers })
     .pipe(
       catchError(this.handleError.bind(this))
      )
     .subscribe(
       result => {
         this.dto.Response = {};
         this.dto.Response = result;
         this.gameproviderList = this.dto.Response.filter(data => data.name != 'FISH');
         this.gameproviderList.push("None");
       });
    }
   
    flagProvider()
    {
        this.providerId = $("#providerId").val();
    }

    flagProvider1()
    {
        this.providerId1 = $("#providerId1").val();
    }


  getUserById() 
  {
    this.spinner.show();
    var id1 = 'tblBank' + this.bankidIndex;
    var table1 = $('#' + id1).DataTable();
    table1.destroy();
    
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    let params = new HttpParams();
     params = params.set('id', this.userId);
    this.http.get(this.funct.ipaddress + 'viberNotification/detail', { params: params, headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => 
      {
        this.dto.Response = {};
        this.dto.Response = result;       
        this.userDTO = this.dto.Response;     
        this.imgURL = this.userDTO.img_link;
        if(this.userDTO.phone_no == null || this.userDTO.phone_no =="" || this.userDTO.phone_no == undefined )
        {
          this.approvefromdate = new Date(this.userDTO.from_date);
          this.approvetodate = new Date(this.userDTO.to_date); 
       
        }
        else 
        {
          this.approvefromdate = null;
          this.approvetodate = null;
        }
  
      
        this.spinner.hide();
        this.dtTrigger2.next();
      }); 
  }

  transform(html) 
  {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }


  goSave(val) 
  {
    this.spinner.show();
     this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  

    this.editUserDTO.phone_no = this.userDTO.phone_no;
    this.editUserDTO.caption = this.userDTO.caption;
    this.editUserDTO.content = this.userDTO.content;
    this.editUserDTO.action_link = this.userDTO.action_link;
      if (!this.storage.retrieve('vibernotificationFromDate'))
      {
      this.approvefromdate = new Date(); 
      this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');   
    }
    else {
      this.approvefromdate = new Date(this.storage.retrieve('vibernotificationFromDate'));
      this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
    }
    if (!this.storage.retrieve('vibernotificationToDate')) 
    {
      this.approvetodate = new Date();  
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    }
    else {
      this.approvetodate = new Date(this.storage.retrieve('vibernotificationToDate'));
      this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
    } 
    this.editUserDTO.from_date = this.formatDate(this.approvetodayDate);
    this.editUserDTO.to_date = this.formatDate(this.approvetodaytodate);
    this.editUserDTO.sendNow = this.userDTO.sendNow;
    this.editUserDTO.img_type =".jpg";
    this.http.post(this.funct.ipaddress + 'viberNotification/SendNoti', this.editUserDTO, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
     )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if (this.dto.Response.status == 'Success') 
        {            
          this.spinner.hide();
          this.router.navigate(['/vibernotification/list']).then(() => 
          {
            this.toastr.success(this.dto.Response.message, 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-right'
            });
          })
        }
        else
         {
          this.spinner.hide();
          this.toastr.error(this.dto.Response.message, 'Invalid!', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
        }
      }
    );
}

private formatDate(date: Date | null): string 
{
  if (!date) return '';
  return this.datepipe.transform(date, 'yyyy-MM-dd') || '';
}

  edit(val) 
  {
      this.spinner.show();
       this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.token); 
      if(this.userId == undefined || this.userId =="" || this.userId == null)
      {
           return;
      }
      if (!this.storage.retrieve('vibernotificationFromDate'))
      {
      this.approvefromdate = new Date(); 
      this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');   
      }
      else {
        this.approvefromdate = new Date(this.storage.retrieve('vibernotificationFromDate'));
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
      }

      if (!this.storage.retrieve('vibernotificationToDate')) {
        this.approvetodate = new Date();  
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }
      else {
        this.approvetodate = new Date(this.storage.retrieve('vibernotificationToDate'));
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }     
      this.editUserDTO.id = this.userId;         
      this.editUserDTO.caption = this.userDTO.caption;
      this.editUserDTO.content = this.userDTO.content;
      this.editUserDTO.action_link = this.userDTO.action_link;
      this.editUserDTO.phone_no = this.userDTO.phone_no;
      this.editUserDTO.from_date = this.formatDate(this.approvetodayDate);
      this.editUserDTO.to_date = this.formatDate(this.approvetodaytodate);
      this.editUserDTO.sendNow = this.userDTO.sendNow;
      this.editUserDTO.isResend = val;

      this.editUserDTO.img_link = this.imgURL;
        //amk
      if (this.editUserDTO.img_link === null || this.editUserDTO.img_link  === ''  || this.editUserDTO.img_link  === undefined) 
      {      
        this.editUserDTO.img_link = this.editUserDTO.img_link;
      }   
      else
      {
          if(this.editUserDTO.img_link .includes('data:image/jpeg;base64,'))
          this.editUserDTO.img_link = this.editUserDTO.img_link.replace("data:image/jpeg;base64,","");
          if(this.editUserDTO.img_link.includes('data:image/png;base64,'))
          this.editUserDTO.img_link = this.editUserDTO.img_link.replace("data:image/png;base64,","");
         
      }
      // this.editUserDTO.img_type =".jpg";
      if(this.editUserDTO.img_link == undefined || this.editUserDTO.img_link == "" || this.editUserDTO.img_link == null)
      {
        this.editUserDTO.img_link ="";
        this.editUserDTO.img_type ="";
      }
      else
      {
        this.editUserDTO.img_type =".jpg";
      }
    
      this.http.post(this.funct.ipaddress + 'viberNotification/Edit', this.editUserDTO, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') 
          {            
            this.spinner.hide();
            this.router.navigate(['/vibernotification/list']).then(() => 
            {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        }
      );
  }


  
  resend(val) 
  {
      this.spinner.show();
       this.token = this.storage.retrieve('token');
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.token); 
      if(this.userId == undefined || this.userId =="" || this.userId == null)
      {
           return;
      }
      if (!this.storage.retrieve('vibernotificationFromDate'))
      {
      this.approvefromdate = new Date(); 
      this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');   
      }
      else {
        this.approvefromdate = new Date(this.storage.retrieve('vibernotificationFromDate'));
        this.approvetodayDate = this.datepipe.transform(this.approvefromdate, 'MMM dd, yyyy');
      }

      if (!this.storage.retrieve('vibernotificationToDate')) {
        this.approvetodate = new Date();  
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }
      else {
        this.approvetodate = new Date(this.storage.retrieve('vibernotificationToDate'));
        this.approvetodaytodate = this.datepipe.transform(this.approvetodate, 'MMM dd, yyyy');
      }     
      this.editUserDTO.id = this.userId;         
      this.editUserDTO.caption = this.userDTO.caption;
      this.editUserDTO.content = this.userDTO.content;
      this.editUserDTO.action_link = this.userDTO.action_link;
      this.editUserDTO.phone_no = this.userDTO.phone_no;
      this.editUserDTO.from_date = this.formatDate(this.approvetodayDate);
      this.editUserDTO.to_date = this.formatDate(this.approvetodaytodate);
      this.editUserDTO.sendNow = this.userDTO.sendNow;
      this.editUserDTO.isResend = val;

      this.editUserDTO.img_link = this.imgURL;
        //amk
      if (this.editUserDTO.img_link === null || this.editUserDTO.img_link  === ''  || this.editUserDTO.img_link  === undefined) 
      {      
        this.editUserDTO.img_link = this.editUserDTO.img_link;
      }   
      else
      {
          if(this.editUserDTO.img_link .includes('data:image/jpeg;base64,'))
          this.editUserDTO.img_link = this.editUserDTO.img_link.replace("data:image/jpeg;base64,","");
          if(this.editUserDTO.img_link.includes('data:image/png;base64,'))
          this.editUserDTO.img_link = this.editUserDTO.img_link.replace("data:image/png;base64,","");         
      }
      this.editUserDTO.img_type =".jpg";
      this.http.post(this.funct.ipaddress + 'viberNotification/Edit', this.editUserDTO, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
       )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') 
          {            
            this.spinner.hide();
            this.router.navigate(['/vibernotification/list']).then(() => 
            {
              this.toastr.success(this.dto.Response.message, 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right'
              });
            })
          }
          else {
            this.spinner.hide();
            this.toastr.error(this.dto.Response.message, 'Invalid!', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
            });
          }
        }
      );
  }


  
  Cancel()
  {
    this.router.navigate(['/vibernotification/list']).then(() => 
    {    
    });
  }

  ngOnDestroy(): void
   {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

  // preview(files) 
  // {
  //   if (files.length === 0)
  //     return;
  //   var mimeType = files[0].type;
  //   if (mimeType.match(/image\/*/) == null) 
  //   {
  //     this.message = "Only images are supported.";
  //     return;
  //   }
  //   var reader = new FileReader();
  //   this.imagePath = files;
  //   reader.readAsDataURL(files[0]);
  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;    
  //   }
  // }

  numericOnly(event): boolean 
  { 
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43 || charCode == 46) {
      return false;
    }
    return true;
  }
  closeTran()
  {
      $('#deleteData').modal("hide");
      this.router.navigate(['/user-detail' ,this.userId]).then(() => {
      this.toastr.error("Game Transaction ", 'Cancel!', {  
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    });
  }

onChangeApproveFromDate() 
{
  this.storage.store('vibernotificationFromDate', this.approvefromdate.toString());
}

onChangeApproveToDate()
{
  this.storage.store('vibernotificationToDate', this.approvetodate.toString());
}


deleteImage(): void {
  this.imgURL = null; // Clear the image preview
  this.imageFileName = null; // Clear the file name
  console.info('Image deleted successfully');
}

preview(files) {
  if (files.length === 0)
    return;
  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }
  var reader = new FileReader();
  this.imagePath = files;
  this.filePath = files[0];
  reader.readAsDataURL(files[0]);
  reader.onload = (_event) => {
    this.imgURL = reader.result;
   
  }
}


}